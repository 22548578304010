import { Box, Grid } from "@mui/material";
import { headerIconsMap } from "../../common/mappings";

export function IconLegend() {
  return (
    <Grid width="80%" marginLeft="auto" container spacing={1}>
      {Object.values(headerIconsMap).map((iconData) => (
        <Grid key = {`icon-${iconData.shortLabel}`} item xs={4}>
          <Box display="flex" alignItems={'center'}>
            <Box mr={1}> {iconData.component}</Box>
            <Box mx={1}>-</Box>
            <Box>{iconData.shortLabel}</Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}
