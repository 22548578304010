import { useEffect, useState } from "react";
import { useValidationQuery } from ".";
import { Project } from "../../types";
import { EntityString, IssueTraversalCoordinates } from "../types";

export default function useEntityManagerValidation(
  project: Project,
  entity: EntityString
) {
  const [selectedErrorRowId, setSelectedErrorRowId] = useState<number>();
  const [traversalCoords, setTraversalCoords] = useState<IssueTraversalCoordinates|undefined>(undefined)

  const [
    validate,
    {
      errors,
      warnings,
      groupedErrors,
      groupedWarnings,
      loading,
      erroredEntries,
      erroredEntriesCount,
      totalEntries,
      setErrors,
      setWarnings,
      setGroupedErrors,
      setGroupedWarnings
    },
  ] = useValidationQuery(
    project.id,
    entity
  );

  return {
    validate,
    errors,
    warnings,
    groupedErrors,
    groupedWarnings,
    isValidating: loading,
    selectedErrorRowId,
    setSelectedErrorRowId,
    erroredEntriesCount,
    erroredEntries,
    totalEntries,
    traversalCoords,
    setTraversalCoords,
    setErrors,
    setWarnings,
    setGroupedErrors,
    setGroupedWarnings
  };
}
