import { useMutation, useApolloClient, useReactiveVar } from "@apollo/client";
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  Dashboard as DashboardIcon,
  Lock as LoginIcon,
  ExitToApp as LogoutIcon,
  LocalShipping as TrucksIcon,
  People as ClientsIcon,
  Receipt as OrdersIcon,
  ReceiptLong as ResultsIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  CheckCircleOutline as PlannedIcon,
  ErrorOutline as ErroredOrdersIcon,
  HelpOutline as UnplannedOrdersIcon,
  LocalShippingOutlined as UnusedTrucksIcon,
} from "@mui/icons-material";

import { LOGOUT } from "../../auth/api/mutations";
import { useAuth } from "../../auth/hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useCurrentProjectQuery } from "../../projects/hooks";
import { selectedBatchCache } from "../../projects/outputData/cache";

const useStyles = makeStyles({
  nestedIcon: {
    minWidth: 30,
    paddingLeft: 20,
    paddingRight: 10,
  },
});

interface LinkType {
  href?: string;
  label: string;
  icon?: JSX.Element;
  isActive?: (user: any) => boolean;
  onClick?: () => void;
  selected?: boolean;
  nested?: boolean;
  disabled?: boolean;
}

const ListItemLink: React.FC<LinkType> = (props) => {
  const { href, label, icon, onClick, selected, nested, children, disabled } =
    props;
  const classes = useStyles();

  const listItem = (
    <ListItem
      onClick={onClick}
      button
      component="div"
      selected={selected}
      disabled={disabled}
    >
      <ListItemIcon className={nested ? classes.nestedIcon : undefined}>
        {icon}
      </ListItemIcon>
      <ListItemText primary={label} />
      {children}
    </ListItem>
  );

  return !disabled && href ? (
    <Link style={{ color: "inherit", textDecoration: "inherit" }} to={href}>
      {listItem}
    </Link>
  ) : (
    listItem
  );
};

export const ListItems: React.FC<{ drawerOpen: boolean }> = ({
  drawerOpen,
}) => {
  const { user } = useAuth();
  const { data: currentProject } = useCurrentProjectQuery();
  const selectedBatch = useReactiveVar(selectedBatchCache);
  const [logout] = useMutation(LOGOUT);

  const client = useApolloClient();

  const location = useLocation();

  const [planningMenuOpen, setPlanningMenuOpen] = useState(false);

  useEffect(() => {
    const fragments = location.pathname.split("/");

    if (fragments.includes("planning")) {
      setPlanningMenuOpen(true);
    }
  }, [location.pathname]);

  const links: LinkType[] = useMemo(() => {
    if (user && user.isSuperAdmin) {
      return [
        {
          href: "/login",
          label: "Login",
          icon: <LoginIcon />,
          isActive: (user) => !user,
        },
        {
          label: "Logout",
          icon: <LogoutIcon />,
          isActive: (user) => !!user,
          onClick: async () => {
            try {
              await logout();
              localStorage.removeItem("token");
              await client.resetStore();
            } catch (e) {}
          },
        },
        {
          href: "/users",
          label: "Users",
          isActive: (user) => {
            console.log(user);
            return true;
          },
          icon: <ClientsIcon />,
        },
      ];
    }

    return [
      {
        href: "/login",
        label: "Login",
        icon: <LoginIcon />,
        isActive: (user) => !user,
      },
      {
        label: "Logout",
        icon: <LogoutIcon />,
        isActive: (user) => !!user,
        onClick: async () => {
          try {
            await logout();
            localStorage.removeItem("token");
            await client.resetStore();
          } catch (e) {}
        },
      },
      {
        href: "/projects",
        label: "Projects",
        isActive: (user) => !!user,
        icon: <DashboardIcon />,
      },
    ];
  }, [client, logout, user]);

  const navigate = useNavigate();

  const items = useMemo(
    () =>
      links
        .filter((link) => link.isActive === undefined || link.isActive(user))
        .map((link, idx) => (
          <ListItemLink
            href={link.href}
            label={link.label}
            icon={link.icon}
            onClick={link.onClick}
            key={idx}
            selected={link.href === location.pathname}
          ></ListItemLink>
        )),
    [links, location.pathname, user]
  );

  const projectPath = currentProject ? `projects/${currentProject.id}` : null;

  const handlePlanningClick = useCallback(() => {
    if (currentProject.canPlan) {
      if (drawerOpen) {
        setPlanningMenuOpen(!planningMenuOpen);
      } else {
        navigate(`${projectPath}/planning/createdPlan`);
      }
    }
  }, [drawerOpen, navigate, planningMenuOpen, projectPath, currentProject]);

  return (
    <>
      {items}

      {currentProject && (
        <>
          <Divider />
          <ListItem component="div">
            {drawerOpen && (
              <ListItemText primary="Data management:"></ListItemText>
            )}
          </ListItem>

          <ListItemLink
            icon={<TrucksIcon />}
            label="Vehicles"
            href={`${projectPath}/vehicles`}
            selected={location.pathname.includes(`/${projectPath}/vehicles`)}
          ></ListItemLink>
          <ListItemLink
            icon={<OrdersIcon />}
            label="Orders"
            href={`${projectPath}/orders`}
            selected={location.pathname.includes(`/${projectPath}/orders`)}
          ></ListItemLink>
          <ListItemLink
            icon={<ClientsIcon />}
            label="Clients"
            href={`${projectPath}/clients`}
            selected={location.pathname.includes(`/${projectPath}/clients`)}
          ></ListItemLink>
          <Divider />
          <ListItem component="div">
            {drawerOpen && (
              <ListItemText primary="Plan management:"></ListItemText>
            )}
          </ListItem>

          {currentProject.isPlanned ? (
            <ListItemLink
              onClick={handlePlanningClick}
              icon={<ResultsIcon />}
              label="Planning"
            >
              {planningMenuOpen ? (
                <ExpandLessIcon style={{ color: "#757575" }} />
              ) : (
                <ExpandMoreIcon style={{ color: "#757575" }} />
              )}
            </ListItemLink>
          ) : (
            <Tooltip
              title={
                !currentProject.canPlan
                  ? "Planning requires orders, trucks, clients data"
                  : ""
              }
            >
              <span>
                <ListItemLink
                  icon={<ResultsIcon />}
                  label="Planning"
                  href={`${projectPath}/planning`}
                  selected={location.pathname.includes(
                    `/${projectPath}/planning`
                  )}
                  disabled={!currentProject.canPlan}
                ></ListItemLink>
              </span>
            </Tooltip>
          )}

          {currentProject.isPlanned && (
            <Collapse
              style={{ display: drawerOpen ? "initial" : "none" }}
              in={planningMenuOpen}
              timeout="auto"
              unmountOnExit
            >
              <List sx={{ pl: 1 }} component="div" disablePadding>
                <ListItemLink
                  icon={<PlannedIcon style={{ color: "#10c500" }} />}
                  label="Created plan"
                  href={`${projectPath}/planning/createdPlan`}
                  selected={
                    drawerOpen &&
                    location.pathname.includes(
                      `/${projectPath}/planning/createdPlan`
                    )
                  }
                  nested={true}
                ></ListItemLink>

                {selectedBatch && selectedBatch.isSuccess && (
                  <>
                    <ListItemLink
                      icon={<ErroredOrdersIcon style={{ color: "#cf1818" }} />}
                      label="Errored orders"
                      href={`${projectPath}/planning/erroredOrders`}
                      selected={
                        drawerOpen &&
                        location.pathname.includes(
                          `/${projectPath}/planning/erroredOrders`
                        )
                      }
                      nested={true}
                    ></ListItemLink>

                    <ListItemLink
                      icon={<UnplannedOrdersIcon />}
                      label="Unplanned orders"
                      href={`${projectPath}/planning/unplannedOrders`}
                      selected={
                        drawerOpen &&
                        location.pathname.includes(
                          `/${projectPath}/planning/unplannedOrders`
                        )
                      }
                      nested={true}
                    ></ListItemLink>

                    <ListItemLink
                      icon={<UnusedTrucksIcon />}
                      label="Unused vehicles"
                      href={`${projectPath}/planning/unusedTrucks`}
                      selected={
                        drawerOpen &&
                        location.pathname.includes(
                          `/${projectPath}/planning/unusedTrucks`
                        )
                      }
                      nested={true}
                    ></ListItemLink>
                  </>
                )}
              </List>
            </Collapse>
          )}
        </>
      )}
    </>
  );
};
