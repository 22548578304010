import { gql } from '@apollo/client';

export const GET_TRUCK_FIELDS_METADATA = gql`
  query {
    trucksMetadata
  }
`;

export const GET_TRUCKS = gql`
  query($projectId: String!) {
    trucks(projectId: $projectId) {
      id
      amId
      trailerType
      carId
      trailerId
      twoDrivers
      driver1Id
      driver2Id
      amTripEnd
      dailyDrivingTime
      nonstopDrivingTime
      extendedDrivingCounter
      weeklyDrivingTime
      fortnightDrivingTime
      lastDailyRestEnd
      lastWeeklyRestEnd
      lastWeeklyRestDuration
      firstReducedWeeklyRest
      firstReducedWeeklyRestCompensation
      secondReducedWeeklyRest
      secondReducedWeeklyRestCompensation
      lastTaskLocation {
        id
        latitude
        longitude
        country
        city
        zipCode
      }
      lastOrderId
      normalWeekRestRequired
      dailyWorkingTime
      availableStartPlace
      availableEndPlace
      availableStartTime
      properties
    }
  }
`;

export const VALIDATE_TRUCKS = gql`
  query($projectId: String!, $validationOptions: ValidationOptionsInput, $refresh: Boolean!) {
    validateTrucks(projectId: $projectId, validationOptions: $validationOptions, refresh: $refresh)
  }
`;

export const FETCH_TRUCK_PROPERTIES = gql`
     query($projectId: String!) {
    fetchTruckPropertyOptions(projectId: $projectId)
  }
`;
