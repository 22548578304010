import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import { Project } from "../../types";
import React from "react";
import { EntityString } from "../../inputData/types";
import { PlanningForm } from "./PlanningForm";
import { BootstrapDialogTitle } from "../../../common/components/BootstrapDialogTitle";

interface Props {
  onClose: () => void;
  isOpen: boolean;
  project: Project;
  onPlanningStarted: (batch: any) => Promise<void>;
}

const PlanningFormDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  project,
  onPlanningStarted,
}) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
      >
        <BootstrapDialogTitle id="form-dialog-title" onClose={handleClose}>
          Planning form
        </BootstrapDialogTitle>

        <DialogContent>
          <PlanningForm
            onPlanningStarted={onPlanningStarted}
            project={project}
          ></PlanningForm>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PlanningFormDialog;
