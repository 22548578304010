import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { Shadows } from '@mui/material/styles/shadows';

// Create a theme instance.
const theme = createTheme({
  shadows: Array(25).fill("none") as Shadows,
  palette: {
    primary: {
      main: '#2196f3'
    },
    secondary: {
      main: '#19857b'
    },
    error: {
      main: red.A400
    },
    background: {
      default: '#fff'
    },
  }
});

export default theme;
