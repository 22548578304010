import {
  DataGridPro,
  GridCellIndexCoordinates,
  GridColDef,
  GridEditCellPropsParams,
  GridRowId,
  GridRowParams,
  useGridApiRef,
  gridVisibleSortedRowIdsSelector,
  visibleGridColumnsSelector,
  GridEventListener,
  GridEvents,
} from "@mui/x-data-grid-pro";
import { Box, TableContainer } from "@mui/material";
import {
  CellFormatProps,
  Entity,
  FieldDisplayConfigsMap,
  FieldMetadataMap,
  IssueTraversalCoordinates,
  LocationData,
  SetLocationEditDialogType,
  SetParametersEditDialogType,
  SetPropertyEditDialogType,
  ValidationError,
} from "../types";
import { useEntityDataTableStyles } from "../hooks/useEntityDataTableStyles";
import { useColumnsData } from "../hooks";
import { CSSProperties, ReactNode, useEffect, useMemo, useState } from "react";
import { Project } from "../../types";
import { usePrevious } from "../../../common/utils";
import { CustomNoRowsOverlay } from "./CustomNoRowsOverlay";
import theme from "../../../theme";

interface DataTableProps<T extends Entity> {
  loading: boolean;
  data: T[];
  fieldsMetadata: FieldMetadataMap<T>;
  errors: Record<string, ValidationError[]>;
  warnings: Record<string, ValidationError[]>;
  selectedErrorRowId?: number | null;
  rowClickHandler?: (param: GridRowParams) => void;
  selectionModel?: GridRowId[];
  rowSelectHandler?: (ids: (number | string)[]) => void;
  onCellUpdate: (param: GridEditCellPropsParams) => void;
  getRowClassName?: (row: any) => string;
  setPropertyEditDialog?: SetPropertyEditDialogType;
  setParametersEditDialog: SetParametersEditDialogType;
  setLocationEditDialog: SetLocationEditDialogType;
  locations?: LocationData;
  project: Project;
  readOnly?: boolean;
  traversalCoords?: IssueTraversalCoordinates;
  customOverlayElement?: ReactNode;
  onColumnWidthChange?: GridEventListener<GridEvents.columnWidthChange>;
  onColumnOrderChange?: (orders: string[]) => void;
  headerColor?: string;
  fullHeight?: boolean;
  style?: CSSProperties;
}

function InputEntityDataTable<T extends Entity>({
  fieldsMetadata,
  data = [],
  loading,
  errors,
  warnings,
  onCellUpdate,
  getRowClassName,
  setPropertyEditDialog,
  setParametersEditDialog,
  setLocationEditDialog,
  project,
  locations: locationData,
  readOnly,
  rowSelectHandler,
  selectionModel,
  traversalCoords,
  customOverlayElement,
  onColumnOrderChange,
  onColumnWidthChange,
  headerColor,
  style,
}: DataTableProps<T>): JSX.Element {
  const props: CellFormatProps = useMemo(() => {
    return {
      setParametersEditDialog,
      setPropertyEditDialog,
      setLocationEditDialog,
      updateCellValue: onCellUpdate,
      locations: locationData?.data,
      project,
    };
  }, [
    locationData?.data,
    onCellUpdate,
    project,
    setLocationEditDialog,
    setParametersEditDialog,
    setPropertyEditDialog,
  ]);

  const apiRef = useGridApiRef();

  const [page, setPage] = useState<number>(0);

  const previousTraversal = usePrevious(traversalCoords);

  useEffect(() => {
    if (apiRef && apiRef.current && apiRef.current.scrollToIndexes) {
      if (traversalCoords && traversalCoords.row !== -1) {
        const rowIndex = apiRef.current.getRowIndex(traversalCoords.row);
        const errorPage = Math.floor(rowIndex / 100);
        setPage(errorPage);
        setTimeout(() => {
          const colIndex = visibleGridColumnsSelector(
            apiRef.current.state
          ).findIndex((column) => column.field === traversalCoords.column);
          const id = gridVisibleSortedRowIdsSelector(apiRef.current.state)[
            rowIndex
          ];
          apiRef.current.scrollToIndexes({ rowIndex, colIndex });
          apiRef.current.setCellFocus(id, traversalCoords.column);
        }, 100);
      } else {
        if (previousTraversal !== undefined) {
          setPage(0);
          apiRef.current.scrollToIndexes({ rowIndex: 0, colIndex: 0 });
          apiRef.current.setCellFocus(0, "");
        }
      }
    }
  }, [apiRef, traversalCoords, previousTraversal]);

  const classes = useEntityDataTableStyles(
    errors,
    warnings,
    headerColor || theme.palette.primary.main
  );

  const columnsData: GridColDef[] = useColumnsData(
    fieldsMetadata,
    props,
    errors,
    warnings,
    readOnly
  );

  const handleColumnOrderChange: GridEventListener<
    GridEvents.columnOrderChange
  > = () => {
    if (!apiRef.current.getVisibleColumns) return;

    const visibleColumns = apiRef.current.getVisibleColumns();

    const newOrders: string[] = visibleColumns
      .filter((col) => col.field !== "__check__")
      .map((col) => col.field);

    if (onColumnOrderChange) {
      onColumnOrderChange(newOrders);
    }
  };

  const handleCellChange: any = (e: any) => {
    const rowVal = (data as any).find((item: any) => item.id === e.id)[e.field];

    if (rowVal !== (e.value as any)) {
      onCellUpdate(e);
    }
  };

  const renderElement = loading ? (
    <span>Loading...</span>
  ) : (
    <TableContainer
      className={classes.root}
      style={{ height: "100%", ...style }}
    >
      <DataGridPro
        apiRef={apiRef}
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
        columnBuffer={2}
        columnThreshold={2}
        pagination
        checkboxSelection={!project.isPlanned && !project.isPlanning}
        density="compact"
        className="entity-datatable"
        disableSelectionOnClick
        rows={data}
        columns={columnsData}
        getRowClassName={getRowClassName}
        pageSize={100}
        rowsPerPageOptions={[]}
        onCellEditCommit={handleCellChange}
        selectionModel={selectionModel}
        onSelectionModelChange={rowSelectHandler}
        onColumnWidthChange={onColumnWidthChange}
        onColumnOrderChange={handleColumnOrderChange}
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        componentsProps={{
          noRowsOverlay: {
            customOverlayElement,
          },
        }}
        disableColumnSelector={true}
      />
    </TableContainer>
  );

  return renderElement;
}

export default InputEntityDataTable;
