import { Button, DialogTitle, Icon, Tooltip, Typography } from "@mui/material";
import React from "react";

export interface ActionButtonProps {
  buttonText: string;
  tooltipText: string;
  icon: React.ReactNode;
  onClick?: () => void;
  disabled: boolean;
}

export const ActionButton = (props: ActionButtonProps) => {
  const { buttonText, tooltipText, icon, disabled, onClick } = props;

  return (
    <Tooltip title={tooltipText}>
      <Button
        disabled={disabled}
        onClick={onClick}
        style={{ color: disabled ? "grey" : "black" }}
      >
        <span>
          {icon}
          <Typography lineHeight="0.2" fontSize="small">
            {buttonText}
          </Typography>
        </span>
      </Button>
    </Tooltip>
  );
};
