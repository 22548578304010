import { QueryLazyOptions, useLazyQuery } from "@apollo/client";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { validationQueryMap } from "../api/queries";
import { EntityString, ValidationError } from "../types";
import { replaceValidationErrors } from "../utilities/errorsTransformer";
import ErroredEntriesDialog from "../../outputData/components/ErroredEntriesDialog";
import useEntityManagerValidation from "./useEntityManagerValidation";

export type ValidationOptions = {
  customParameters: any;
  ignoreWarnings?: boolean;
  includeTotals: boolean;
};

export type ValidationFunc = (
  options?:
    | QueryLazyOptions<{
        projectId: string;
        refresh: boolean;
        validationOptions?: ValidationOptions;
      }>
    | undefined
) => void;

interface ValidationResult {
  groupedErrors: Record<string, ValidationError[]>;
  groupedWarnings: Record<string, ValidationError[]>;
  errors: ValidationError[] | null;
  warnings: ValidationError[] | null;
  setErrors: Dispatch<SetStateAction<ValidationError[]|null>>;
  setWarnings: Dispatch<SetStateAction<ValidationError[]|null>>;
  setGroupedErrors: Dispatch<SetStateAction<Record<string, ValidationError[]>>>;
  setGroupedWarnings: Dispatch<SetStateAction<Record<string, ValidationError[]>>>;
  loading: boolean;
  totalEntries: number | null;
  erroredEntriesCount: number | null;
  erroredEntries: number[] | null;
}

const useValidationQuery = (
  projectId: string,
  entity: EntityString,
  refresh = false
): [ValidationFunc, ValidationResult] => {
  const query = validationQueryMap[entity];

  const [errors, setErrors] = useState<ValidationError[] | null>(null);
  const [warnings, setWarnings] = useState<ValidationError[] | null>(null);

  const [groupedErrors, setGroupedErrors] = useState<
    Record<string, ValidationError[]>
  >({});
  const [groupedWarnings, setGroupedWarnings] = useState<
    Record<string, ValidationError[]>
  >({});

  const [totalEntries, setTotalEntries] = useState<number | null>(null);
  const [erroredEntriesCount, setErroredEntriesCount] =
    useState<number | null>(null);
  const [erroredEntries, setErroredEntries] = useState<number[] | null>(null);

  const [validate, { data, loading }] = useLazyQuery(query, {
    variables: {
      projectId,
      refresh
    },
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    if (data) {
      
      const errsData = data ? data[Object.keys(data)[0]] : null;

      const issues = errsData.issues
        ? errsData.issues
        : errsData;

      const groupedErrs = replaceValidationErrors(issues.errors);
      const groupedWarns = replaceValidationErrors(issues.warnings);

      const errs = Object.values(groupedErrs).flat() as ValidationError[];
      const warnings = Object.values(groupedWarns).flat() as ValidationError[];

      setGroupedErrors(groupedErrs);
      setGroupedWarnings(groupedWarns);

      setTotalEntries(errsData.totalEntitiesCount || null);
      setErroredEntriesCount(errsData.erroredEntitiesCount || null);
      setErroredEntries(errsData.erroredEntities || null);

      setErrors(errs);
      setWarnings(warnings);
    }
  }, [data]);



  return [
    validate,
    {
      groupedErrors,
      groupedWarnings,
      errors,
      warnings,
      loading,
      totalEntries,
      erroredEntriesCount,
      erroredEntries,
      setErrors,
      setWarnings,
      setGroupedErrors,
      setGroupedWarnings
    },
  ];
};

export default useValidationQuery;
