import { ApolloQueryResult, QueryResult, useQuery } from "@apollo/client";
import { useMatch } from "react-router-dom";
import { GET_PROJECT } from "../api/queries";
import { Project } from "../types";

const useCurrentProjectQuery = (): QueryResult => {
    const projectRouteMatch = useMatch({
        path: '/projects/:projectId',
        end: false
      })
    
      const { data, ...other } = useQuery(GET_PROJECT, { variables: { id: projectRouteMatch?.params.projectId }, skip: !projectRouteMatch });

      return { data: data ? data.project : null, ...other} 
}

export default useCurrentProjectQuery