import { gql } from '@apollo/client';

export const UPDATE_ORDER = gql`
  mutation($id: Int!, $updateOrderInput: UpdateOrderInput!) {
    updateOrder(id: $id, updateOrderInput: $updateOrderInput) {
      id
      orderId
      clientId
      loadingPointId
      unloadingPointId
      loadingPointLocation {
        id
        latitude
        longitude
        country
        city
        zipCode
      }
      unloadingPointLocation {
        id
        latitude
        longitude
        country
        city
        zipCode
      }
      orderLoadingTimeWindowStart
      orderLoadingTimeWindowEnd
      orderLoadingDuration
      orderUnloadingTimeWindowStart
      orderUnloadingTimeWindowEnd
      orderUnloadingDuration
      allowableUnloadingDelay
      allowableLoadingDelay
      fineForUnloadingDelay
      fineForLoadingDelay
      freight
      drivingDuration
      routeCost
      fineForNoCargo
      nonExpeditedCargo
      requirements
      issues
    }
  }
`;

export const CREATE_ORDER = gql`
  mutation($createOrderInput: CreateOrderInput!) {
    createOrder(createOrderInput: $createOrderInput) {
      id
      orderId
      clientId
      loadingPointId
      unloadingPointId
      loadingPointLocation {
        id
        latitude
        longitude
        country
        city
        zipCode
      }
      unloadingPointLocation {
        id
        latitude
        longitude
        country
        city
        zipCode
      }
      orderLoadingTimeWindowStart
      orderLoadingTimeWindowEnd
      orderLoadingDuration
      orderUnloadingTimeWindowStart
      orderUnloadingTimeWindowEnd
      orderUnloadingDuration
      allowableUnloadingDelay
      allowableLoadingDelay
      fineForUnloadingDelay
      fineForLoadingDelay
      freight
      drivingDuration
      routeCost
      fineForNoCargo
      nonExpeditedCargo
      requirements
    }
  }
`;

export const DELETE_ORDERS = gql`
    mutation($projectId: String!, $ids: [Int!]!){
        removeOrders(projectId: $projectId, ids: $ids){
        ids
        count
        }
    }`;
