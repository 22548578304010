import {Entity, EntityString, SetPropertyEditDialogType} from "../types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {useForm} from "react-hook-form";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {Autocomplete, AutocompleteChangeReason, Box, Chip, TextField} from "@mui/material";
import {usePropertyOptionsQuery} from "../hooks";
import {useUpdateEntityMutation} from "../hooks/useUpdateEntityMutation";
import {OrderType} from "../types/orderType";
import {TruckType} from "../types/truckType";
import {Project} from "../../types";
import React, {useCallback, useEffect, useMemo} from "react";
import {generateInputString} from "../../../utilities/helpers";
import {LabelObject, propertyLabelMap, PropifiedEntityType} from "../mappings";
import {BootstrapDialogTitle} from "../../../common/components/BootstrapDialogTitle";

interface Props {
    dialog: {
        open: boolean,
        entityObject: Entity | null,
    };
    setDialog: SetPropertyEditDialogType;
    project: Project;
    entityString: EntityString
}

const PropertyEditDialog: React.FC<Props> = ({ dialog, setDialog, project , entityString}) => {
    const {handleSubmit,reset} = useForm();

    const {data, refetch} = usePropertyOptionsQuery(project, entityString);

    const labelObject: LabelObject = propertyLabelMap[entityString as PropifiedEntityType]

    let propValue: string[] = [];
    if(dialog.entityObject){
    propValue = (dialog.entityObject as any)[labelObject.valueKey]
    }

    useEffect(() => {
        const refetchProps = async () => {
            await refetch()
        }

        reset()
        if (dialog.open) {
            refetchProps()
        }

    }, [dialog.open, refetch, reset])

    const [updateEntity] = useUpdateEntityMutation(entityString);

    const handleAutocompleteChange = useCallback((event: Object, value: any, reason:AutocompleteChangeReason) => {
        if(reason === 'clear'){
            setDialog({
                open:true,
                entityObject: {...dialog.entityObject,
                    [labelObject.valueKey]: []} as Entity
            })
        }
        else {
            setDialog({
                open: true,
                entityObject: {
                    ...dialog.entityObject,
                    [labelObject.valueKey]: value
                } as Entity
            })
        }
    }, [dialog.entityObject, setDialog, labelObject.valueKey])


    const formFields = useMemo(() => {

            return (
                <Box>
                    <Autocomplete
                        multiple
                        id="tags-filled"
                        options={data}
                        freeSolo
                        renderTags={(value: string[], getTagProps) =>
                            value.map((option: string, index: number) => (
                                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField {...params} variant="filled" label={labelObject.fieldLabel}/>
                        )}
                        onChange={handleAutocompleteChange}
                        value={propValue}
                        defaultValue={[]}
                    />
                </Box>

            )
    }, [data, handleAutocompleteChange, propValue, labelObject.fieldLabel])


    const onSubmit = async () => {

        try {
            const response = await updateEntity({
                variables: {
                    id: (dialog.entityObject as Entity).id,
                    [labelObject.updateInputKey]: {
                        [labelObject.valueKey] : propValue
                    }
                }
            })
            handleClose();
        }
        catch (e){
            console.log(e)
        }

    };

    const handleClose = () => {
        setDialog({
            entityObject: null,
            open: false
        })
    }

    return (
        <div>
            <Dialog
                fullWidth
                open={dialog.open && !project.isPlanned && !project.isPlanning}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <BootstrapDialogTitle id="form-dialog-title" onClose={handleClose}>
                    {entityString === 'orders' ? 'Edit order requirements' : 'Edit vehicle properties'}
                </BootstrapDialogTitle>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        {formFields}
                    </DialogContent>

                    <DialogActions>
                        <Button type="submit" color="primary">
                            Update
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};

export default PropertyEditDialog;