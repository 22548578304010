import { gql } from "@apollo/client";

export const GET_OUTPUT_FIELDS_METADATA = gql`
  query {
    outputMetadata
  }
`;

export const GET_OUTPUT_DATA = gql`
  query ($projectId: String!, $batchId: String!) {
    outputRecords(projectId: $projectId, batchId: $batchId) {
      id
      jobId
      taskType
      country
      zip
      city
      slotStart
      slotEnd
      pta
      trailerType
      status
      kpi1
      kpi2
      vehicle
      amCountry
      amCity
      amZip
      freeFrom
      drivingTime
      dailyRest
    }
  }
`;

export const GET_ORDER_ERROR_IDS = gql`
  query ($projectId: String!) {
    getOrderErrorIds(projectId: $projectId)
  }
`;

export const GET_TRUCK_ERROR_IDS = gql`
  query ($projectId: String!) {
    getTruckErrorIds(projectId: $projectId)
  }
`;

export const GET_KPI_METADATA = gql`
  query {
    planKpiMetadata
  }
`;

export const GET_PLAN_KPIS = gql`
  query ($batchId: String!, $projectId: String!) {
    planKpi(projectId: $projectId, batchId: $batchId)
  }
`;

export const GET_KPI_FOR_COMPARE = gql`
  query ($projectId: String!, $batchIds: [String!]!) {
    getKpiForCompare(projectId: $projectId, batchIds: $batchIds) {
      batchName
      totalFreight
      totalRouteCost
      totalProfit
      totalPlanDistance
      totalEmptyKm
      averageArrivingTime
      averageTransitTime
      averageRouteLoadingWaitingTime
      averageRouteUnloadingWaitingTime
      averageRouteLoadingDelay
      averageRouteUnloadingDelay
      batchId
      amPlanned {
        plannedEntries
        totalEntries
      }
      ordersPlanned {
        plannedEntries
        totalEntries
      }
    }
  }
`;

export const GET_PROJECT_BATCHES = gql`
  query ($projectId: String!, $forCompare: Boolean!) {
    fetchBatches(projectId: $projectId, forCompare: $forCompare) {
      id
      name
      date
      isProcessing
      isPlanning
      isSuccess
      message
    }
  }
`;

export const GET_UNUSED_TRUCKS = gql`
  query($projectId: String!, $batchId: String!) {
    fetchUnusedTrucks(projectId: $projectId, batchId: $batchId) {
      id
      carId
      trailerId
      twoDrivers
      driver1Id
      driver2Id
      amTripEnd
      dailyDrivingTime
      nonstopDrivingTime
      extendedDrivingCounter
      weeklyDrivingTime
      fortnightDrivingTime
      lastDailyRestEnd
      lastWeeklyRestEnd
      lastWeeklyRestDuration
      firstReducedWeeklyRest
      firstReducedWeeklyRestCompensation
      secondReducedWeeklyRest
      secondReducedWeeklyRestCompensation
      lastTaskLocation {
        id
        latitude
        longitude
        country
        city
        zipCode
      }
      normalWeekRestRequired
      dailyWorkingTime
      availableStartPlace
      availableEndPlace
      availableStartTime
      properties
    }
  }
`;
