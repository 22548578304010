import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ApolloError, gql, useMutation } from "@apollo/client";
import { CREATE_PROJECT, UPDATE_PROJECT } from "../api/mutations";
import { Project } from "../types";
import { useNavigate } from "react-router";
import { BootstrapDialogTitle } from "../../common/components/BootstrapDialogTitle";
import { useAuth } from "../../auth/hooks";

interface Props {
  dialog: {
    open: boolean;
    edited: Project | null;
  };
  setDialog: Dispatch<
    SetStateAction<{
      open: boolean;
      edited: Project | null;
    }>
  >;
  showArchived: boolean;
  setShowArchived: Dispatch<SetStateAction<boolean>>;
}

type VoidHandler = () => void;
const ProjectFormDialog: React.FC<Props> = ({
  dialog,
  setDialog,
  setShowArchived,
  showArchived,
}) => {
  const navigate = useNavigate();

  const { register, handleSubmit, setValue } = useForm();
  const { user } = useAuth();
  const [error, setError] = useState<string>();

  useEffect(() => {
    setTimeout(() => setValue("name", dialog.edited?.name ?? ""));
  }, [dialog, setValue]);

  const handleClose: VoidHandler = () => {
    setError(undefined);
    setDialog({
      open: false,
      edited: null,
    });
  };

  const [createProject] = useMutation(CREATE_PROJECT, {
    update(cache, { data: { createProject } }) {
      cache.modify({
        fields: {
          projects(existingProjects = []) {
            const newProjectRef = cache.writeFragment({
              data: createProject,
              fragment: gql`
                fragment NewProject on Project {
                  id
                  name
                }
              `,
            });
            return [...existingProjects, newProjectRef];
          },
        },
      });
    },
  });

  const [updateProject] = useMutation(UPDATE_PROJECT);
  const onSubmit = async (data: { name: string }) => {
    try {
      if (dialog.edited) {
        await updateProject({
          variables: {
            id: dialog.edited.id,
            updateProjectInput: {
              name: data.name,
              userId: user.id,
            },
          },
        });
      } else {
        const {
          data: { createProject: project },
        } = await createProject({
          variables: {
            createProjectInput: {
              name: data.name,
              userId: user.id,
            },
          },
        });
        if (showArchived) {
          setShowArchived(false);
        }
      }

      handleClose();

      // navigate((project as Project).id)
    } catch (err) {
      if (err.graphQLErrors[0]?.extensions?.statusCode === 400) {
        setError(err.graphQLErrors[0].message);
      }
    }
  };

  return (
    <div>
      <Dialog
        open={dialog.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <BootstrapDialogTitle id="form-dialog-title" onClose={handleClose}>
          {dialog.edited ? "Edit project" : "New project"}
        </BootstrapDialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText>
              Fill information about this planning project.
            </DialogContentText>
            <TextField
              error={!!error}
              helperText={error}
              inputProps={{ ...register("name") }}
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              fullWidth
            />
          </DialogContent>

          <DialogActions>
            <Button type="submit" color="primary">
              {dialog.edited ? "Update" : "Create"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default ProjectFormDialog;
