import {Reference, useMutation} from "@apollo/client";
import {deleteMutationsMap} from "../api/mutations";
import {EntityString} from "../types";
import {GridRowId} from "@mui/x-data-grid-pro";
import {Dispatch, SetStateAction} from "react";

export function useDeleteEntitiesMutation(entity: EntityString, selectionModel: GridRowId[], setSelectionModel: Dispatch<SetStateAction<GridRowId[]>>, selectedErrorId: number|undefined, setSelectedErrorId: Dispatch<SetStateAction<number|undefined>>, validate: any, refetchProject: any) {
    const mutation = deleteMutationsMap[entity];
    const dataKey = `remove${entity.charAt(0).toUpperCase() + entity.slice(1)}`


    const [mutateFunction, mutationResult] = useMutation(mutation,{
        update(cache, { data }) {
            cache.modify({
                fields: {
                    [entity]: (existingEntityRefs: Reference[] = [], { readField }) => {
                        const parsedIds = data[dataKey].ids.map((id:string) => {
                            return parseInt(id);
                        })
                        return existingEntityRefs.filter(
                            (entityRef) => !parsedIds.includes(readField("id", entityRef))
                        );
                    },
                },
            });
        },
        onCompleted(data){
            if(selectionModel.length === 1 && selectionModel[0] === selectedErrorId){
                setSelectedErrorId(undefined)
            }
            const parsedIds = data[dataKey].ids.map((id:string) => {
                return parseInt(id);
            })
            setSelectionModel(selectionModel.filter((id) => !parsedIds.includes(id)))
            window.alert(`Successfully deleted ${data[dataKey].count} ${entity}`)
            refetchProject()
        }
    });

    return {
        deleteEntities: mutateFunction,
        deleteResponse: {
            ...mutationResult,
            data: mutationResult.data ? mutationResult.data[dataKey] : {},
        }
    }
}