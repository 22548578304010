import { gql } from "@apollo/client";

export const REMOVE_BATCH = gql`
  mutation ($batchId: String!) {
    removeBatch(id: $batchId) { id }
  }
`
export const PLAN_PROJECT = gql`
  mutation ($planProjectInput: PlanProjectInput!) {
    planProject(planProjectInput: $planProjectInput) {
      id,
      name,
      planningStartedAt,
      isProcessing
    }
  }
`;