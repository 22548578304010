import { ProjectsList } from '../../projects/components';

function Projects() {

  return (
    <>
      <ProjectsList></ProjectsList>
    </>
  );
}

export default Projects;
