import { useQuery } from '@apollo/client';
import { Outlet } from 'react-router';
import { GET_PROJECT } from '../api/queries';
import ProjectContextProvider from '../context/ProjectContext';
import { Project } from '../types';
import {useAuth} from "../../auth/hooks";
import {Navigate} from "react-router-dom";
import React from "react";

interface ProjectDashboardProps {
  projectId: string;
}

const ProjectDashboard: React.FC<ProjectDashboardProps> = ({ projectId }) => {
  const { data } = useQuery(GET_PROJECT, { variables: { id: projectId } });
  const { user, loading} = useAuth();

  const project: Project = data ? data.project : null;

  return (
      <>
        {((!loading && user) || (loading)) ? (
            <>
              {!project ? (<span>Loading project...</span>) :
                  (<ProjectContextProvider project={project}>
                    <Outlet/>
                  </ProjectContextProvider>)
              }
            </>
        ) : (<Navigate to='/login' />)
        }
      </>);
};

export default ProjectDashboard;
