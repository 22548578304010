import { ParameterError, ValidationError } from "../types";
import reactStringReplace from "react-string-replace";

export const errorReplaceCallback = (error:ValidationError) => {
  return {
    ...error,
    formattedMessage: generateErrorMessageString(error),
    unformattedMessage: generateErrorMessageString(error, false)
  }
}
export const replaceValidationErrors = (
  errors: Record<string, ValidationError[]>
): Record<string, ValidationError[]> => {
  return Object.keys(errors).reduce((obj, curr) => {
    const modifiedErrors = errors[curr].map((error) => {
      return errorReplaceCallback(error)
    })

    return {
      ...obj,
      [curr]: modifiedErrors
    }
  }, {})
};

export const replaceParamErrors = (
  errors: ParameterError[]
): ParameterError[] => {
  return errors.map((error) => {
    error.message = generateErrorMessageString(error);
    return error;
  });
};

export const generateErrorMessageString = (
  error: ParameterError | ValidationError,
  spanMessages = true
) => {
  let message = error?.message
  let field = ''

  if (error.fieldValues) {
    error.fieldValues.forEach((value, key) => {
      field = (error as ValidationError).field
      message = reactStringReplace(
        message,
        "$fieldValue" + (key + 1),
        (match, i) => spanMessages ? (
          <span key={`${error.id}-${field}-fieldValue[${key}]`} className="field-value">
            {value}
          </span>
        ): (value)
      );
    });
  }

  if (error.parameterValues) {
    error.parameterValues.forEach((value, key) => {
      message = reactStringReplace(
        message,
        "$parameterValue" + (key + 1),
        (match, i) => spanMessages ? (
          <span key={key + "-parameterValue"} className="parameter-value">
            {value}
          </span>
        ): (value)
      );
    });
  }

  if (error.parameterLabels) {
    error.parameterLabels.forEach((value, key) => {
      message = reactStringReplace(
        message,
        "$parameterLabel" + (key + 1),
        (match, i) => spanMessages ? (
          <span key={key + "-parameterLabel"} className="parameter-label">
            {value}
          </span>
        ) : (value)
      );
    });
  }

  if (error.fieldLabels) {
    error.fieldLabels.forEach((value, key) => {
      message = reactStringReplace(
        message,
        "$fieldLabel" + (key + 1),
        (match, i) => spanMessages ? (
          <span key={key + "-fieldLabel"} className="field-label">
            {value}
          </span>
        ) : (value)
      );
    });
  }

  return message;
};
