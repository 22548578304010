import { PasswordResetForm } from '../auth/components';
import {useParams} from "react-router-dom";

const ResetPassword = (): React.ReactElement => {
    let { token } = useParams() as any;
    console.log(token);

    return <PasswordResetForm resetToken={token}/>;
};
export default ResetPassword;
