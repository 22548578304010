import { QueryResult, useQuery } from "@apollo/client";
import { DateTime } from "luxon";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useMetadataQuery } from ".";
import { Project } from "../../types";
import { dataQueriesMap } from "../api/queries";
import { Entity, EntityString, LocationMap, ValidationError } from "../types";
import { SocketContext } from "../../../context/SocketContext";
import {
  EntityManagerContext,
  EntityManagerContextProps,
} from "../context/EntityManagerContext";
import { FETCH_FROM_OUTPUT_RECORDS } from "../api/queries/orders";
import { keyBy, omit } from "lodash";
import useFieldDisplayConfigs from "./useFieldDisplayConfigs";
import { formatDatetimeValues } from "../../../common/utils";

type InputDataQueryResult = QueryResult & {
  noDataFound: boolean;
};

const useInputDataQuery = (
  project: Project,
  entity: EntityString,
  errors: Record<string, ValidationError[]>,
  warnings: Record<string, ValidationError[]>,
  includeValidRows: boolean,
  includeRowsWithWarnings: boolean,
  includeRowsWithErrors: boolean,
  customFilter?: (item: any) => boolean,
  entryIds?: number[],
  status?: string,
  batchId?: string
): InputDataQueryResult => {
  const query = useQuery(
    status ? FETCH_FROM_OUTPUT_RECORDS : dataQueriesMap[entity],
    {
      variables: { projectId: project.id, entryIds, status, batchId },
      skip: (status === "Error" || status === "Unplanned") && !batchId
    }
  );

  const entityManagerContext = useContext(
    EntityManagerContext
  ) as EntityManagerContextProps;

  const { socket } = useContext(SocketContext);



  const metadataQuery = useMetadataQuery(entity);

  useEffect(() => {
    if (socket && entityManagerContext) {
      socket.on("location_found", entityManagerContext.locations.setLocations);
    }

    return () => {
      if (socket && entityManagerContext) {
        socket.off(
          "location_found",
          entityManagerContext.locations.setLocations
        );
      }
    };
  }, [entityManagerContext, project.id, socket]);

  const data: Entity[] = useMemo(() => {
    let entityData: Entity[] = query.data ? query.data[entity] : [];

    if (query.data) {
      if (status) {
        entityData = query.data["fetchFromOutputRecords"];
      } else {
        entityData = query.data[entity];
      }
    }

    entityData = formatDatetimeValues(entityData, metadataQuery.data);
    if (errors || warnings) {
      const normalizedEntries = keyBy(entityData, "id");

      let allIds = Object.keys(normalizedEntries);

      const warningRowIds = Object.keys(warnings);
      const errorRowIds = Object.keys(errors);
      const rowIdsWithIssues = [...warningRowIds, ...errorRowIds];
      const validRowIds = allIds.filter((id) => !rowIdsWithIssues.includes(id));

      let idsToExclude: string[] = [];

      if (includeRowsWithErrors === false) {
        idsToExclude = [...idsToExclude, ...errorRowIds];
      }

      if (includeRowsWithWarnings === false) {
        idsToExclude = [...idsToExclude, ...warningRowIds];
      }

      if (includeValidRows === false) {
        idsToExclude = [...idsToExclude, ...validRowIds];
      }

      entityData = Object.values(omit(normalizedEntries, idsToExclude));
    }

    if (customFilter) {
      // console.log(customFilter)
      entityData = entityData.filter((item) => customFilter(item));
    }

    return entityData;
  }, [
    query.data,
    entity,
    errors,
    customFilter,
    status,
    metadataQuery.data,
    warnings,
    includeRowsWithErrors,
    includeRowsWithWarnings,
    includeValidRows,
  ]);

  const noDataFound = useMemo(() => {
    if (!query.data) {
      return true;
    }

    const data = Object.values(query.data)[0] as Array<unknown>;
    return data.length === 0;
  }, [query.data]);

  return {
    ...query,
    data: data,
    noDataFound,
  };
};

export default useInputDataQuery;
