import {createContext, useContext} from 'react';
import { Project } from '../types';

interface ProjectContextProps {
  project: Project;
}
export const ProjectContext = createContext<Project | null>(null);

const ProjectContextProvider: React.FC<ProjectContextProps> = ({ children, project}) => {
  return (
    <ProjectContext.Provider value={project}>
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectContextProvider;
