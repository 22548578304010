import {EntityString} from "../types";
import { Project } from '../../types';
import {QueryResult, useQuery} from "@apollo/client";
import {propertyOptionsQueryMap} from "../api/queries";

const usePropertyOptionsQuery = (
    project: Project,
    entity: EntityString
): QueryResult => {
        const query = useQuery(propertyOptionsQueryMap[entity], {
            variables: {projectId: project.id}
        });
        let entityDataString
        switch (entity) {
            case "orders": {
                entityDataString = 'fetchOrderRequirementOptions'
                break;
            }
            case "trucks": {
                entityDataString = 'fetchTruckPropertyOptions'
                break;
            }
            case "clients": {
                entityDataString = 'fetchClientOptions'
            }
        }
        return {...query, data: query.data ? query?.data[entityDataString] : []}
};

export default usePropertyOptionsQuery;
