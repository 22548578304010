import clsx from "clsx";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
} from "@mui/icons-material";
import { ListItems } from "./ListItems";

import {
  CssBaseline,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  Container,
  Box,
  Theme,
} from "@mui/material";
import { Route, Routes, Navigate } from "react-router-dom";
import Login from "../../pages/login";
import Projects from "../../pages/projects";
import Users from "../../pages/users";
import Project from "../../pages/projects/project";
import { useAuth } from "../../auth/hooks";
import { useCurrentProjectQuery } from "../../projects/hooks";
import {
  EntityDataManager,
  EntityManagerView,
  EntityParametersManager,
} from "../../projects/inputData/components";
import ResetPassword from "../../pages/resetPassword";
import { ToolbarNavigator } from ".";
import PlanningResultsView from "../../projects/outputData/components/PlanningResultsView";
import { useTopbarActions } from "../../context/TopbarActionsContext";

const drawerWidth = 240;

const useStyles = makeStyles((theme: any) => {
  return {
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
      backgroundColor: "white",
      color: "black",
      height: "47px",
    },
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      height: "47px",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      borderWidth: 0,
      borderStyle: "solid",
      borderColor: "rgb(224, 224, 224)",
      borderBottomWidth: "thin",
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: "none",
    },
    drawerPaper: {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: "border-box",
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    },
    appBarSpacer: { height: "47px" },
  };
});

export function Dashboard(): React.ReactElement {
  const { user, loading, isLoggedIn } = useAuth();

  const [actions] = useTopbarActions();

  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <CssBaseline />
      <AppBar
        elevation={0}
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar style={{ minHeight: "47px" }} className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>

          <ToolbarNavigator></ToolbarNavigator>
          <Box>{actions}</Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItems drawerOpen={open}></ListItems>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          paddingBottom: "10px",
        }}
      >
        <div className={classes.appBarSpacer} />

        <Container
          id="content-container"
          maxWidth={false}
          disableGutters
          sx={{
            pl: "8px",
            pr: "8px",
            mt: "4px",
            mb: "4px",
            maxWidth: "100%",
            height: "calc(100% - 47px)",
            overflow: "auto",
          }}
        >
          <Routes>
            <Route path="/resetPassword/:token" element={<ResetPassword />} />
            <Route path="/login/" element={<Login />} />
            {isLoggedIn ? (
              <>
                <Route path="/projects/" element={<Projects />} />
                {user && user.isSuperAdmin && (
                  <Route path="/users/" element={<Users />} />
                )}
                <Route path="/projects/:projectId" element={<Project />}>
                  <Route path="/" element={<Navigate to="vehicles" />} />
                  <Route path="orders/*">
                    <Box height="100%" key="orders">
                      <EntityManagerView entity="orders" />
                    </Box>
                  </Route>
                  <Route path="vehicles/*">
                    <Box height="100%" key="vehicles">
                      <EntityManagerView entity="trucks" />
                    </Box>
                  </Route>
                  <Route path="clients/*">
                    <Box height="100%" key="clients">
                      <EntityManagerView entity="clients" />
                    </Box>
                  </Route>

                  <Route path="planning/*">
                    <Box height="100%" key="planning">
                      <PlanningResultsView view="planning" />
                    </Box>
                  </Route>

                  <Route path="planning/createdPlan">
                    <Box height="100%" key="createdPlan">
                      <PlanningResultsView view="createdPlan" />
                    </Box>
                  </Route>

                  <Route path="planning/unplannedOrders">
                    <Box height="100%" key="unplannedOrders">
                      <PlanningResultsView view="unplannedOrders" />
                    </Box>
                  </Route>

                  <Route path="planning/erroredOrders">
                    <Box height="100%" key="erroredOrders">
                      <PlanningResultsView view="erroredOrders" />
                    </Box>
                  </Route>

                  <Route path="planning/unusedTrucks">
                    <Box height="100%" key="unusedTrucks">
                      <PlanningResultsView view="unusedTrucks" />
                    </Box>
                  </Route>
                </Route>
                <Route path="/" element={<Navigate to="projects" />} />
              </>
            ) : (
              <>
                <Route path="*" element={<Navigate to="login" />} />
              </>
            )}
          </Routes>
        </Container>
      </Box>
    </Box>
  );
}
