import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation($loginInput: LoginInput!) {
    login(loginInput: $loginInput) {
      token
      user {
        id
      }
    }
  }
`;

export const LOGOUT = gql`
  mutation {
    signOut
  }
`;

export const REFRESH_TOKEN = gql`
  mutation {
    refreshUserToken {
      token
    }
  }
`;

export const SEND_PASSWORD_REMINDER = gql`
  mutation($email: String!) {
    sendPasswordReminder(email: $email)
  }
`

export const RESET_PASSWORD = gql`
  mutation($token: String!, $passwordResetInput: PasswordResetInput!) {
    resetPassword(token: $token, passwordResetInput: $passwordResetInput)
  }
`
