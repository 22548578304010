import { gql } from "@apollo/client";

export const GET_FIELD_DISPLAY_CONFIGS = gql`
  query ($projectId: String!) {
    fieldDisplayConfigs(projectId: $projectId) {
      id
      field
      entityType
      width
      order
      isPinned
      isHidden
    }
  }
`;

export const SET_NEW_FIELDS_ORDER = gql`
  mutation ($projectId: String!, $entityType: String!, $fieldsOrder: [String!]!) {
    setNewFieldsOrder(projectId: $projectId, entityType: $entityType, fieldsOrder: $fieldsOrder) 
  }
`;

export const SET_NEW_FIELD_WIDTH = gql`
  mutation ($projectId: String!, $entityType: String!, $field: String!, $width: Float!) {
    setNewFieldWidth(projectId: $projectId, entityType: $entityType, field: $field, width: $width) 
  }
`;
