import { useQuery } from "@apollo/client";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Chip,
  Box,
  CircularProgress,
  LinearProgress,
  Skeleton,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { FETCH_COMPATIBLE_TRUCKS } from "../api/queries/compatibilities";
import { CompatibilityDetailsDialog } from "../types";
import { formatCell } from "../utilities/cellFormatter";
import {BootstrapDialogTitle} from "../../../common/components/BootstrapDialogTitle";

interface Props {
  dialog: CompatibilityDetailsDialog;
  setDialog: Dispatch<SetStateAction<CompatibilityDetailsDialog>>;
}

const CompatibilitiesDetailsDialog: React.FC<Props> = ({
  dialog,
  setDialog,
}) => {
  const { data, refetch, loading } = useQuery(FETCH_COMPATIBLE_TRUCKS, {
    variables: {
      orderId: dialog.orderData?.id,
      projectId: dialog.projectId

    },
    skip: dialog.orderData === null,
  });

  const compatibilitiesData = data ? data.fetchCompatibleTrucks : [];

  useEffect(() => {
    if (dialog.open) refetch();
  }, [dialog.open, refetch]);

  const handleClose = () => {
    setDialog({
      orderData: null,
      open: false,
      projectId: null,
      compatibilitiesInfo: null,
    });
  };

  const orderRequirements = (
    dialog.orderData?.requirements || ([] as string[])
  ).map((item) => (
    <Chip
      label={item as any}
      style={{ marginRight: 10 }}
      size="small"
      variant="outlined"
    />
  ));

  const displayElement = useMemo(() => {
    return loading ? (
      <Skeleton variant='rectangular' width={'100%'} height={500} />
    ) : (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Vehicle ID</TableCell>
            <TableCell>Properties</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {compatibilitiesData.map((item: any) => (
            <TableRow>
              <TableCell>{item.amId}</TableCell>
              <TableCell>{formatCell("props", item.truckProps)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }, [compatibilitiesData, loading]);

  return (
    <div>
      <Dialog
        fullWidth
        open={dialog.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth={'md'}
      >
        <BootstrapDialogTitle id="form-dialog-title" onClose={handleClose}>
          Compatibilities details for order {dialog.orderData?.orderId}
        </BootstrapDialogTitle>

        <DialogContent>
          <DialogContentText>
            <h4>Requirements:</h4>

            <Box mb={5}>{orderRequirements}</Box>

            <Box mb={3}>
              This order can be fulfiled by{" "}
              <b>
                {" "}
                {dialog.compatibilitiesInfo?.truckCount} trucks (
                {dialog.compatibilitiesInfo?.percentage.toFixed(2)}% total)
              </b>
            </Box>
          </DialogContentText>

          <Box>{displayElement}</Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CompatibilitiesDetailsDialog;
