import { Tooltip } from "@mui/material"
import React from "react"
import { useEntityManagerContext } from "../context/EntityManagerContext"
import { CellFormatProps, ValidationError } from "../types"
import { makeStyles } from '@mui/styles';
import { Settings as SettingsIcon } from "@mui/icons-material";

interface ErrorTooltipProps {
  error: ValidationError;
  value: any;
  tooltipClass: string;
  props: CellFormatProps;
}


const useStyles = makeStyles({
  settingsIcon: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
})
export default function ErrorTooltip({ error, value, tooltipClass, props }: ErrorTooltipProps) {
    const classes = useStyles()
    const { project, focusedParameterState, entity} = useEntityManagerContext()
  
    const [focusedParameters, setFocusedParameter] = focusedParameterState
  
    const onSettingsClick = (e: any) => {
      setFocusedParameter(error.parameterKeys)
      
      if (props.setParametersEditDialog) {
        props.setParametersEditDialog({
          edited: project,
          open: true,
          entity: entity
        })
      }
    }
  
    return (
      <Tooltip title={<span className={tooltipClass}>{error.formattedMessage}</span>}>
        <span>
          {value}{" "}
          {error.parameterKeys && error.parameterKeys.length > 0 && (
            <SettingsIcon className={classes.settingsIcon} onClick={onSettingsClick} />
          )}
        </span>
      </Tooltip>
    );
  }