import { DataTableColumnMap, ValidationError } from "../types";

type ValidationErrorField = Omit<
  ValidationError,
  | "parameterValues"
  | "fieldValues"
  | "fieldLabels"
  | "parameterLabels"
  | "parameterKeys"
>;

const errorMappings: DataTableColumnMap<ValidationErrorField> = {
  id: { label: "ID", width: 150 },
  nativeId: { label: "Order ID", width: 150 },
  field: { label: "Field", width: 200 },
  value: { label: "Value", width: 100 },
  errorType: { label: "Severity", width: 100 },
  message: { label: "Message", width: 200 },
  formattedMessage: { label: "Message", width: 400 },
  unformattedMessage: { label: "Unformatted message", width: 400 },
};

export default errorMappings;
