import { Box, Typography } from "@mui/material";
import { startCase } from "lodash";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useCurrentProjectQuery } from "../../projects/hooks";
import { capitalizeFirstLetter } from "../../utilities/helpers";
import { 
    KeyboardArrowRight as NavArrow
} from "@mui/icons-material";

export default function ToolbarNavigator() {
  const { data: project, loading: projectLoading } = useCurrentProjectQuery();

  const location = useLocation();

  const [links, setLinks] = useState<any[]>([]);

  const buildQuery = (segments: string[]) => "/" + segments.join("/");

  const getLabel = useCallback((segment: string) => {
      if (project && segment === project.id) {
          return project.name
      } 

      if (segment.length === 32) {
          return "Reset password"
      }

      return startCase(segment)
  }, [project]);

  useEffect(() => {
    const segments = location.pathname.split("/").slice(1);
    const filteredSegments = segments.filter(
      (segment) => project || (!project && segment.length !== 32)
    );

    const links = filteredSegments.map((segment, index) => {
      const label = getLabel(segment);

      const query = buildQuery(segments.slice(0, index + 1));
      return (
        <Box key={`toolbarItem-${label}`} display='flex' alignItems='center'>
          <Link style={{ color: "black", textDecoration: "none" }} to={query}>
            {label}
          </Link>{" "}
          {index !== filteredSegments.length - 1 ? <NavArrow/>: ""}
        </Box>
      );
    });

    setLinks(links);
  }, [getLabel, location, location.pathname, project]);

  if (projectLoading) {
    return null;
  }

  return (
    <Typography
      display='flex'
      component="h1"
      variant="h6"
      color="inherit"
      noWrap
      sx={{ flexGrow: 1 }}
    >
      {links}
    </Typography>
  );
}
