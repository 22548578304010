import classes from "*.module.css";
import { CSSProperties, Dispatch, SetStateAction, useState } from "react";
import { BoundKey, CompatibilityBoundDataObject } from "../types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

interface ValidateDataButtonProps {
  setCompatibilityBounds: Dispatch<SetStateAction<[number, number]>>;
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
  },
  legendItem: {
    display: "flex",
    flexDirection: "row",
    marginRight: 20,
    padding: 10,
    height: "30px",
    alignItems: "center",
    border: "1px solid #cecece",
    borderRadius: "5%",
  },
  activeLegendItem: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#ECEFF1",
      // fontWeight: 'bold'
    },
  },
  legendIndicator: {
    height: 20,
    width: 20,
    borderRadius: "50%",
    marginRight: 5,
    border: "1px solid black",
  },
});
const boundsData: Record<BoundKey, CompatibilityBoundDataObject> = {
  errored: {
    bounds: [0, 0],
    color: "rgba(236,95,95,0.5)",
    text: "Errored",
  },
  "0Bound": {
    bounds: [0, 0],
    color: "#263238",
    text: "0%",
  },
  "1to10Bound": {
    bounds: [0.0000000000001, 10],
    color: "#455A64",
    text: "1-10%",
  },
  "10To20Bound": {
    bounds: [10, 20],
    color: "#546E7A",
    text: "11-20%",
  },
  "20To30Bound": {
    bounds: [20, 30],
    color: "#78909C",
    text: "21-30%",
  },
  "30To40Bound": {
    bounds: [30, 40],
    color: "#B0BEC5",
    text: "31-40%",
  },
  "40To50Bound": {
    bounds: [40, 50],
    color: "#CFD8DC",
    text: "41-50%",
  },
  "50To100Bound": {
    bounds: [50, 99.9999999999999],
    color: "#ECEFF1",
    text: "51-99%",
  },
  "100Bound": {
    bounds: [100, 100],
    color: "#FFFFFF",
    text: "100%",
  }
};

const CompatibilitiesLegend: React.FC<ValidateDataButtonProps> = ({
  setCompatibilityBounds,
}) => {
  const [chosenBound, setChosenBound] = useState<BoundKey | null>(null);

  const classes = useStyles();

  const handleBoundClick = (key: BoundKey) => {
    if (key === "errored") {
      return;
    }
    if (key === chosenBound) {
      setCompatibilityBounds([0, 100]);
      setChosenBound(null);
    } else {
      setCompatibilityBounds(boundsData[key].bounds);
      setChosenBound(key);
    }
  };

  const returnChosenStyle = (key: BoundKey): CSSProperties => {
    if (key === chosenBound) {
      return {
        fontWeight: "bold",
        backgroundColor: "#ECEFF1",
      };
    }
    return {};
  };
  return (
    <div className={classes.root}>
      {Object.entries(boundsData).map(([key, value]) => {
        return (
          <div
            className={clsx({
              [classes.legendItem]: true,
              [classes.activeLegendItem]: (key as BoundKey) !== "errored",
            })}
            style={returnChosenStyle(key as BoundKey)}
            key={key}
            onClick={() => {
              handleBoundClick(key as BoundKey);
            }}
          >
            <div
              className={classes.legendIndicator}
              style={{ backgroundColor: value.color }}
            ></div>
            <div>{value.text}</div>
          </div>
        );
      })}
    </div>
  );
};

export default CompatibilitiesLegend;
