import { gql } from "@apollo/client";

export const GET_PARAMETERS_METADATA = gql`
    query($entity: String) {
        getParamsMeta(entity: $entity) {
            key
            label
            description
            type
            twoDrivers
            label2
        }
    }
`

export const GET_PROJECT_PARAMETERS = gql`
    query($fetchParamsInput: FetchParamsInput!) {
        getSelectedParams(fetchParamsInput: $fetchParamsInput)
    }
`

export const GET_ENTITY_PARAMETER_KEY_MAP = gql`
    query{
        fetchEntityParameterKeyMap
    }
    `