import {
  Box,
  Theme,
  useTheme,
  Typography,
  Toolbar,
  CircularProgress,
  Button,
  Paper,
  TableContainer,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useCurrentProjectQuery } from "../../hooks";
import useOutputFieldMetadataQuery from "../hooks/useOutputFieldMetadataQuery";
import useOutputDataQuery from "../hooks/useOutputDataQuery";
import { DataGridPro } from "@mui/x-data-grid-pro";
import useColumnsData from "../hooks/useColumnsData";
import { makeStyles } from "@mui/styles";
import { useUnusedTrucksQuery, useProjectBatchesQuery } from "../hooks";
import BatchSelect from "./BatchSelect";
import {
  BatchType,
  PlanningCompletedEventData,
  PlanningFailedEventData,
} from "../types";
import {
  LocalShippingOutlined as UnusedAMIcon,
  ArrowDownward as DownloadIcon,
  Delete as DeleteIcon,
  ErrorOutlineRounded,
} from "@mui/icons-material";
import { PlanningForm } from "./PlanningForm";
import { InputEntityDataTable } from "../../inputData/components";
import useEntityMetadata from "../../inputData/hooks/useEntityMetadata";
import { useInputDataQuery } from "../../inputData/hooks";
import useLocations from "../../inputData/hooks/useLocations";
import PlanningFormDialog from "./PlanningFormDialog";
import { SocketContext } from "../../../context/SocketContext";
import handleDownload from "../../../common/utils/handleDownload";
import CompareKpisDialog from "./CompareKpisDialog";
import PlanKpiBar from "./PlanKpiBar.";
import { useTopbarActionsSetter } from "../../../context/TopbarActionsContext";
import { ActionButton } from "../../../common/components/ActionButton";
import { Navigate, useLocation } from "react-router-dom";
import clsx from "clsx";
import { useMutation, useReactiveVar } from "@apollo/client";
import { REMOVE_BATCH } from "../api/mutations";
import { selectedBatchCache } from "../cache";

type StyleProps = {
  theme: Theme;
};

interface PlanningResultsViewProps {
  view:
    | "createdPlan"
    | "unplannedOrders"
    | "erroredOrders"
    | "planning"
    | "unusedTrucks";
}

const useStyles = makeStyles({
  root: {
    width: "100%",
    "& .MuiDataGrid-row.Mui-odd": {
      "&:hover": {
        backgroundColor: "#eaebea",
      },

      backgroundColor: "#ebebf1",
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: (props: StyleProps) => "#1e87db",
      "& .vehicle--header": {
        backgroundColor: "#1e87db",
      },
      "& .base--header": {
        backgroundColor: (props: StyleProps) =>
          props.theme.palette.primary.main,
      },
      color: "white",
    },
  },
  header: {
    colCellWrapper: {
      backgroundColor: (props: StyleProps) => props.theme.palette.primary.main,
    },
  },
  title: {
    display: "flex",
    flexDirection: "row",
  },
});

export default function PlanningResultsView({
  view,
}: PlanningResultsViewProps) {
  const location = useLocation();
  const theme = useTheme();
  const classes = useStyles({
    theme,
  });

  const { socket, connectSocket } = useContext(SocketContext);
  const setActions = useTopbarActionsSetter();

  const { data: project, refetch: refetchProject } = useCurrentProjectQuery();

  const selectedBatch = useReactiveVar(selectedBatchCache);
  const setSelectedBatch = useCallback((newBatch: BatchType | null) => {
    selectedBatchCache(newBatch);
  }, []);

  const { data: currentProject, refetch } = useCurrentProjectQuery();
  const fieldsMetadata = useOutputFieldMetadataQuery();
  const trucksMeta = useEntityMetadata("trucks", {});
  const locations = useLocations();

  const [deleteBatch] = useMutation(REMOVE_BATCH);

  const metadata = useEntityMetadata("orders", {});

  const { data, refetch: refetchOutputData } = useOutputDataQuery(
    currentProject.id,
    selectedBatch?.id as string
  );

  // TODO MAKE OBJECT ARG
  const unplannedOrdersQuery = useInputDataQuery(
    project,
    "orders",
    {},
    {},
    true,
    true,
    true,
    undefined,
    undefined,
    "Unplanned",
      selectedBatch?.id
  );

  const erroredOrdersQuery = useInputDataQuery(
    project,
    "orders",
    {},
    {},
    true,
    true,
    true,
    undefined,
    undefined,
    "Error",
      selectedBatch?.id
  );

  const unusedTrucksQuery = useUnusedTrucksQuery(
    selectedBatch?.id as string,
    project.id,
    trucksMeta
  );
  const { refetch: refetchBatches, data: batches } = useProjectBatchesQuery(
    project.id
  );
  const { refetch: refetchCompareBatches } = useProjectBatchesQuery(
    project.id,
    true
  );

  useEffect(() => {
    connectSocket({
      projectId: project.id,
    });
  }, [connectSocket, project]);

  const handlePlanningCompleted = useCallback(
    async (data: PlanningCompletedEventData) => {
      console.log("PLAN Successful");
      const refetchBatchesResult = await refetchBatches({
        fetchPolicy: "network-only",
      });

      const refreshedBatches = refetchBatchesResult.data.fetchBatches;
      await refetchOutputData({
        batchId: data.batchId,
      });

      await unplannedOrdersQuery.refetch({
        fetchPolicy: "network-only",
      });
      await erroredOrdersQuery.refetch({
        fetchPolicy: "network-only",
      });

      const freshBatchData = refreshedBatches.find(
        (batch) => batch.id === data.batchId
      );

      if (freshBatchData) {
        setSelectedBatch(freshBatchData);
      }

      await refetchProject();
      await refetchCompareBatches();
      await refetch();

      await unusedTrucksQuery.refetch();
    },
    [
      erroredOrdersQuery,
      refetch,
      refetchBatches,
      refetchCompareBatches,
      refetchOutputData,
      refetchProject,
      setSelectedBatch,
      unplannedOrdersQuery,
      unusedTrucksQuery,
    ]
  );

  const handlePlanningFailed = useCallback(
    async (data: PlanningFailedEventData) => {
      const refetchBatchesResult = await refetchBatches();

      const refreshedBatches = refetchBatchesResult.data.fetchBatches;
      await refetchOutputData({
        batchId: data.batchId,
      });

      const freshBatchData = refreshedBatches.find(
        (batch) => batch.id === data.batchId
      );

      if (freshBatchData) {
        setSelectedBatch(freshBatchData);
      }

      await refetchProject();
      await refetchCompareBatches();
    },
    [
      refetchBatches,
      refetchCompareBatches,
      refetchOutputData,
      refetchProject,
      setSelectedBatch,
    ]
  );

  useEffect(() => {
    if (socket && project.id) {
      socket.on("planning_completed", handlePlanningCompleted);
    }

    return () => {
      if (socket) {
        socket.off("planning_completed", handlePlanningCompleted);
      }
    };
  }, [handlePlanningCompleted, project.id, socket]);

  useEffect(() => {
    if (socket && project.id) {
      socket.on("planning_failed", handlePlanningFailed);
    }

    return () => {
      if (socket) {
        socket.off("planning_failed", handlePlanningFailed);
      }
    };
  }, [handlePlanningFailed, project.id, socket]);

  const handlePlanningStarted = async (batch: any) => {
    setTimeout(async () => {
      await refetchBatches();
      await refetch();
    }, 2000);

    setTimeout(async () => {
      setSelectedBatch({
        ...batch,
        isProcessing: true,
      });
      closePlanningFormDialog();
    }, 3000);
  };

  const [planningFormDialog, setPlanningFormDialog] = useState({
    isOpen: false,
  });

  const [compareKpisDialog, setCompareKpisDialog] = useState({
    isOpen: false,
  });

  const openPlanningFormDialog = useCallback(() => {
    setPlanningFormDialog({ isOpen: true });
  }, []);

  const closePlanningFormDialog = useCallback(() => {
    setPlanningFormDialog({ isOpen: false });
  }, []);

  const openCompareKpisDialog = useCallback(() => {
    setCompareKpisDialog({ isOpen: true });
  }, []);

  const closeCompareKpisDialog = useCallback(() => {
    setCompareKpisDialog({ isOpen: false });
  }, []);

  const handleDeletePlanClick = useCallback(async () => {
    if (
      !selectedBatch ||
      !window.confirm("Do you really want to delete this planning version?")
    )
      return;

    await deleteBatch({
      variables: {
        batchId: selectedBatch.id,
      },
    });

    const { data } = await refetchBatches();

    const newBatches = data.fetchBatches;
    if (batches.length > 0) {
      setSelectedBatch(newBatches[0]);
    }

    await refetchCompareBatches();
  }, [
    batches.length,
    deleteBatch,
    refetchBatches,
    refetchCompareBatches,
    selectedBatch,
    setSelectedBatch,
  ]);

  const columnsData = useColumnsData(fieldsMetadata.data);

  useEffect(() => {
    setActions(
      <Box display="flex" alignItems="center">
        {currentProject.isPlanned && (
          <BatchSelect
            project={currentProject}
            selectedBatchState={[selectedBatch, setSelectedBatch as any]}
            openPlanningFormDialog={openPlanningFormDialog}
            openCompareKpisDialog={openCompareKpisDialog}
          />
        )}

        {view === "createdPlan" && selectedBatch && (
          <>
            <Box ml={2}>
              <ActionButton
                buttonText="Export"
                tooltipText="Export plan"
                icon={<DownloadIcon />}
                onClick={() => {
                  const serverUrl = process.env.REACT_APP_SERVER as string;
                  const fetchUrl = `${serverUrl}/planResults/export?projectId=${currentProject.id}&batchId=${selectedBatch.id}`;
                  handleDownload(fetchUrl, "PlanResults.txt");
                }}
                disabled={selectedBatch.isProcessing}
              />
            </Box>
            <Box ml={2}>
              <ActionButton
                buttonText="Delete"
                tooltipText="Delete this plan version"
                icon={<DeleteIcon />}
                onClick={handleDeletePlanClick}
                disabled={selectedBatch.isProcessing || batches.length < 2}
              />
            </Box>
          </>
        )}
      </Box>
    );

    // return () => {
    //   setActions(null);
    // };
  }, [
    batches.length,
    currentProject,
    handleDeletePlanClick,
    openCompareKpisDialog,
    openPlanningFormDialog,
    selectedBatch,
    setActions,
    setSelectedBatch,
    view,
  ]);

  return (
    <Paper sx={{ p: 2, height: "100%" }}>
      {view === "planning" && project.isPlanned && (
        <Navigate to={`${location.pathname}/createdPlan`} />
      )}
      {currentProject.isPlanned ? (
        <Box>
          <CompareKpisDialog
            project={project}
            onClose={closeCompareKpisDialog}
            isOpen={compareKpisDialog.isOpen}
            selectedBatch={selectedBatch}
            setSelectedBatch={setSelectedBatch as any}
          ></CompareKpisDialog>
        </Box>
      ) : (
        <PlanningForm project={currentProject} />
      )}

      <PlanningFormDialog
        project={project}
        onPlanningStarted={handlePlanningStarted}
        onClose={closePlanningFormDialog}
        isOpen={planningFormDialog.isOpen}
      ></PlanningFormDialog>

      {selectedBatch &&
        (selectedBatch.isPlanning || selectedBatch?.isProcessing) && (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <CircularProgress size={100} value={15} />
            <Typography sx={{ pt: 5 }}>
              This batch is currently planning
            </Typography>
          </Box>
        )}

      {selectedBatch &&
        !selectedBatch.isProcessing &&
        !selectedBatch.isPlanning && (
          <Box height="100%">
            <Box height="100%">
              {view === "createdPlan" &&
                (selectedBatch.isSuccess ? (
                  <>
                    <PlanKpiBar
                      projectId={currentProject.id}
                      batchId={selectedBatch.id}
                    />

                    <TableContainer
                      style={{ width: "100%", height: "calc(100% - 96px)" }}
                    >
                      <DataGridPro
                        columnBuffer={Number.MAX_SAFE_INTEGER}
                        pagination
                        className={clsx({
                          "entity-datatable": true,
                          [classes.root]: true,
                        })}
                        disableSelectionOnClick
                        density="compact"
                        rows={data.preplanned}
                        columns={columnsData}
                        pageSize={100}
                        rowsPerPageOptions={[]}
                        rowHeight={60}
                        disableColumnSelector={true}
                      />
                    </TableContainer>
                  </>
                ) : (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <ErrorOutlineRounded
                      fontSize={"large"}
                    ></ErrorOutlineRounded>
                    <Typography sx={{ pt: 2 }}>
                      {selectedBatch.message ||
                        "There was an error planning this project :("}
                    </Typography>
                  </Box>
                ))}

              {view === "unplannedOrders" && (
                <InputEntityDataTable
                  loading={unplannedOrdersQuery.loading}
                  data={unplannedOrdersQuery.data}
                  fieldsMetadata={metadata}
                  errors={{}}
                  warnings={{}}
                  onCellUpdate={() => {}}
                  setParametersEditDialog={() => {}}
                  setLocationEditDialog={() => {}}
                  locations={locations}
                  project={project}
                  readOnly
                  fullHeight
                ></InputEntityDataTable>
              )}

              {view === "erroredOrders" && (
                <InputEntityDataTable
                  loading={erroredOrdersQuery.loading}
                  data={erroredOrdersQuery.data}
                  fieldsMetadata={metadata}
                  errors={{}}
                  warnings={{}}
                  onCellUpdate={() => {}}
                  setParametersEditDialog={() => {}}
                  setLocationEditDialog={() => {}}
                  locations={locations}
                  project={project}
                  readOnly
                  fullHeight
                ></InputEntityDataTable>
              )}

              {view === "unusedTrucks" && (
                <InputEntityDataTable
                  loading={unusedTrucksQuery.loading}
                  data={unusedTrucksQuery.data}
                  fieldsMetadata={trucksMeta}
                  errors={{}}
                  warnings={{}}
                  onCellUpdate={() => {}}
                  setParametersEditDialog={() => {}}
                  setLocationEditDialog={() => {}}
                  locations={locations}
                  project={currentProject}
                  readOnly
                  fullHeight
                />
              )}
            </Box>
          </Box>
        )}
    </Paper>
  );
}
