import {
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";

import { Control, Controller, UseFormRegister } from "react-hook-form";
import { ParameterMetadata } from "../types";
import { DateTime } from "luxon";
import { DateTimePicker } from "@mui/lab";
import React, { useEffect, useRef } from "react";
import { useEntityManagerContext } from "../../inputData/context/EntityManagerContext";

type Value = string | number | boolean;

interface Props {
  value: Value;
  error?: string;
  register: any;
  parameterMetadata: ParameterMetadata;
  secondDriver?: boolean;
  control: Control<Record<string, any>>;
  focusedParameterState?: any; // TODO FIX
}

const getComponent = (
  { key, type, label, label2, twoDrivers }: ParameterMetadata,
  value: Value,
  register: UseFormRegister<Record<string, any>>,
  control: Control<Record<string, any>>,
  inputRef: React.MutableRefObject<HTMLDivElement | null>,
  secondDriver?: boolean,
  error?: string
): JSX.Element => {
  let fieldKey = key;
  let fieldLabel = secondDriver ? (label2 ? label2 : "") : label;

  if (twoDrivers) {
    if (secondDriver) {
      fieldKey += ".twoDriver";
    } else {
      fieldKey += ".oneDriver";
    }
  }

  switch (type) {
    case "money":
    case "duration":
    case "number":
    case "percentage":
      return (
        <TextField
          error={!!error}
          helperText={error}
          fullWidth
          type="number"
          margin="dense"
          label={fieldLabel}
          id={fieldKey}
          name={fieldKey}
          defaultValue={value}
          inputRef={inputRef}
          onWheel={(event: any) => event.target.blur()}
          inputProps={{
            step: type === "percentage" ? "0.01" : "1",
            ...register(fieldKey),
          }}
        ></TextField>
      );
    case "boolean":
      return (
        <FormControl>
          <FormControlLabel
            control={
              <Controller
                defaultValue={value}
                control={control}
                name={fieldKey as any}
                render={({ field }) => (
                  <Checkbox
                    onBlur={field.onBlur}
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                    inputRef={field.ref}
                  />
                )}
              />
            }
            label={fieldLabel}
          />
        </FormControl>
      );
    case "dateTime":
      const defaultValue = value
        ? DateTime.fromSeconds(value as number).toUTC()
        : null;
      return (
        <Controller
          defaultValue={defaultValue}
          control={control}
          name={fieldKey as any}
          render={({ field }) => (
            <DateTimePicker
              label={fieldLabel}
              renderInput={(props) => (
                <TextField
                  {...props}
                  margin="dense"
                  fullWidth
                  error={!!error}
                  helperText={error}
                />
              )}
              onChange={(newValue) => {
                field.onChange(newValue);
              }}
              value={field.value}
            />
          )}
        />
      );
    default:
      return (
        <TextField
          error={!!error}
          helperText={error}
          fullWidth
          margin="dense"
          label={fieldLabel}
          id={key}
          name={fieldKey}
          defaultValue={value}
          onWheel={(event) => event.currentTarget.blur()}
          inputProps={{ ...register(fieldKey) }}
          inputRef={inputRef}
        ></TextField>
      );
  }
};

const ParameterInputField = ({
  value,
  register,
  parameterMetadata,
  secondDriver,
  control,
  error,
  focusedParameterState,
}: Props): JSX.Element => {
  const inputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let focusedParameters;
    if (focusedParameterState) {
      [focusedParameters] = focusedParameterState;
    }

    if (focusedParameters) {
      const focusedParameter = focusedParameters[0];
      const suffix = focusedParameter.slice(-2);

      const driverDependant = ["1D", "2D"].includes(suffix);

      const parameterKey = driverDependant
        ? focusedParameter.substring(0, focusedParameter.length - 2)
        : focusedParameter;

      if (parameterMetadata.key === parameterKey) {
        const shouldFocus =
          driverDependant === false ||
          (suffix === "1D" && !secondDriver) ||
          (suffix === "2D" && secondDriver);
        if (shouldFocus) {
          const node = inputRef.current;
          node?.focus();
        }
      }
    }
  }, [focusedParameterState, parameterMetadata.key, secondDriver]);

  const inputComponent = getComponent(
    parameterMetadata,
    value,
    register,
    control,
    inputRef,
    secondDriver,
    error
  );

  return inputComponent;
};

export default ParameterInputField;
