import React, { Dispatch, SetStateAction, useEffect } from "react";
import { BatchType } from "../types";
import { useProjectBatchesQuery } from "../hooks";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Project } from "../../types";
import { ActionButton } from "../../../common/components/ActionButton";

import {
  Sync as ReplanIcon,
  CompareArrows as CompareIcon,
} from "@mui/icons-material";

interface BatchSelectProps {
  project: Project;
  selectedBatchState: [
    BatchType | null,
    Dispatch<SetStateAction<BatchType | null>>
  ];
  openPlanningFormDialog: () => void;
  openCompareKpisDialog: () => void;
}

const BatchSelect: React.FC<BatchSelectProps> = ({
  project,
  selectedBatchState,
  openPlanningFormDialog,
  openCompareKpisDialog,
}) => {
  const { data: batches, loading: batchesLoading } = useProjectBatchesQuery(
    project.id
  );

  const [selectedBatch, setSelectedBatch] = selectedBatchState;

  useEffect(() => {
    console.log(selectedBatch);
  }, [selectedBatch]);

  if (batches.length !== 0 && !selectedBatch) {
    setSelectedBatch(batches[0]);
  }

  const handleChange = (event: any) => {
    if (event.target.value !== selectedBatch?.id) {
      const batch = batches.find((item) => item.id === event.target.value);

      if (batch) {
        console.log(batch);
        setSelectedBatch(batch);
      }
    }
  };

  return batches.length !== 0 && selectedBatch ? (
    <>
      <Box display="flex" alignItems="center">
        <FormControl sx={{ minWidth: 80 }}>
          <InputLabel sx={{ pt: "7px", fontSize: "0.8rem" }}>
            Select version
          </InputLabel>
          <Select
            value={selectedBatch.id}
            onChange={handleChange}
            autoWidth
            size="small"
            label="Select version"
          >
            {batches.map((batch: BatchType) => {
              return <MenuItem value={batch.id}>{batch.name}</MenuItem>;
            })}
          </Select>
        </FormControl>
        <Box ml={2}>
          <ActionButton
            buttonText="Re-plan"
            tooltipText={"Re-plan this project"}
            icon={<ReplanIcon />}
            onClick={openPlanningFormDialog}
            disabled={project.isPlanning}
          />
        </Box>
        <Box ml={2}>
          <ActionButton
            buttonText="Compare"
            tooltipText={"Compare versions"}
            icon={<CompareIcon />}
            onClick={openCompareKpisDialog}
            disabled={project.isPlanning}
          />
        </Box>
      </Box>
    </>
  ) : !batchesLoading ? (
    <h5>No plan results were found</h5>
  ) : null;
};

export default BatchSelect;
