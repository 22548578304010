import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { GET_UNUSED_TRUCKS } from "../api/queries";
import { formatDatetimeValues } from "../../../common/utils";
import { FieldMetadata } from "../../types";

export default function useUnusedTrucksQuery(
  batchId: string,
  projectId: string,
  metadata: Record<string, FieldMetadata>
) {
  const query = useQuery(GET_UNUSED_TRUCKS, {
    variables: { batchId, projectId },
    skip: !batchId,
  });

  const formattedTrucks = useMemo(() => {
    const unusedTrucks =
      query.data && query.data.fetchUnusedTrucks
        ? query.data.fetchUnusedTrucks
        : [];
    return formatDatetimeValues(unusedTrucks, metadata, true);
  }, [metadata, query.data]);

  return { ...query, data: formattedTrucks };
}
