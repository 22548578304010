import { InputBase } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import React, { useCallback } from "react";


export default function PercentageEditCell(
    props: GridRenderCellParams
) {
    const { id, value, api, field} = props;

    const handleChange = useCallback(
        (event) => {
            api.setEditCellValue({ id, field, value: event.target.value }, event);
        },
        [api, field, id]
    );

    return (
            <InputBase
                margin={"none"}
                autoFocus
                className="MuiDataGrid-editCellInputBase"
                fullWidth
                type="number"
                inputProps={{
                    step:"0.01"
                }}
                value={value}
                onChange={handleChange}
            />
    );
}
