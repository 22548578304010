import { fromPromise } from "@apollo/client";

import { onError } from "@apollo/client/link/error";
import { REFRESH_TOKEN } from "./auth/api/mutations";
import { print } from "graphql/language/printer";

const endpoint = process.env.REACT_APP_GRAPHQL_SERVER as string;

let isRefreshing = false;
let isSigningOut = false;
let pendingRequests: Function[] = [];

const setIsRefreshing = (value: boolean) => {
  isRefreshing = value;
};

const addPendingRequest = (pendingRequest: Function) => {
  pendingRequests.push(pendingRequest);
};

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

const getNewToken = async () => {
  const query = JSON.stringify({
    query: print(REFRESH_TOKEN),
  });

  return fetch(endpoint, {
    headers: { "content-type": "application/json" },
    credentials: "include",
    method: "POST",
    body: query,
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.data) {
        const token = response.data.refreshUserToken.token;
        localStorage.setItem("token", token);
      }
    });
};

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (networkError) {
      console.log("got Network error");
      console.log(networkError);
    }
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        if (err.extensions) {
          const headers = operation.getContext().headers;

          const status = err.extensions?.statusCode;

          if (status === 403) {
            if (!isSigningOut) {
              isSigningOut = true;

              alert(err.message);
              localStorage.removeItem("token");
              window.location.reload();
            }
          }

          if (headers.Authorization && status && status === 401) {
            if (!isRefreshing) {
              setIsRefreshing(true);

              return fromPromise(
                getNewToken().catch(() => {
                  resolvePendingRequests();
                  setIsRefreshing(false);
                  localStorage.clear();
                  return forward(operation);
                })
              ).flatMap(() => {
                resolvePendingRequests();
                setIsRefreshing(false);

                return forward(operation);
              });
            } else {
              return fromPromise(
                new Promise<void>((resolve) => {
                  addPendingRequest(() => resolve());
                })
              ).flatMap(() => {
                return forward(operation);
              });
            }
          }
        }
      }
    }
  }
);

export default errorLink;
