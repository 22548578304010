import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  List,
  ListItem,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { EntityString, ImportDialog, ImportResult } from "../types";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { BootstrapDialogTitle } from "../../../common/components/BootstrapDialogTitle";
import { LoadingButton } from "@mui/lab";

interface Props {
  dialog: {
    open: boolean;
    entity: EntityString;
  };
  setDialog: Dispatch<SetStateAction<ImportDialog>>;
  importFileHandler: any;
  refetchProject: any;
  checkCompatibilities: any;
  validate: any;
}

interface ImportOutputProps {
  importResult: ImportResult;
}

const useStyles = makeStyles({
  matching: {
    backgroundColor: "#81ff68",
    fontWeight: "bold",
  },
  notMatching: {
    backgroundColor: "#ec5f5f",
    fontWeight: "bold",
    color: "white",
  },
});

const ImportOutput: React.FC<ImportOutputProps> = ({ importResult }) => {
  const classes = useStyles({});
  const hasErrors = !importResult.validationPassed;

  if (!hasErrors) {
    return null;
  }

  const { fileStructureErrors, otherStructureErrors, fieldTypeErrors } =
    importResult;

  const fileStructureErrorsTable = fileStructureErrors ? (
    <>
      <h3>Field type errors</h3>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Order</TableCell>
            <TableCell>Expected field</TableCell>
            <TableCell>Actual field</TableCell>
            <TableCell>Fields match</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {fileStructureErrors.map((error, idx) => (
            <TableRow key={idx}>
              <TableCell>{idx + 1}</TableCell>
              <TableCell>{error.expectedField}</TableCell>
              <TableCell>{error.actualField}</TableCell>
              <TableCell
                className={clsx({
                  [classes.matching]: error.fieldsMatch,
                  [classes.notMatching]: !error.fieldsMatch,
                })}
              >
                {error.fieldsMatch ? "Yes" : "No"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  ) : null;
  const fieldTypeErrorsTable =
    fieldTypeErrors !== null ? (
      <>
        <h3>Field type errors</h3>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Line</TableCell>
              <TableCell>Field</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Errors</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {fieldTypeErrors?.map((error, idx) => (
              <TableRow key={idx}>
                <TableCell>{error.line}</TableCell>
                <TableCell>{error.field}</TableCell>
                <TableCell>{error.value}</TableCell>
                <TableCell>
                  <List>
                    {error.errors.map((error, idx) => (
                      <ListItem key={idx}>{error}</ListItem>
                    ))}
                  </List>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </>
    ) : null;

  const otherErrorsList =
    otherStructureErrors && otherStructureErrors.length > 0 ? (
      <>
        <h3>Errors found:</h3>
        <List>
          {otherStructureErrors.map((error, idx) => (
            <ListItem key={idx}>{error}</ListItem>
          ))}
        </List>
      </>
    ) : null;

  return (
    <Box p={3}>
      {fileStructureErrorsTable}
      {otherErrorsList}
      {fieldTypeErrorsTable}
    </Box>
  );
};

const DataImportDialog: React.FC<Props> = ({
  dialog,
  setDialog,
  importFileHandler,
  refetchProject,
  validate,
  checkCompatibilities,
}) => {
  const { register, handleSubmit, watch } = useForm({
    reValidateMode: "onChange",
  });

  const watchFile = watch("file", {});

  const [isProcessing, setIsProcessing] = useState(false)

  const [importResult, setImportResult] = useState<ImportResult | null>(null);

  useEffect(() => {
    if (dialog) {
      setImportResult(null);
    }
  }, [dialog, watchFile]);

  const closeDialog: () => void = () => {
    setDialog({
      ...dialog,
      open: false,
    });
    validate();
    if (dialog.entity === "orders") {
      checkCompatibilities();
    }
  };

  const onSubmit = async (data: any): Promise<void> => {
    setIsProcessing(true)
    const response = await importFileHandler({
      variables: {
        [dialog.entity as string]: data.file[0],
      },
    });

    const result: ImportResult = response.data.importFiles[0];

    if (result.validationPassed) {
      refetchProject();

      setTimeout(() => {
        setIsProcessing(false)
        closeDialog();
      }, 2000)
   
    } else {
      setImportResult(result);
      setIsProcessing(false)
    }
  };

  return (
    <div>
      <Dialog
        open={dialog.open}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <BootstrapDialogTitle id="form-dialog-title" onClose={closeDialog}>
          Import data
        </BootstrapDialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText>
              Select .txt file containing{" "}
              {dialog.entity === "trucks" ? "vehicles" : dialog.entity} data
            </DialogContentText>

            <input
              style={{ display: "none" }}
              accept="text/*"
              id="contained-button-file"
              type="file"
              {...register("file")}
            />
            <label
              style={{ marginRight: "10px" }}
              htmlFor="contained-button-file"
            >
              <Button variant="contained" color="primary" component="span">
                Upload file
              </Button>
            </label>

            {watchFile && watchFile[0] && watchFile[0].name}
          </DialogContent>

          <DialogActions>
            <LoadingButton loading={isProcessing} type="submit" color="primary" disabled={!watchFile}>
              Submit
            </LoadingButton>
          </DialogActions>
        </form>

        {importResult !== null && (
          <ImportOutput importResult={importResult}></ImportOutput>
        )}
      </Dialog>
    </div>
  );
};

export default DataImportDialog;
