import { Reference, useMutation, useQuery } from "@apollo/client";
import {
  DataGrid,
  GridCellParams,
  GridColumns,
  GridRowModel,
} from "@mui/x-data-grid";
import {
  Button,
  Box,
  IconButton,
  FormControlLabel,
  Switch,
  CircularProgress,
  Theme,
  useTheme,
  TableContainer,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Paper,
  Tooltip,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Visibility as ShowIcon,
  Settings as ParametersIcon,
  Undo as UndoIcon,
  FileCopy as CopyIcon,
  Close as UnplannedIcon,
  Check as PlannedIcon,
  Archive as ArchiveIcon,
} from "@mui/icons-material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Project } from "../types";
import { ProjectFormDialog, ProjectParametersDialog } from "../components";
import { GET_PROJECTS } from "../api/queries";
import {
  DELETE_PROJECT,
  DUPLICATE_PROJECT,
  TOGGLE_PROJECT_ARCHIVED_STATE,
} from "../api/mutations";
import { Navigate, useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { useAuth } from "../../auth/hooks";
import { SocketContext } from "../../context/SocketContext";
import { makeStyles } from "@mui/styles";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

type StyleProps = {
  theme: Theme;
};

const useStyles = makeStyles({
  root: {
    width: "100%",
    "& .MuiDataGrid-row.Mui-odd": {
      backgroundColor: "#ebebf1",
    },
    "& .MuiDataGrid-row:hover": {
      backgroundColor: "#d4d4d4",
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: (props: StyleProps) => props.theme.palette.primary.main,
      color: "white",
    },
  },
});

interface ActionButton {
  clickHandler: (row: GridRowModel) => void;
  icon: JSX.Element;
  name: string;
  tooltipLabel: string;
}

interface ActionButtonsProps {
  actions: ActionButton[];
  row: GridRowModel;
  archivedState: boolean;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  actions,
  row,
  archivedState,
}) => {
  const buttons = actions.map(
    ({ clickHandler, icon, name, tooltipLabel }, idx) => {
      return archivedState || (!archivedState && name !== "archive") ? (
        <Tooltip key={`${row.id}-${idx}`} title={tooltipLabel}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              clickHandler(row);
            }}
          >
            {icon}
          </IconButton>
        </Tooltip>
      ) : null;
    }
  );
  return <div>{buttons}</div>;
};

const ProjectsList: React.FC<Props> = () => {
  const navigate = useNavigate();
  const { data, refetch } = useQuery<{ projects: Project[] }>(GET_PROJECTS);
  const { user, loading } = useAuth();
  const { connectSocket, socket } = useContext(SocketContext);

  const theme = useTheme();
  const classes = useStyles({ theme });

  const getDeleteIcon = (showArchived: boolean) => {
    return showArchived ? <DeleteIcon /> : <ArchiveIcon />;
  };

  useEffect(() => {
    if (user) {
      connectSocket({
        userId: user.id,
      });
    }
  }, [connectSocket, user]);

  useEffect(() => {
    if (socket && user) {
      socket.on(`planning_completed`, refetch).on(`planning_failed`, refetch);

      return () => {
        if (socket) {
          socket
            .off(`planning_completed`, refetch)
            .off("planning_failed", refetch);
        }
      };
    }
  }, [refetch, socket, user]);

  const [projectDialog, setProjectDialog] = useState<{
    open: boolean;
    edited: Project | null;
  }>({
    open: false,
    edited: null,
  });

  const [showArchived, setShowArchived] = useState(false);
  const [filteredProjects, setFilteredProjects] = useState<Project[]>([]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    const projects = data ? data.projects : [];

    const filteredProjects = projects.filter((project) =>
      showArchived ? !!project.archivedAt : !project.archivedAt
    );

    setFilteredProjects(filteredProjects);
  }, [data, showArchived]);

  const [projectParametersDialog, setProjectParametersDialog] = useState<{
    open: boolean;
    edited: Project | null;
  }>({
    open: false,
    edited: null,
  });

  const [deleteProject] = useMutation(DELETE_PROJECT, {
    update(cache, { data: { removeProject } }) {
      cache.modify({
        fields: {
          projects(existingProjectRefs: Reference[] = [], { readField }) {
            const projects = existingProjectRefs.filter(
              (projectRef) => removeProject.id !== readField("id", projectRef)
            );
            return projects;
          },
        },
      });
    },
  });

  const [duplicateProject] = useMutation(DUPLICATE_PROJECT);

  const handleRowClick = useCallback(
    (row) => {
      const project = row as Project;

      navigate(`/projects/${project.id}/vehicles`);
    },
    [navigate]
  );

  const [toggleProjectArchivedState] = useMutation(
    TOGGLE_PROJECT_ARCHIVED_STATE
  );

  const actions: ActionButton[] = [
    {
      name: "show",
      icon: <ShowIcon />,
      clickHandler: handleRowClick,
      tooltipLabel: "Show project",
    },
    {
      name: "edit",
      icon: <EditIcon />,
      clickHandler: (row) => {
        const edited = row as Project;
        setProjectDialog({
          open: true,
          edited: edited,
        });
      },
      tooltipLabel: "Edit project",
    },
    {
      name: "parameters",
      icon: <ParametersIcon />,
      clickHandler: (row) => {
        const edited = row as Project;
        setProjectParametersDialog({
          open: true,
          edited: edited,
        });
      },
      tooltipLabel: "Show Parameters",
    },
    {
      name: "archive",
      icon: <UndoIcon />,
      clickHandler: (row) => {
        if (
          window.confirm("Are you sure you want to unarchive this project?")
        ) {
          toggleProjectArchivedState({
            variables: {
              id: row.id,
            },
          });
        }
      },
      tooltipLabel: "Archive project",
    },
    {
      name: "duplicate",
      icon: <CopyIcon />,
      clickHandler: async (row) => {
        const projectName = window.prompt("Set name of the new project");

        try {
          if (projectName) {
            const {
              data: { duplicateProject: project },
            } = await duplicateProject({
              variables: {
                id: row.id,
                name: projectName,
                userId: user.id,
              },
            });

            navigate((project as Project).id);
            await refetch();
          }
        } catch (err) {
          if (
            err.graphQLErrors[0]?.extensions?.statusCode === 400 ||
            "BAD_USER_INPUT"
          ) {
            alert(err.graphQLErrors[0]?.message);
          }
        }
      },
      tooltipLabel: "Duplicate project",
    },
    {
      name: "delete",
      icon: getDeleteIcon(showArchived),
      clickHandler: async (row) => {
        const message = !showArchived
          ? "Are you sure you want to archive this project?"
          : "Are you sure you want to delete this project?";
        if (window.confirm(message)) {
          if (showArchived) {
            await deleteProject({
              variables: {
                id: row.id,
              },
            });
          } else {
            await toggleProjectArchivedState({
              variables: {
                id: row.id,
              },
            });
          }
          await refetch();
        }
      },
      tooltipLabel: !showArchived ? "Archive project" : "Delete project",
    },
  ];

  const columns: GridColumns = [
    { field: "name", headerName: "Name", width: 250 },
    {
      field: "createdAt",
      headerName: "Created at",
      width: 250,
      valueFormatter: ({ value }) =>
        value
          ? DateTime.fromISO(value as any).toLocaleString(DateTime.DATETIME_MED)
          : "",
    },
    {
      field: "lastViewedAt",
      headerName: "Last viewed",
      width: 250,
      valueFormatter: ({ value }) =>
        value
          ? DateTime.fromISO(value as any).toLocaleString(DateTime.DATETIME_MED)
          : "",
    },
    {
      field: "isPlanned",
      headerName: "Planned",
      width: 250,
      renderCell: (params) => (
        <>
          {params.row.isPlanning ? (
            <CircularProgress size={20} color="primary" />
          ) : params.row.isPlanned ? (
            <PlannedIcon />
          ) : (
            <UnplannedIcon />
          )}
        </>
      ),
    },
    {
      field: "isPlanning",
      hide: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 250,
      renderCell: ({ row }: GridCellParams) => {
        const buttons = (
          <ActionButtons
            actions={actions}
            row={row}
            archivedState={showArchived}
          ></ActionButtons>
        );

        return buttons;
      },
    },
  ];
  const projects: Project[] = filteredProjects;

  const handleRadioClick = useCallback((e) => {
    setShowArchived(e.target.value === "true");
  }, []);

  return (
    <Paper variant="outlined" sx={{ p: 2, height: "100%" }}>
      {(!loading && user) || loading ? (
        <>
          <ProjectFormDialog
            dialog={projectDialog}
            setDialog={setProjectDialog}
            setShowArchived={setShowArchived}
            showArchived={showArchived}
          ></ProjectFormDialog>

          <ProjectParametersDialog
            dialog={projectParametersDialog}
            setDialog={setProjectParametersDialog}
          />

          <Box marginBottom={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                setProjectDialog({
                  open: true,
                  edited: null,
                })
              }
            >
              Create new
            </Button>
          </Box>
          <Box display={"flex"} flexDirection={"row"}>
            <Box mb={2} mr={2}>
              <FormControl component="fieldset">
                {/* <FormLabel component="legend">View:</FormLabel> */}
                <RadioGroup
                  row
                  aria-label="gender"
                  name="row-radio-buttons-group"
                  value={String(showArchived)}
                  onChange={handleRadioClick}
                >
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="Active"
                  />
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Archived"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>

          <TableContainer style={{ width: "100%", height: "100%" }}>
            <DataGrid
              autoHeight
              density="compact"
              className={classes.root}
              rows={projects}
              columns={columns}
              pageSize={15}
              onRowClick={handleRowClick}
            />
          </TableContainer>
        </>
      ) : (
        <Navigate to="/login" />
      )}
    </Paper>
  );
};

export default ProjectsList;
