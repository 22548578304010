import {
  Box,
  FormControlLabel,
  Switch,
  Button,
  Alert,
  Snackbar,
  Grid,
  Typography,
  Paper,
  IconButton,
  Tooltip,
  Divider,
  Backdrop,
  CircularProgress,
  Link,
  SvgIcon,
} from "@mui/material";

import {
  Approval as ValidationIcon,
  Check as CheckCompatibilitiesIcon,
  ArrowDownward as ImportIcon,
  Add as AddIcon,
  Settings as ParametersIcon,
  ArrowUpward as DownloadIcon,
} from "@mui/icons-material";

import React, {
  ChangeEvent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  EntityField,
  Entity,
  ValidationError,
  IssueTraversalCoordinates,
  EntityString,
  LocalValidationFilterResult,
  LocalValidationFormattingResult,
} from "../types";

import {
  useEntityManagerActions,
  useImportFilesMutation,
  useInputDataQuery,
} from "../hooks";

import {
  CompatibilitiesLegend,
  DataImportDialog,
  EntityFormDialog,
  ImportDataButton,
  InputEntityDataTable,
  ValidateDataButton,
  ValidationErrorsTable,
} from ".";
import {
  GridEditCellPropsParams,
  GridEventListener,
  GridEvents,
  GridRowParams,
} from "@mui/x-data-grid-pro";
import { capitalizeFirstLetter } from "../../../utilities/helpers";
import { useEntityManagerContext } from "../context/EntityManagerContext";
import { transformValue } from "../utilities/common";
import { useOrderRowClassCallback } from "../hooks/useOrderRowClassCallback";
import useEntityDataManagerDialogs from "../hooks/useEntityDataManagerDialogs";
import useEntityManagerValidation from "../hooks/useEntityManagerValidation";
import { useEntityManagerCompatibilities } from "../hooks/useEntityManagerCompatibilities";
import { useUpdateEntityMutation } from "../hooks/useUpdateEntityMutation";
import useEntityMetadata from "../hooks/useEntityMetadata";
import PropertyEditDialog from "./PropertyEditDialog";
import CompatibilitiesDetailsDialog from "./CompatibilitiesDetailsDialog";
import ParametersEditDialog from "../../parameters/components/ParametersEditDialog";
import LocationEditDialog from "./LocationEditDialog";
import { useDeleteEntitiesMutation } from "../hooks/useDeleteEntitiesMutation";
import handleDownload from "../../../common/utils/handleDownload";
import { useTopbarActionsSetter } from "../../../context/TopbarActionsContext";
import {
  ActionButton,
  ActionButtonProps,
} from "../../../common/components/ActionButton";
import { useRowClassCallback } from "../hooks/useRowClassCallback";
import TraverseIssuesButtons from "./TraverseIssuesButtons";
import { isEmpty, omit } from "lodash";
import { IconLegend } from "../../components/IconLegend";
import useFieldDisplayConfigs, {
  AdditionalFieldsMetadata,
} from "../hooks/useFieldDisplayConfigs";
import useIssuesTableContent from "../hooks/useIssuesTableContent";
import theme from "../../../theme";
import {
  errorReplaceCallback,
  generateErrorMessageString,
  replaceValidationErrors,
} from "../utilities/errorsTransformer";
import { MutationResult, OperationVariables } from "@apollo/client";
import { useCreateEntityMutation } from "../hooks/useCreateEntityMutation";
import { SocketContext } from "../../../context/SocketContext";

const EntityDataManager: React.FC<{}> = () => {
  const { project, refetchProject, entity, locations, focusedParameterState } =
    useEntityManagerContext();
  const [, setFocusedParameters] = focusedParameterState;

  const {
    validate,
    errors,
    warnings,
    groupedErrors,
    groupedWarnings,
    isValidating,
    selectedErrorRowId,
    setSelectedErrorRowId,
    traversalCoords,
    setTraversalCoords,
    setErrors,
    setWarnings,
    setGroupedErrors,
    setGroupedWarnings,
  } = useEntityManagerValidation(project, entity);

  const { connectSocket } = useContext(SocketContext);

  useEffect(() => {
    connectSocket({
      projectId: project.id,
    });
  }, [connectSocket, project]);

  const issues = useMemo(() => {
    const errArr: ValidationError[] = errors ?? [];
    const warnErr: ValidationError[] = warnings ?? [];
    return [...errArr, ...warnErr];
  }, [errors, warnings]);

  const [includeRowsWithErrors, setIncludeRowsWithErrors] = useState(true);
  const [includeRowsWithWarnings, setIncludeRowsWithWarnings] = useState(true);
  const [includeValidRows, setIncludeValidRows] = useState(true);

  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);

  const issuesTableContent = useIssuesTableContent(
    errors,
    warnings,
    includeRowsWithErrors,
    includeRowsWithWarnings
  );

  const {
    checkCompatibilities,
    compatibilitiesData,
    loadingCompatibilities,
    highlightCompatibilities,
    setHighlightCompatibilities,
    compatibilityBoundsFilter,
    setFilteredBounds,
  } = useEntityManagerCompatibilities(project);

  const { selectionModel, setSelectionModel } = useEntityManagerActions();

  const getOrdersRowClass = useOrderRowClassCallback(
    groupedErrors,
    groupedWarnings,
    highlightCompatibilities,
    compatibilitiesData
  );

  const getRowClass = useRowClassCallback(groupedErrors, groupedWarnings);

  const { createEntity, createResponse } = useCreateEntityMutation(entity);

  const [updateEntity] = useUpdateEntityMutation(entity);

  const { deleteEntities, deleteResponse } = useDeleteEntitiesMutation(
    entity,
    selectionModel,
    setSelectionModel,
    selectedErrorRowId,
    setSelectedErrorRowId,
    validate,
    refetchProject
  );

  const { additionalColumnsInfo, updateFieldWidth, updateFieldsOrder } =
    useFieldDisplayConfigs(project.id, entity);

  const dataQuery = useInputDataQuery(
    project,
    entity,
    groupedErrors,
    groupedWarnings,
    includeValidRows,
    includeRowsWithWarnings,
    includeRowsWithErrors,
    entity === "orders" && highlightCompatibilities
      ? compatibilityBoundsFilter
      : undefined // TODO make generic solution
  );

  // useEffect(() => {
  //   if (errors !== null && errors.length === 0) {
  //     setSuccessSnackbarOpen(true);
  //   }
  // }, [dataQuery.data, errors]);

  const {
    importDialog,
    setImportDialog,
    entityDialog,
    setEntityDialog,
    propertyEditDialog,
    setPropertyEditDialog,
    toggleImportDialog,
    compatibilitiesDetailsDialog,
    setCompatibilitiesDetailsDialog,
    parametersEditDialog,
    setParametersEditDialog,
    locationEditDialog,
    setLocationEditDialog,
  } = useEntityDataManagerDialogs(entity);

  const setActions = useTopbarActionsSetter();

  const additionalFields: AdditionalFieldsMetadata | null = useMemo(() => {
    const fields: Record<string, EntityField> = {};
    if (entity === "orders") {
      fields["compatibleTrucks"] = {
        shortLabel: "Compatible vehicles",
        order: 100,
        size: 160,
        description: "Compatible vehicles count",
        fieldCategory: "orderDriving",
        entity: "orders",
        type: "number",
        tableOnly: true,
        hide: () => !compatibilitiesData,
        sortComparator: (v1: any, v2: any, param1: any, param2: any) => {
          if (compatibilitiesData[param1.id] === undefined) {
            return -1;
          } else if (compatibilitiesData[param2.id] === undefined) {
            return 1;
          } else {
            return (
              compatibilitiesData[param1.id].truckCount -
              compatibilitiesData[param2.id].truckCount
            );
          }
        },
        onCellClick: (params) => {
          setCompatibilitiesDetailsDialog({
            open: true,
            orderData: params.row,
            projectId: project.id,
            compatibilitiesInfo: compatibilitiesData[params.row.id],
          });
        },
        valueGetter: ({ row }) => {
          if (!compatibilitiesData || !compatibilitiesData[row.id]) return null;

          return compatibilitiesData[row.id].truckCount;
        },
      };
    }

    return { ...fields, ...additionalColumnsInfo };
  }, [
    entity,
    additionalColumnsInfo,
    compatibilitiesData,
    setCompatibilitiesDetailsDialog,
    project.id,
  ]);

  const metadata = useEntityMetadata(entity, additionalFields);

  const [importFile] = useImportFilesMutation(project, importDialog.entity);

  const handleRowClick = (params: GridRowParams) => {
    if (!project.isPlanned && !project.isPlanning) {
      setEntityDialog({
        entityObject: params.row as Entity,
        entityType: entity,
        open: true,
      });
    }
  };

  const showLoader = useMemo(() => {
    return loadingCompatibilities || isValidating || dataQuery.loading;
  }, [dataQuery.loading, isValidating, loadingCompatibilities]);

  useEffect(() => {
    checkCompatibilities();
    validate();
  }, [checkCompatibilities, validate]);

  const handleErrorClick = (coords: IssueTraversalCoordinates) => {
    setTimeout(() => {
      setSelectionModel([]);
      const container = document.getElementById("content-container");
      container?.scrollTo({ top: 0, behavior: "smooth" });
      setTraversalCoords(coords);
    });
  };

  const resetCompatibilitiesFilter = (e: ChangeEvent<HTMLInputElement>) => {
    setFilteredBounds([0, 100]);
    setHighlightCompatibilities(e.target.checked);
  };

  useEffect(() => {
    const buttonElementProps: ActionButtonProps[] = [
      {
        tooltipText: "Add new",
        buttonText: "Add",
        icon: <AddIcon />,
        disabled: project.isPlanned || project.isPlanning,
        onClick: () => {
          setEntityDialog({
            entityObject: null,
            entityType: entity,
            open: true,
          });
        },
      },
      {
        tooltipText: "Import data",
        buttonText: "Import",
        icon: <ImportIcon />,
        disabled: project.isPlanned || project.isPlanning,
        onClick: () => toggleImportDialog(true),
      },
      {
        tooltipText: "Export data",
        buttonText: "Export",
        icon: <DownloadIcon />,
        disabled: false,
        onClick: () => {
          const serverUrl = process.env.REACT_APP_SERVER as string;
          const fetchUrl = `${serverUrl}/exports/exportUserData?projectId=${project.id}&entityString=${entity}`;
          handleDownload(fetchUrl, `exported${entity}.txt`);
        },
      },
      {
        tooltipText: "Edit Parameters",
        buttonText: "Parameters",
        icon: <ParametersIcon />,

        disabled: false,
        onClick: () => {
          setFocusedParameters(null);
          setParametersEditDialog({
            edited: project,
            entity: entity,
            open: true,
          });
        },
      },
    ];

    const buttonElements = (
      <Box>
        {buttonElementProps.map((buttonProps) => {
          return (
            <ActionButton
              key={`action-${buttonProps.buttonText}`}
              {...buttonProps}
            />
          );
        })}
      </Box>
    );

    setActions(buttonElements);
  }, [
    checkCompatibilities,
    dataQuery.data.length,
    entity,
    isValidating,
    loadingCompatibilities,
    project,
    setActions,
    setEntityDialog,
    setFocusedParameters,
    setParametersEditDialog,
    toggleImportDialog,
  ]);

  const getRowClassName = useMemo(() => {
    if (
      entity === "orders" &&
      compatibilitiesData &&
      highlightCompatibilities
    ) {
      return getOrdersRowClass;
    } else {
      return getRowClass;
    }
  }, [
    entity,
    compatibilitiesData,
    highlightCompatibilities,
    getOrdersRowClass,
    getRowClass,
  ]);

  const handleRowSelect = (ids: (number | string)[]) => {
    setSelectionModel(ids);
  };

  const handleColumnOrderChange = useCallback(
    async (orders: string[]) => {
      await updateFieldsOrder(orders);
    },
    [updateFieldsOrder]
  );

  const getFormattedIssuesForLocalValidation = (
    data: Record<string, any>
  ): LocalValidationFormattingResult => {
    let formattedWarnings = [];
    let formattedErrors = [];

    if (data && data.issues && data.id) {
      const unformattedErrors = data.issues.errors[data.id] ?? [];
      const unformattedWarnings = data.issues.warnings[data.id] ?? [];

      if (unformattedErrors.length !== 0) {
        formattedErrors = unformattedErrors.map((error: ValidationError) => {
          return errorReplaceCallback(error);
        });
      }

      if (unformattedWarnings.length !== 0) {
        formattedWarnings = unformattedWarnings.map(
          (warning: ValidationError) => {
            return errorReplaceCallback(warning);
          }
        );
      }
    }

    return {
      formattedErrors,
      formattedWarnings,
    };
  };

  const getFilteredIssuesForLocalValidation = useCallback(
    (ids: string[]): LocalValidationFilterResult => {
      const filteredErrors = errors
        ? errors.filter((error) => {
            return !ids.map(Number).includes(error.id);
          })
        : [];
      const filteredGroupedErrors = omit(groupedErrors, ids.map(String));

      const filteredWarnings = warnings
        ? warnings.filter((warning) => {
            return !ids.map(Number).includes(warning.id);
          })
        : [];

      const filteredGroupedWarnings = omit(groupedWarnings, ids.map(String));

      return {
        filteredErrors,
        filteredGroupedErrors,
        filteredWarnings,
        filteredGroupedWarnings,
      };
    },
    [errors, groupedErrors, groupedWarnings, warnings]
  );

  const handleLocalValidationUpdate = useCallback(
    (data: any) => {
      const { formattedErrors, formattedWarnings } =
        getFormattedIssuesForLocalValidation(data);

      const {
        filteredErrors,
        filteredWarnings,
        filteredGroupedErrors,
        filteredGroupedWarnings,
      } = getFilteredIssuesForLocalValidation([data.id]);

      setErrors([...filteredErrors, ...formattedErrors]);
      setWarnings([...filteredWarnings, ...formattedWarnings]);

      let accessor = String(data.id);
      let updatedGroupedErrors: Record<string, ValidationError[]> =
        filteredGroupedErrors;
      let updatedGroupedWarnings: Record<string, ValidationError[]> = {
        ...filteredGroupedWarnings,
      };

      if (formattedErrors.length !== 0) {
        updatedGroupedErrors[accessor] = formattedErrors;
      }

      if (formattedWarnings.length !== 0) {
        updatedGroupedWarnings[accessor] = formattedWarnings;
      }

      setGroupedErrors(updatedGroupedErrors);
      setGroupedWarnings(updatedGroupedWarnings);
    },
    [
      getFilteredIssuesForLocalValidation,
      setErrors,
      setGroupedErrors,
      setGroupedWarnings,
      setWarnings,
    ]
  );

  useEffect(() => {
    if (deleteResponse && !deleteResponse.loading && deleteResponse.called) {
      if (deleteResponse.error) {
        window.alert(`Failed to delete selected ${entity}`);
      } else {
        const {
          filteredErrors,
          filteredWarnings,
          filteredGroupedErrors,
          filteredGroupedWarnings,
        } = getFilteredIssuesForLocalValidation(deleteResponse.data.ids);
        setErrors(filteredErrors);
        setWarnings(filteredWarnings);
        setGroupedErrors(filteredGroupedErrors);
        setGroupedWarnings(filteredGroupedWarnings);
        setSelectionModel([]);
      }
    }
  }, [
    deleteResponse.data,
    deleteResponse.called,
    deleteResponse.error,
    deleteResponse.loading,
  ]);

  const handleColumnWidthChange = useCallback(
    async ({ colDef, width }) => {
      await updateFieldWidth(colDef.field, width);
    },
    [updateFieldWidth]
  );

  const handleEntityCreate = async (variables: OperationVariables) => {
    const { data } = await createEntity({ variables });

    const createdEntity = Object.values(data)[0];
    await dataQuery.refetch();

    handleLocalValidationUpdate(createdEntity);
  };

  const handleCellUpdate = async (e: any) => {
    const updateParamName = `update${capitalizeFirstLetter(
      entity.substring(0, entity.length - 1)
    )}Input`;

    const value = transformValue(metadata[e.field], e.value);

    const updateData = { [e.field]: value };

    try {
      const { data } = await updateEntity({
        variables: {
          id: e.id,
          projectId: project.id,
          [updateParamName]: updateData,
        },
      });

      const updatedEntity = Object.values(data)[0];

      setTimeout(() => {
        handleLocalValidationUpdate(updatedEntity);
      }, 100);
    } catch (err) {
      console.log(e);
      console.log(project);
      console.log(err);
    }
  };

  const headerColor = useMemo(() => {
    switch (entity) {
      case "trucks":
        return "#1769aa";
      case "orders":
        return "#145a92";
      case "clients":
        return theme.palette.primary.main;
    }
  }, [entity]);

  const rowsFilter = useCallback(
    (data: any) => {
      const errorIds = Object.keys(groupedErrors);

      return errorIds.includes(String(data.id));
    },
    [groupedErrors]
  );

  const customOverlay = dataQuery.noDataFound && (
    <Box>
      No {entity === "trucks" ? "vehicles" : entity} data found.{" "}
      <Link
        style={{
          cursor: "pointer",
        }}
        onClick={() => toggleImportDialog(true)}
      >
        Import
      </Link>{" "}
      or{" "}
      <Link
        style={{
          cursor: "pointer",
        }}
        onClick={() =>
          setEntityDialog({
            entityObject: null,
            entityType: entity,
            open: true,
          })
        }
      >
        Add
      </Link>{" "}
      them first
    </Box>
  );

  return (
    <Box height="100%">
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}

      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSuccessSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSuccessSnackbarOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          No validation errors found!
        </Alert>
      </Snackbar>
      <DataImportDialog
        dialog={importDialog}
        setDialog={setImportDialog}
        importFileHandler={importFile}
        refetchProject={refetchProject}
        validate={validate}
        checkCompatibilities={checkCompatibilities}
      ></DataImportDialog>

      <EntityFormDialog
        createEntityHandler={handleEntityCreate}
        project={project}
        fieldsMetadata={metadata}
        dialog={entityDialog}
        setDialog={setEntityDialog}
      ></EntityFormDialog>

      <ParametersEditDialog
        dialog={parametersEditDialog}
        setDialog={setParametersEditDialog}
        onClose={validate}
      ></ParametersEditDialog>

      <LocationEditDialog
        project={project}
        dialog={locationEditDialog}
        setDialog={setLocationEditDialog}
        entityString={entity}
      ></LocationEditDialog>

      {entity !== "clients" && (
        <PropertyEditDialog
          dialog={propertyEditDialog}
          setDialog={setPropertyEditDialog}
          project={project}
          entityString={entity}
        >
          {" "}
        </PropertyEditDialog>
      )}

      <Paper
        variant="outlined"
        sx={{
          p: 2,
          mb: 3,
          height: issuesTableContent.length > 0 ? "80%" : "100%",
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Box display="flex">
            <Box mr={8}>
              <Box display={"flex"} flexDirection={"row"}>
                {issues !== null && issues.length > 0 && (
                  <>
                    <Box mb={1} mr={2}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={includeValidRows}
                            onChange={(e) =>
                              setIncludeValidRows(e.target.checked)
                            }
                            name="includeValidRows"
                            color="primary"
                          />
                        }
                        label="Valid"
                      />
                    </Box>
                    <Box mb={1} mr={2}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={includeRowsWithErrors}
                            onChange={(e) =>
                              setIncludeRowsWithErrors(e.target.checked)
                            }
                            name="includeRowsWithErrors"
                            color="primary"
                          />
                        }
                        label="With errors"
                      />
                    </Box>
                    <Box mb={1}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={includeRowsWithWarnings}
                            onChange={(e) =>
                              setIncludeRowsWithWarnings(e.target.checked)
                            }
                            name="includeRowsWithWarnings"
                            color="primary"
                          />
                        }
                        label="With warnings"
                      />
                    </Box>
                  </>
                )}
              </Box>
            </Box>

            {entity === "orders" && !isEmpty(compatibilitiesData) && (
              <Box>
                <Box mb={1}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={highlightCompatibilities}
                        onChange={(e) => resetCompatibilitiesFilter(e)}
                        name="highlightCompatibilities"
                        color="primary"
                      />
                    }
                    label="Highlight compatibilities"
                  />
                </Box>
              </Box>
            )}

            {issues !== null &&
              issues.length > 0 &&
              includeRowsWithErrors &&
              includeRowsWithWarnings &&
              traversalCoords && (
                <Box pl={5} style={{ transform: "translate(0, -8px)" }}>
                  <Typography mb={"4px"}>Errors navigation:</Typography>
                  <Box display="flex">
                    <TraverseIssuesButtons
                      errors={issues}
                      currentErrorId={traversalCoords.row}
                      setTraversalCoordinates={setTraversalCoords}
                    />
                  </Box>
                </Box>
              )}
          </Box>

          {!dataQuery.loading && (
            <Box>
              <IconLegend />
            </Box>
          )}
        </Box>

        {issues !== null && issues.length > 0 && <Divider sx={{ mb: 1 }} />}

        {entity === "orders" && (
          <Box height={35}>
            {highlightCompatibilities && (
              <CompatibilitiesLegend
                setCompatibilityBounds={setFilteredBounds}
              ></CompatibilitiesLegend>
            )}
          </Box>
        )}

        <CompatibilitiesDetailsDialog
          dialog={compatibilitiesDetailsDialog}
          setDialog={setCompatibilitiesDetailsDialog}
        ></CompatibilitiesDetailsDialog>

        {!project.isPlanned && !project.isPlanning && (
          <Box display="flex">
            {selectionModel.length > 0 && (
              <Box my={1}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={async () => {
                    if (
                      window.confirm(
                        `Do you want to delete ${selectionModel.length} ${entity}?`
                      )
                    ) {
                      await deleteEntities({
                        variables: {
                          projectId: project.id,
                          ids: selectionModel,
                        },
                      });
                    }
                  }}
                >
                  Delete selected entries
                </Button>
              </Box>
            )}
          </Box>
        )}

        <InputEntityDataTable
          loading={dataQuery.loading}
          data={dataQuery.data}
          fieldsMetadata={metadata}
          errors={groupedErrors}
          warnings={groupedWarnings}
          selectedErrorRowId={selectedErrorRowId}
          rowClickHandler={handleRowClick}
          selectionModel={selectionModel}
          rowSelectHandler={handleRowSelect}
          onCellUpdate={handleCellUpdate}
          getRowClassName={getRowClassName}
          setPropertyEditDialog={setPropertyEditDialog}
          setParametersEditDialog={setParametersEditDialog}
          setLocationEditDialog={setLocationEditDialog}
          locations={locations}
          project={project}
          traversalCoords={traversalCoords}
          customOverlayElement={customOverlay}
          onColumnOrderChange={handleColumnOrderChange}
          onColumnWidthChange={handleColumnWidthChange}
          headerColor={headerColor}
          style={{
            height: `calc(100% - ${
              (entity === "orders" ? 96 : 61) +
              (selectionModel.length > 0 ? 53 : 0)
            }px)`,
          }}
        ></InputEntityDataTable>
      </Paper>

      {issuesTableContent.length > 0 && (
        <Paper variant="outlined" sx={{ p: 2 }}>
          <Typography sx={{ mb: 2 }} variant="h5" fontWeight="bold">
            Validation issues
          </Typography>

          <ValidationErrorsTable
            errors={issuesTableContent}
            onErrorClick={handleErrorClick}
            entity={entity}
          ></ValidationErrorsTable>
        </Paper>
      )}
    </Box>
  );
};

export default EntityDataManager;
