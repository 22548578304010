import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { keyBy, mapValues } from "lodash";
import { DateTime } from "luxon";
import { EntityString, ParameterError } from "../../inputData/types";
import { Project } from "../../types";
import {
  useParametersMetadataQuery,
  useProjectParametersQuery,
} from "../hooks";
import useParameterErrorsStyle from "../../inputData/hooks/useParameterErrorsStyle";
import { replaceParamErrors } from "../../inputData/utilities/errorsTransformer";
import { UPDATE_PARAMETERS } from "../api/mutations";
import { ParameterInputField } from ".";
import { Box, Grid } from "@mui/material";
import { useEntityManagerContext } from "../../inputData/context/EntityManagerContext";
import { BootstrapDialogTitle } from "../../../common/components/BootstrapDialogTitle";
import BatchSelect from "../../outputData/components/BatchSelect";
import ParamsBatchSelect from "./ParamsBatchSelect";
import { BatchType } from "../../outputData/types";
import { usePrevious } from "../../../common/utils";
import { useProjectBatchesQuery } from "../../outputData/hooks";

interface Props {
  dialog: {
    open: boolean;
    edited: Project | null;
    entity?: EntityString;
  };
  setDialog: Dispatch<
    SetStateAction<{
      open: boolean;
      edited: Project | null;
      entity?: EntityString;
    }>
  >;
  onClose: () => any;
}

const ParametersEditDialog: React.FC<Props> = ({
  dialog,
  setDialog,
  onClose,
}) => {
  const { register, handleSubmit, setValue, control, reset } = useForm();

  const { data: batches } = useProjectBatchesQuery(
    dialog?.edited?.id,
    false,
    true
  );
  const [selectedBatch, setSelectedBatch] = useState<BatchType | null>(null);

  useEffect(() => {
    if (batches.length !== 0 && !selectedBatch) {
      setSelectedBatch(batches[0]);
    }
  }, [batches, selectedBatch === null]);

  const previousBatch = usePrevious(selectedBatch);

  const { entity, focusedParameterState, entityParameterKeyMapping } =
    useEntityManagerContext();

  let focusedEntity: EntityString | undefined = dialog.entity;

  const [focusedParameters] = focusedParameterState;

  if (focusedParameters) {
    Object.entries(entityParameterKeyMapping).map(([k, v]) => {
      if (v.includes(focusedParameters[0])) {
        focusedEntity = k as EntityString;
      }
    });
  }

  const parametersMetadata = useParametersMetadataQuery(focusedEntity);

  const [updateParams] = useMutation(UPDATE_PARAMETERS);

  const { data, refetch } = useProjectParametersQuery(
    dialog.edited,
    parametersMetadata,
    selectedBatch?.id,
    previousBatch?.id
  );

  const [errors, setErrors] = useState<ParameterError[]>([]);

  const classes = useParameterErrorsStyle();

  const groupedErrors = useMemo(() => {
    return mapValues(keyBy(errors, "key"), (item: any) => item);
  }, [errors]);

  const projectParameters = data;

  useEffect(() => {
    const refetchParams = async () => {
      await refetch();
    };

    reset();
    if (dialog.open) {
      if (parametersMetadata.length !== 0 && dialog.edited?.id) {
        refetchParams();
      }
    }
  }, [dialog.edited, dialog.open, parametersMetadata.length, refetch, reset]);

  const formFields = useMemo(() => {
    if (!parametersMetadata || !projectParameters) {
      return null;
    }

    return parametersMetadata.map((parameter) => {
      let value = projectParameters[parameter.key];

      if (!value) return null;

      const error = groupedErrors[parameter.key] || "";
      const includesModifiedKey =
        focusedParameters &&
        (focusedParameters.includes(parameter.key + "1D") ||
          focusedParameters.includes(parameter.key + "2D"));
      const shouldDisplay =
        !focusedParameters ||
        (focusedParameters &&
          (focusedParameters.includes(parameter.key) ||
            (parameter.twoDrivers && includesModifiedKey)));

      if (shouldDisplay) {
        if (parameter.twoDrivers) {
          const oneDriverError = groupedErrors[parameter.key + "1D"] || {};
          const twoDriversError = groupedErrors[parameter.key + "2D"] || {};

          return (
            <React.Fragment key={parameter.key}>
              <Grid key={parameter.key + "1D"} item xs={12} sm={6}>
                <ParameterInputField
                  key={parameter.key + "2D"}
                  parameterMetadata={parameter}
                  control={control}
                  register={register}
                  error={oneDriverError.message}
                  value={value.oneDriver}
                  focusedParameterState={focusedParameterState}
                ></ParameterInputField>
              </Grid>

              <Grid key={parameter.key + "2D"} item xs={12} sm={6}>
                <ParameterInputField
                  key={parameter.key + "2D"}
                  secondDriver={true}
                  parameterMetadata={parameter}
                  control={control}
                  register={register}
                  error={twoDriversError.message}
                  value={value.twoDriver}
                  focusedParameterState={focusedParameterState}
                ></ParameterInputField>
              </Grid>
            </React.Fragment>
          );
        }
        return (
          <Grid key={parameter.key + "1D"} item sm={12}>
            <ParameterInputField
              parameterMetadata={parameter}
              control={control}
              register={register}
              error={error.message}
              value={value}
              focusedParameterState={focusedParameterState}
            ></ParameterInputField>
          </Grid>
        );
      }

      return null;
    });
  }, [
    parametersMetadata,
    projectParameters,
    groupedErrors,
    focusedParameters,
    control,
    register,
    focusedParameterState,
  ]);

  const getParametersDialogText = (entity: EntityString): string => {
    switch (entity) {
      case "orders":
        return "Here you can edit order validation parameter values, the values of these parameters depend on company policy";
      case "trucks":
        return "Here you can edit vehicle validation parameter values, the values of these parameters are taken from EU regulation (EC) No 561/2006";
      case "clients":
        return "Here you can edit client validation parameter values, the values of these parameters depend on company policy";
    }
  };

  const reapplyFormValues = useCallback(() => {
    parametersMetadata.forEach((parameterMeta) => {
      if (parameterMeta.twoDrivers) {
        setValue(
          parameterMeta.key + ".oneDriver",
          projectParameters[parameterMeta.key].oneDriver
        );
        setValue(
          parameterMeta.key + ".twoDriver",
          projectParameters[parameterMeta.key].twoDriver
        );
      } else {
        setValue(parameterMeta.key, projectParameters[parameterMeta.key]);
      }
    });
  }, [parametersMetadata, projectParameters, setValue]);

  const onSubmit = async (data: any) => {
    const paramValues = Object.entries(data).map(([key, value]) => {
      const paramValue = DateTime.isDateTime(value) ? value.toSeconds() : value;

      return {
        key,
        value: paramValue,
      };
    });

    const response = await updateParams({
      variables: {
        updateParamsInput: {
          projectId: dialog.edited?.id,
          paramValues,
        },
      },
    });

    const validationErrors: ParameterError[] =
      response.data.updateProjectParams;
    const replacedErrors: ParameterError[] =
      replaceParamErrors(validationErrors);

    if (validationErrors.length === 0) {
      alert("Successfuly updated project parameters");
      await refetch({
        fetchParamsInput: {
          projectId: dialog.edited ? dialog.edited.id : null,
          paramKeys: parametersMetadata?.map((parameter) => parameter.key),
        },
      });
      handleClose();
      reapplyFormValues();
    }

    setErrors(replacedErrors);
  };

  useEffect(() => {
    if (selectedBatch && projectParameters && parametersMetadata && batches) {
      reapplyFormValues();
    }
  }, [
    selectedBatch,
    projectParameters,
    parametersMetadata,
    batches,
    reapplyFormValues,
  ]);

  const handleClose = () => {
    setDialog({
      edited: null,
      open: false,
    });
    if (batches) {
      setSelectedBatch(batches[0]);
    }
    onClose();
  };

  return (
    <div>
      <Dialog
        open={dialog.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth={"lg"}
      >
        <BootstrapDialogTitle id="form-dialog-title" onClose={handleClose}>
          Edit parameters
        </BootstrapDialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText style={{ marginBottom: 20 }}>
              {getParametersDialogText(entity)}
            </DialogContentText>
            {dialog.edited && batches && (
              <Box style={{ marginBottom: 50 }}>
                <ParamsBatchSelect
                  project={dialog.edited}
                  selectedBatchState={[selectedBatch, setSelectedBatch]}
                  batches={batches}
                />
              </Box>
            )}
            <Grid container spacing={3} className={classes.errors}>
              {formFields}
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button type="submit" color="primary">
              Update
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default ParametersEditDialog;
