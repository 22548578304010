import { useContext, createContext } from "react";


export interface EntityFormContextProps {
  propsData: any,
}

export const EntityFormContext = createContext<EntityFormContextProps | null>(null);

export const useEntityFormContext = () => {
  return useContext(EntityFormContext) as EntityFormContextProps
}
export const EntityFormProvider: React.FC<EntityFormContextProps> = ({
  children,
  ...props
}) => {
  return (
    <EntityFormContext.Provider value={props}>
      {children}
    </EntityFormContext.Provider>
  );
};
