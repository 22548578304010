import { useMutation, useQuery } from "@apollo/client";
import { keyBy } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { FieldMetadata } from "../../types";
import {
  GET_FIELD_DISPLAY_CONFIGS,
  SET_NEW_FIELDS_ORDER,
  SET_NEW_FIELD_WIDTH,
} from "../api/queries/fieldDisplayConfigs";
import { EntityField, EntityString, FieldDisplayConfig } from "../types";

export type AdditionalFieldsMetadata = Record<string, Partial<EntityField>>;

export interface FieldDisplayConfigData {
  additionalColumnsInfo: AdditionalFieldsMetadata;
  updateFieldWidth: (field: string, width: number) => Promise<void>;
  updateFieldsOrder: (fieldsOrder: string[]) => Promise<void>;
}

export default function useFieldDisplayConfigs(
  projectId: string,
  entityType: EntityString
): FieldDisplayConfigData {
  const { data } = useQuery(GET_FIELD_DISPLAY_CONFIGS, {
    variables: {
      projectId,
    },
  });

  const [setNewFieldWidth] = useMutation(SET_NEW_FIELD_WIDTH, {
    ignoreResults: true,
  });
  const [setNewFieldsOrder] = useMutation(SET_NEW_FIELDS_ORDER, {
    ignoreResults: true,
  });

  const updateFieldWidth = useCallback(
    async (field: string, width: number) => {
      await setNewFieldWidth({
        variables: {
          projectId,
          entityType: entityType.slice(0, -1),
          field,
          width,
        },
      });
    },
    [entityType, projectId, setNewFieldWidth]
  );

  const updateFieldsOrder = useCallback(
    async (fieldsOrder: string[]) => {
      await setNewFieldsOrder({
        variables: {
          projectId,
          entityType: entityType.slice(0, -1),
          fieldsOrder,
        },
      });
    },
    [entityType, projectId, setNewFieldsOrder]
  );

  const fieldDisplayConfigs: FieldDisplayConfig[] = useMemo(() => {
    return data ? data.fieldDisplayConfigs : [];
  }, [data]);

  const additionalColumnsInfo: AdditionalFieldsMetadata = useMemo(() => {
    return fieldDisplayConfigs
      .filter((config) => config.entityType === entityType.slice(0, -1)) // HACKKKK
      .reduce((obj, curr) => {
        const fieldKey = curr.field;

        return {
          ...obj,
          [fieldKey]: {
            size: curr.width,
            order: curr.order,
          },
        };
      }, {});
  }, [entityType, fieldDisplayConfigs]);

  return {
    additionalColumnsInfo,
    updateFieldWidth,
    updateFieldsOrder,
  };
}
