import { gql } from '@apollo/client';

export const GET_ORDER_FIELDS_METADATA = gql`
  query {
    ordersMetadata
  }
`;

export const FETCH_FROM_OUTPUT_RECORDS =  gql`
 query($batchId: String!, $projectId: String!, $status: String!) {
    fetchFromOutputRecords(batchId: $batchId, projectId: $projectId, status: $status) {
      id
      orderId
      clientId
      loadingPointId
      unloadingPointId
      loadingPointLocation {
        id
        latitude
        longitude
        country
        city
        zipCode
      }
      unloadingPointLocation {
        id
        latitude
        longitude
        country
        city
        zipCode
      }
      orderLoadingTimeWindowStart
      orderLoadingTimeWindowEnd
      orderLoadingDuration
      orderUnloadingTimeWindowStart
      orderUnloadingTimeWindowEnd
      orderUnloadingDuration
      allowableLoadingDelay
      allowableUnloadingDelay
      fineForLoadingDelay
      fineForUnloadingDelay
      freight
      drivingDuration
      routeCost
      fineForNoCargo
      nonExpeditedCargo
      requirements
    }
  }
`
export const GET_ORDERS = gql`
  query($projectId: String!, $entryIds: [Int!]) {
    orders(projectId: $projectId, entryIds: $entryIds) {
      id
      orderId
      clientId
      loadingPointId
      unloadingPointId
      loadingPointLocation {
        id
        latitude
        longitude
        country
        city
        zipCode
      }
      unloadingPointLocation {
        id
        latitude
        longitude
        country
        city
        zipCode
      }
      orderLoadingTimeWindowStart
      orderLoadingTimeWindowEnd
      orderLoadingDuration
      orderUnloadingTimeWindowStart
      orderUnloadingTimeWindowEnd
      orderUnloadingDuration
      allowableLoadingDelay
      allowableUnloadingDelay
      fineForLoadingDelay
      fineForUnloadingDelay
      freight
      drivingDuration
      routeCost
      fineForNoCargo
      nonExpeditedCargo
      requirements
    }
  }
`;

export const VALIDATE_ORDERS = gql`
  query($projectId: String!, $validationOptions: ValidationOptionsInput, $refresh: Boolean!) {
    validateOrders(projectId: $projectId, validationOptions: $validationOptions, refresh: $refresh)
  }
`;

export const FETCH_ORDER_REQUIREMENTS = gql`
     query($projectId: String!) {
    fetchOrderRequirementOptions(projectId: $projectId)
  }
`
