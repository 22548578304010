import { useCurrentProjectQuery } from "../../hooks";
import usePlanKpiMetadataQuery from "../hooks/usePlanKpiMetadataQuery";
import usePlanKpiDataQuery from "../hooks/usePlanKpiDataQuery";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Tooltip,
  Paper,
} from "@mui/material";
import {
  KpiCategory,
  KpiMetaType,
  KpiType,
  KpiTypeScalar,
  PlanPercentageValueType,
} from "../types";
import { Duration } from "luxon";
import { makeStyles } from "@mui/styles";
import {
  Euro as MoneyIcon,
  LocalShipping as TruckIcon,
  OpenInFull as DistanceIcon,
  Schedule as TimeIcon
} from "@mui/icons-material";

import { ReactNode } from "react";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    marginRight: 5,
    textAlign: "center",
  },
  kpiKey: {
    marginRight: "5px",
    fontSize: "0.8em",
    // textAlign: "center",
  },
  kpiValue: {
    fontWeight: "bold",
    marginRight: "5px",
    fontSize: "0.8em",
    // textAlign: "center",
  },
  content: {},
  kpiRow: {
    marginRight: "8px",
    paddingLeft: "7px",
    paddingRight: "7px",
    paddingTop: "10px",
    paddingBottom: "10px",
    textAlign: 'center'
  },
  kpiIconHelper: {

  }
});
interface PlanKpiBarProps {
  projectId: string;
  batchId: string;
}

const PlanKpiBar: React.FC<PlanKpiBarProps> = ({ projectId, batchId }) => {
  const classes = useStyles();

  const { data: kpiMetaMap } = usePlanKpiMetadataQuery();
  const kpiData: Record<string, KpiType> = usePlanKpiDataQuery(
    projectId,
    batchId
  ).data;

  const categoryIconMap: Record<KpiCategory, ReactNode> = {
    "Cost/Profit": <MoneyIcon fontSize="medium" />,
    Distance: <Box paddingTop='12px' >
    <DistanceIcon fontSize="medium" />
    <Box textAlign='center' fontSize='0.7em' marginLeft='-4px'>
      km
    </Box>
  </Box>,
    Load: <TruckIcon fontSize="medium" />,
    Time: <Box paddingTop='12px' >
      <TimeIcon fontSize="medium" />
      <Box textAlign='center' fontSize='0.7em' marginLeft='-4px'>
        hh:mm
      </Box>
    </Box>,
  };

  const prepareCardContent = (
    kpiData: Record<string, KpiType>,
    kpiMeta: KpiMetaType,
    kpiKey: string
  ) => {
    return (
      <Tooltip
        title={`${kpiMeta.label} - ${kpiMeta.description.toLowerCase()}`}
      >
        <Box className={classes.kpiRow}>
          <Typography className={classes.kpiKey}>
            {kpiMeta.shortLabel}
          </Typography>
          <Typography className={classes.kpiValue}>
            {kpiData[kpiKey]
              ? formatKpiValue(kpiData[kpiKey].value, kpiMeta.type)
              : ""}
          </Typography>
        </Box>
      </Tooltip>
    );
  };

  const formatKpiValue = (
    value: string | PlanPercentageValueType,
    type: KpiTypeScalar
  ) => {
    switch (type) {
      case "distance": {
        return `${parseInt(value as string).toLocaleString()}`;
      }
      case "money": {
        return `${parseInt(value as string).toLocaleString()}`;
      }
      case "duration": {
        return Duration.fromObject({
          minutes: parseInt(value as string),
        }).toFormat("hh:mm");
      }
      case "plan percentage": {
        return `${(value as PlanPercentageValueType).plannedEntries}/${
          (value as PlanPercentageValueType).totalEntries
        }`;
      }
    }
  };

  return (
    <Paper
      variant="outlined"
      sx={{ mb: "4px", backgroundColor: "rgb(223 232 255)"}}
    >
      <Box
        style={{
          display: "flex",
        }}
      >
        {Object.keys(kpiData).length !== 0 &&
          Object.entries(kpiMetaMap).map(([category, kpis], i) => {
            return (
              <Box
                key={i}
                display="flex"
                borderRight={i < 3 ? "1px solid #cecece" : "none"}
              >
                <Box
                  style={{
                    display: "flex",
                    width: '100%',
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Box px={2}>{categoryIconMap[category as KpiCategory]}</Box>

                  {Object.entries(kpis).map(([kpiKey, kpiMeta]) =>
                    prepareCardContent(kpiData, kpiMeta, kpiKey)
                  )}
                </Box>
              </Box>
            );
          })}
      </Box>
    </Paper>
  );
};

export default PlanKpiBar;
