import { MutationTuple, useMutation } from '@apollo/client';
import { Project } from '../../types';
import { IMPORT_FILES } from '../api/mutations';
import { dataQueriesMap } from '../api/queries';
import { EntityString } from '../types';

const useImportFilesMutation = (
  project: Project,
  entity: EntityString
): MutationTuple<any, { projectId: string }> => {
  const refetchQuery = dataQueriesMap[entity];

  const mutationData = useMutation(IMPORT_FILES, {
    variables: {
      projectId: project.id
    },
    refetchQueries: [
      {
        query: refetchQuery,
        variables: {
          projectId: project.id
        }
      }
    ]
  });

  return mutationData;
};

export default useImportFilesMutation;
