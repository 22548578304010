import { gql } from '@apollo/client';

export const UPDATE_CLIENT = gql`
  mutation($id: Int!, $updateClientInput: UpdateClientInput!) {
    updateClient(id: $id, updateClientInput: $updateClientInput) {
      id
      clientId
      mustTransported
      cargoCount
      issues
    }
  }
`;

export const CREATE_CLIENT = gql`
  mutation($createClientInput: CreateClientInput!) {
    createClient(createClientInput: $createClientInput) {
      id
      clientId
      mustTransported
      cargoCount
      issues
    }
  }
`;

export const DELETE_CLIENTS = gql`
    mutation($projectId: String!, $ids: [Int!]!){
        removeClients(projectId: $projectId, ids: $ids){
        ids
        count
        }
    }`;

