import { useQuery } from "@apollo/client";
import {GET_KPI_METADATA} from "../api/queries";
import { KpiCategory, KpiMetaType } from "../types";

export default function usePlanKpiMetadataQuery() {
    const query = useQuery(GET_KPI_METADATA)

    const data = ((query.data && query.data.planKpiMetadata) ? query.data.planKpiMetadata : {}) as Record<KpiCategory, Record<string,KpiMetaType>>

  
    return { ...query, data}
}