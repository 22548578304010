import { gql } from '@apollo/client';

export const CREATE_PROJECT = gql`
  mutation CreateProject($createProjectInput: CreateProjectInput!) {
    createProject(createProjectInput: $createProjectInput) {
      id
      name
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation UpdateProject(
    $id: String!
    $updateProjectInput: UpdateProjectInput!
  ) {
    updateProject(id: $id, updateProjectInput: $updateProjectInput) {
      id
      name
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation DeleteProject($id: String!) {
    removeProject(id: $id) {
      id
    }
  }
`;

export const TOGGLE_PROJECT_ARCHIVED_STATE = gql`
  mutation ToggleArchived($id: String!){
      toggleProjectArchivedState(id: $id){
        id,
        name,
        archivedAt
      }
}
`;

export const DUPLICATE_PROJECT = gql`
  mutation DuplicateProject($id: String!, $name: String!){
      duplicateProject(id: $id, name: $name) {
        id
      }
}
`;
