import { isArray } from "lodash";
import {ReactNodeArray, useEffect, useRef} from "react";
import {Entity} from "../../projects/inputData/types";
import {FieldMetadata} from "../../projects/types";
import {DateTime} from "luxon";

export { default as handleDownload } from "./handleDownload";

export const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const mergeCustomizer = (objValue: any, srcValue: any) => {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }
}

export const formatDatetimeValues = (entityData: Entity[], metadata: Record<string,FieldMetadata>, forSecondaryPlanResults = false) => {
  return entityData.map((item) => {
    let newData = { ...item };
    for (let key in newData) {
      if (
          metadata.hasOwnProperty(key) &&
          metadata[key].type === "dateTime"
      ) {
        if((newData as any)[key] === '0' ){
          (newData as any)[key] = null
        }
        else {
          (newData as any)[key] = DateTime.fromISO((newData as any)[key]);
        }
      }
    }

    return newData;
  });
}

export const getFieldLabel = (meta: FieldMetadata): string => {
  return (meta.fullLabel ? meta.fullLabel : meta.shortLabel) as string;
}
