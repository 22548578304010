import {
  Box,
  Button,
  Tab,
  Tabs,
  Typography,
  CircularProgress,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { DateTime } from "luxon";
import { useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  ValidateDataButton,
  ValidationErrorsTable,
} from "../../inputData/components";
import useEntityManagerValidation from "../../inputData/hooks/useEntityManagerValidation";
import { ParameterInputField } from "../../parameters/components";
import {
  useParametersMetadataQuery,
  useProjectParametersQuery,
} from "../../parameters/hooks";
import { Project } from "../../types";
import hash from "object-hash";
import { useMutation } from "@apollo/client";
import { PLAN_PROJECT } from "../api/mutations";
import ErrorredEntriesDialog from "./ErroredEntriesDialog";
import { useCurrentProjectQuery } from "../../hooks";
interface PlanningFormProps {
  project: Project;
  onPlanningStarted?: (batch: any) => Promise<void>;
}

export function PlanningForm({
  project,
  onPlanningStarted,
}: PlanningFormProps) {
  const [isPlanning, setIsPlanning] = useState(project.isPlanning);
  const [planningBatch, setPlanningBatch] = useState(null);
  const { refetch: refetchProject } = useCurrentProjectQuery();

  const projectParametersMetadata = useParametersMetadataQuery("projects");
  const planningParametersMetadata = useParametersMetadataQuery("planning");

  const { register, handleSubmit, control, getValues, watch } = useForm();

  const ordersValidator = useEntityManagerValidation(project, "orders");
  const trucksValidator = useEntityManagerValidation(project, "trucks");

  const [erroredEntriesDialog, setErroredEntriesDialog] = useState({
    isOpen: false,
  });

  const [currentFormHash, setCurrentFormHash] = useState<string>("");
  const [validatedFormHash, setValidatedFormHash] = useState<string>("");
  const [planTimeSet, setPlanTimeSet] = useState(false);

  const isValidated = useMemo(() => {
    return (
      currentFormHash === validatedFormHash &&
      ordersValidator.errors !== null &&
      trucksValidator.errors !== null
    );
  }, [
    currentFormHash,
    validatedFormHash,
    ordersValidator.errors,
    trucksValidator.errors,
  ]);

  const parametersMetadata = useMemo(() => {
    if (isValidated) {
      return [...projectParametersMetadata, ...planningParametersMetadata];
    } else {
      return projectParametersMetadata;
    }
  }, [isValidated, projectParametersMetadata, planningParametersMetadata]);

  const { data: projectParameters } = useProjectParametersQuery(
    project,
    parametersMetadata
  );

  const [planProject] = useMutation(PLAN_PROJECT);

  const isValidating = useMemo(() => {
    return ordersValidator.isValidating || trucksValidator.isValidating;
  }, [ordersValidator.isValidating, trucksValidator.isValidating]);

  const [selectedTab, setSelectedTab] = useState("1");

  useEffect(() => {
    setTimeout(() => {
      const { planTime, ...formObject } = getValues(); // TODO do not hardcode planning params

      const hashString = hash(formObject);
      setCurrentFormHash(hashString);
      setPlanTimeSet(!!planTime)
    }, 2000);
  }, [getValues]);

  useEffect(() => {
    const subscription = watch((value) => {
      const { planTime, ...formObject } = value;
      setCurrentFormHash(hash(formObject));
      setPlanTimeSet(!!planTime)  
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (
      isValidating === false &&
      ordersValidator.errors !== null &&
      trucksValidator.errors !== null
    ) {
      const { planTime, ...formObject } = getValues();

      const hashString = hash(formObject);
      setValidatedFormHash(hashString);
    }
  }, [getValues, isValidating, ordersValidator.errors, trucksValidator.errors]);

  const formFields = useMemo(() => {
    if (!parametersMetadata || !projectParameters) {
      return null;
    }

    return parametersMetadata.map((parameter) => {
      let value = projectParameters[parameter.key];

      return (
        <ParameterInputField
          key={parameter.key}
          parameterMetadata={parameter}
          control={control}
          register={register}
          value={value}
        ></ParameterInputField>
      );
    });
  }, [parametersMetadata, projectParameters, control, register]);

  const mapParameters = (parameters: any) => {
    return Object.entries(parameters).map(([key, value]) => {
      const paramValue = DateTime.isDateTime(value) ? value.toSeconds() : value;

      return {
        key,
        value: paramValue,
      };
    });
  };
  const validateData = (parameters: any) => {
    const paramValues = mapParameters(parameters);

    const validationParams = {
      variables: {
        projectId: project.id,
        refresh: true,
        validationOptions: {
          customParameters: paramValues,
          ignoreWarnings: true,
          includeTotals: true,
        },
      },
    };

    ordersValidator.validate(validationParams);
    trucksValidator.validate(validationParams);
  };

  const onProjectPlan = async (parameters: any) => {
    if (
      !window.confirm(
        "Are you sure you want to queue this project for planning?"
      )
    )
      return;

    const {
      data: { planProject: batch },
    } = await planProject({
      variables: {
        planProjectInput: {
          projectId: project.id,
          planningParameters: mapParameters(parameters),
        },
      },
    });

    if (onPlanningStarted) {
      await onPlanningStarted(batch);
    }

    setIsPlanning(true);
    setPlanningBatch(batch as any);
    refetchProject();
  };

  const onSubmit = (parameters: any) => {
    if (isValidated) {
      onProjectPlan(parameters);
    } else {
      validateData(parameters);
    }
  };

  const openErroredEntriesModal = () => {
    setErroredEntriesDialog({
      isOpen: true,
    });
  };

  const handleErroredEntriesDialogClose = () => {
    setErroredEntriesDialog({
      isOpen: false,
    });
  };

  if (isPlanning) {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <CircularProgress size={100} value={15} />
        <Typography sx={{ pt: 5 }}>Project is currently planning</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <ErrorredEntriesDialog
        isOpen={erroredEntriesDialog.isOpen}
        entity="orders"
        entryIds={ordersValidator.erroredEntries}
        project={project}
        onClose={handleErroredEntriesDialogClose}
      ></ErrorredEntriesDialog>
      <form onSubmit={handleSubmit(onSubmit)}>
        {formFields}

        <Box sx={{ pt: 2 }}>
          {isValidated ? (
            <Button disabled={!planTimeSet} variant="contained" type="submit" color="primary">
              Plan project
            </Button>
          ) : (
            <Button
              disabled={isValidating}
              variant="contained"
              type="submit"
              color="primary"
            >
              {!isValidating ? "Validate data" : "Validating..."}
            </Button>
          )}

          {isValidated &&
            ordersValidator.errors !== null &&
            trucksValidator.errors !== null && (
              <Box sx={{ width: "100%", typography: "body1", pt: 4 }}>
                <TabContext value={selectedTab}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={(e, newVal) => setSelectedTab(newVal)}
                      aria-label="lab API tabs example"
                    >
                      <Tab label="Order errors" value="1" />
                      <Tab label="Vehicle errors" value="2" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    {ordersValidator?.errors.length !== 0 ? (
                      <Box>
                        <h3>
                          {" "}
                          <span
                            onClick={openErroredEntriesModal}
                            style={{ color: "red", cursor: "pointer" }}
                          >
                            {ordersValidator.erroredEntriesCount}
                          </span>{" "}
                          {" orders out of " +
                            ordersValidator.totalEntries +
                            " cannot be planned"}
                        </h3>

                        <ValidationErrorsTable
                          isCompact
                          entity="orders"
                          errors={ordersValidator.errors}
                          onErrorClick={() => {}}
                        ></ValidationErrorsTable>
                      </Box>
                    ) : (
                      <Box>
                        <h3>No vehicle errors found</h3>
                      </Box>
                    )}
                  </TabPanel>
                  <TabPanel value="2">
                    {trucksValidator?.errors.length !== 0 ? (
                      <Box>
                        <h3>
                          {" "}
                          <span style={{ color: "red" }}>
                            {trucksValidator.erroredEntriesCount}
                          </span>{" "}
                          {" Vehicles out of " +
                            trucksValidator.totalEntries +
                            " cannot be used for planning"}
                        </h3>
                        <ValidationErrorsTable
                          isCompact
                          entity="trucks"
                          errors={trucksValidator.errors}
                          onErrorClick={() => {}}
                        ></ValidationErrorsTable>
                      </Box>
                    ) : (
                      <Box>
                        <h3>No vehicle errors found</h3>
                      </Box>
                    )}
                  </TabPanel>
                </TabContext>
              </Box>
            )}
        </Box>
      </form>
    </Box>
  );
}
