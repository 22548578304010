import {useMutation} from "@apollo/client";
import {createMutationsMap} from "../api/mutations";
import { EntityString } from "../types";

export function useCreateEntityMutation(entity: EntityString) {
    const mutation = createMutationsMap[entity];
    const entityCacheType = entity.charAt(0).toUpperCase() + entity.slice(1,-1)
    const dataKey = `create${entityCacheType}`

    const [mutateFunction, mutationResult]  = useMutation(mutation)

    return {
        createEntity: mutateFunction,
        createResponse: {
            ...mutationResult,
            data: mutationResult.data ? mutationResult.data[dataKey] : {},
        }
    }
}