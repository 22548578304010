import { Reference, useMutation, useQuery } from "@apollo/client";
import {
  DataGrid,
  GridCellParams,
  GridColumns,
  GridRowModel,
} from "@mui/x-data-grid";
import {
  Button,
  Box,
  IconButton,
  Theme,
  useTheme,
  TableContainer,
  Paper,
  Tooltip,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Visibility as ShowIcon,
  Archive as ArchiveIcon,
} from "@mui/icons-material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { User } from "../types";
import { UserFormDialog } from ".";
import { GET_USERS } from "../api/queries";
import { DELETE_USER } from "../api/mutations";
import { Navigate, useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { useAuth } from "../../auth/hooks";
import { SocketContext } from "../../context/SocketContext";
import { makeStyles } from "@mui/styles";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

type StyleProps = {
  theme: Theme;
};

const useStyles = makeStyles({
  root: {
    width: "100%",
    "& .MuiDataGrid-row.Mui-odd": {
      backgroundColor: "#ebebf1",
    },
    "& .MuiDataGrid-row:hover": {
      backgroundColor: "#d4d4d4",
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: (props: StyleProps) => props.theme.palette.primary.main,
      color: "white",
    },
  },
});

interface ActionButton {
  clickHandler: (row: GridRowModel) => void;
  icon: JSX.Element;
  name: string;
  tooltipLabel: string;
}

interface ActionButtonsProps {
  actions: ActionButton[];
  row: GridRowModel;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ actions, row }) => {
  const buttons = actions.map(({ clickHandler, icon, tooltipLabel }, idx) => {
    return (
      <Tooltip key={`${row.id}-${idx}`} title={tooltipLabel}>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            clickHandler(row);
          }}
        >
          {icon}
        </IconButton>
      </Tooltip>
    );
  });
  return <div>{buttons}</div>;
};

const UsersList: React.FC<Props> = () => {
  const navigate = useNavigate();
  const { data, refetch } = useQuery<{ users: User[] }>(GET_USERS);
  const { user, loading } = useAuth();

  const theme = useTheme();
  const classes = useStyles({ theme });

  const [userDialog, setUserDialog] = useState<{
    open: boolean;
    edited: User | null;
  }>({
    open: false,
    edited: null,
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  const [deleteUser] = useMutation(DELETE_USER, {
    update(cache, { data: { removeUser } }) {
      cache.modify({
        fields: {
          users(existingUserRefs: Reference[] = [], { readField }) {
            const users = existingUserRefs.filter(
              (userRef) => removeUser.id !== readField("id", userRef)
            );
            return users;
          },
        },
      });
    },
  });

  const actions: ActionButton[] = [
    {
      name: "edit",
      icon: <EditIcon />,
      clickHandler: (row) => {
        const edited = row as User;
        console.log(row);
        setUserDialog({
          open: true,
          edited: edited,
        });
      },
      tooltipLabel: "Edit user",
    },
    {
      name: "delete",
      icon: <DeleteIcon />,
      clickHandler: async (row) => {
        const message = "Are you sure you want to delete this user?";
        if (window.confirm(message)) {
          await deleteUser({
            variables: {
              id: row.id,
            },
          });
        }
        await refetch();
      },
      tooltipLabel: "Delete user",
    },
  ];

  const columns: GridColumns = [
    { field: "name", headerName: "Name", width: 250 },
    { field: "email", headerName: "Email", width: 250 },
    {
      field: "activeUntil",
      headerName: "Active until",
      width: 250,
      valueFormatter: ({ value }) =>
        value
          ? DateTime.fromISO(value as any).toLocaleString(DateTime.DATETIME_MED)
          : "",
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 250,
      renderCell: ({ row }: GridCellParams) => {
        const buttons = (
          <ActionButtons actions={actions} row={row}></ActionButtons>
        );

        return buttons;
      },
    },
  ];

  return (
    <Paper variant="outlined" sx={{ p: 2, height: "100%" }}>
      {(!loading && user) || loading ? (
        <>
          <UserFormDialog
            dialog={userDialog}
            setDialog={setUserDialog}
          ></UserFormDialog>

          <Box marginBottom={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                setUserDialog({
                  open: true,
                  edited: null,
                })
              }
            >
              Create new
            </Button>
          </Box>

          <TableContainer style={{ height: "750px", width: "100%" }}>
            <DataGrid
              autoHeight
              density="compact"
              className={classes.root}
              rows={data?.users || []}
              columns={columns}
              pageSize={5}
            />
          </TableContainer>
        </>
      ) : (
        <Navigate to="/login" />
      )}
    </Paper>
  );
};

export default UsersList;
