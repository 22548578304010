import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { useForm } from "react-hook-form";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {
  Autocomplete,
  AutocompleteChangeReason,
  Chip,
  TextField,
} from "@mui/material";

import { Project } from "../../types";
import React, { useCallback, useEffect, useMemo } from "react";
import { InputEntityDataTable } from "../../inputData/components";
import useEntityMetadata from "../../inputData/hooks/useEntityMetadata";
import { EntityString } from "../../inputData/types";
import { useInputDataQuery } from "../../inputData/hooks";
import useLocations from "../../inputData/hooks/useLocations";
import { BootstrapDialogTitle } from "../../../common/components/BootstrapDialogTitle";

interface Props {
  onClose: () => void;
  entryIds: number[] | null;
  isOpen: boolean;
  project: Project;
  entity: EntityString;
}

const ErrorredEntriesDialog: React.FC<Props> = ({
  isOpen,
  entryIds,
  onClose,
  project,
  entity,
}) => {
  const metadata = useEntityMetadata(entity, {});

  const locations = useLocations();
  const dataQuery = useInputDataQuery(
    project,
    entity,
    {}, // TODO ADD GROUPED ERRORS,
    {},
    true,
    true,
    true,
    undefined,
    entryIds || undefined
  );

  const handleClose = async () => {
    await dataQuery.refetch();
    onClose();
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        // sx={{height: '600px'}}
      >
        <BootstrapDialogTitle id="form-dialog-title" onClose={handleClose}>
          View errored entries
        </BootstrapDialogTitle>

        <DialogContent>
          <InputEntityDataTable
            loading={dataQuery.loading}
            data={dataQuery.data}
            fieldsMetadata={metadata}
            errors={{}}
            warnings={{}}
            onCellUpdate={() => {}}
            setParametersEditDialog={() => {}}
            setLocationEditDialog={() => {}}
            locations={locations}
            project={project}
            readOnly
            style={{ height: "600px" }}
          ></InputEntityDataTable>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ErrorredEntriesDialog;
