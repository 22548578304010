import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import { FieldType, Project } from "../../types";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Autocomplete,
  TextField,
  Chip,
  Box,
  Button,
  useTheme,
  Theme,
} from "@mui/material";
import { useProjectBatchesQuery } from "../hooks";
import { BatchType } from "../types";
import usePlanKpiMetadataQuery from "../hooks/usePlanKpiMetadataQuery";
import {
  DataGridPro,
  GridColDef,
  GridColumns,
  GridRowId,
} from "@mui/x-data-grid-pro";
import { useQuery } from "@apollo/client";
import { GET_KPI_FOR_COMPARE } from "../api/queries";
import { formatCell } from "../../inputData/utilities/cellFormatter";
import { sorter } from "../../inputData/hooks/useColumnsData";
import { BootstrapDialogTitle } from "../../../common/components/BootstrapDialogTitle";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";

interface Props {
  onClose: () => void;
  isOpen: boolean;
  project: Project;
  selectedBatch: BatchType | null;
  setSelectedBatch: Dispatch<SetStateAction<BatchType | null>>;
}

type StyleProps = {
  theme: Theme;
};

const useStyles = makeStyles({
  root: {
    width: "100%",
    "& .MuiDataGrid-row.Mui-odd": {
      backgroundColor: "#ebebf1",
    },
    "& .MuiDataGrid-row:hover": {
      backgroundColor: "#d4d4d4",
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: (props: StyleProps) => props.theme.palette.primary.main,
      color: "white",
    },
  },
});

const CompareKpisDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  project,
  selectedBatch,
  setSelectedBatch,
}) => {
  const handleClose = () => {
    onClose();
  };

  const [selectionModel, setSelectionModel] = useState<string | undefined>(
    selectedBatch?.id
  );

  const { data: kpiBatches } = useProjectBatchesQuery(project.id, true);

  const [selectedVersions, setSelectedVersions] = useState([]);

  const handleBatchSelect = () => {
    const batch = kpiBatches.find((item) => item.id === selectionModel);

    if (batch) {
      if (selectionModel !== selectedBatch?.id) {
        setSelectedBatch(batch);
      }
      handleClose();
    }
  };

  const selectedVersionIds = useMemo(() => {
    return selectedVersions.map((version: any) => version.id);
  }, [selectedVersions]);

  const { data, previousData } = useQuery(GET_KPI_FOR_COMPARE, {
    variables: {
      batchIds: selectedVersionIds,
      projectId: project.id,
    },
  });

  const theme = useTheme();
  const classes = useStyles({ theme });

  const handleAutocompleteChange = useCallback((_event: Object, value: any) => {
    setSelectedVersions(value);
  }, []);

  const planPercentageSorter = (a: any, b: any, params1: any, params2: any) => {
    const usedEntitiesFraction1 = a.plannedEntries / a.totalEntries;
    const usedEntitiesFraction2 = b.plannedEntries / b.totalEntries;

    return usedEntitiesFraction1 - usedEntitiesFraction2;
  };

  const { data: kpiMetaMap } = usePlanKpiMetadataQuery();

  const kpiAttributes = useMemo(() => {
    return Object.entries(
      Object.values(kpiMetaMap).reduce((prev, curr) => {
        return { ...prev, ...curr };
      }, {})
    );
  }, [kpiMetaMap]);

  let columnsData: GridColumns = kpiAttributes
    .sort((a, b) => {
      const aVal = a[1];
      const bVal = b[1];
      return aVal.order - bVal.order;
    })
    .map(([key, attr]) => ({
      field: key,
      headerName: attr.label,
      width: 250,
      sortable: true,
      sortComparator:
        attr.type === "plan percentage" ? planPercentageSorter : sorter,
      valueFormatter: ({ value: val }) => {
        if (val && val.hasOwnProperty("plannedEntries")) {
          return `${(val as any).plannedEntries}/${(val as any).totalEntries}`;
        }
        if (attr.type === "distance") {
          return `${parseInt(val as string).toLocaleString()} km`;
        }
        if (attr.type === "money") {
          return `${parseInt(val as string).toLocaleString()} €`;
        } else {
          return formatCell(attr.type as FieldType, val);
        }
      },
    }));

  const versionNameColumn: GridColDef = {
    field: "batchName",
    headerName: "Version name",
    width: 250,
  };

  columnsData = [versionNameColumn, ...columnsData];

  const displayData = useMemo(() => {
    if (data && data.getKpiForCompare) {
      return data.getKpiForCompare;
    } else if (previousData && previousData.getKpiForCompare) {
      return previousData.getKpiForCompare;
    }

    return [];
  }, [data, previousData]);

  return (
    <div>
      <Dialog
        fullWidth
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
      >
        <BootstrapDialogTitle id="form-dialog-title" onClose={handleClose}>
          Compare KPIS of different versions
        </BootstrapDialogTitle>

        <DialogContent>
          <Autocomplete
            multiple
            id="tags-filled"
            options={kpiBatches}
            freeSolo
            getOptionLabel={(option: BatchType) => option.name}
            renderTags={(value: BatchType[], getTagProps) =>
              value.map((option: BatchType, index: number) => (
                <Chip
                  variant="outlined"
                  label={option.name}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} variant="filled" label="Versions" />
            )}
            onChange={handleAutocompleteChange}
            value={selectedVersions}
            defaultValue={[]}
          />
          <Box mb={2} mt={2}>
            <Button variant="contained" onClick={handleBatchSelect}>
              Use selected version
            </Button>
          </Box>

          {kpiAttributes && (
            <Box height={800} width="100%" mb={4} pt={2}>
              <DataGridPro
                className={classes.root}
                autoPageSize={true}
                density="compact"
                rows={displayData}
                getRowId={(row) => row.batchId}
                columns={columnsData}
                pageSize={5}
                selectionModel={selectionModel}
                onRowClick={(params) => setSelectionModel(params.row.batchId)}
              />
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CompareKpisDialog;
