import { QueryResult, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GET_LOCATIONS } from "../api/queries/locations";
import { Location, LocationMap } from "../types";

const useLocations = () => {
    const query = useQuery(GET_LOCATIONS);

    const locationsState = useState<LocationMap>({})

    const setLocations = (locations: LocationMap) => {
        const setAction = locationsState[1]

        return setAction((prevLocations) => {
            let mergedLocations: LocationMap = {...prevLocations}
            Object.entries(locations).forEach(([k,v]) => {
              mergedLocations[k] = v
            })
            
            return mergedLocations;
          })

    }

    useEffect(() => {
        if (query.data) {
            setLocations(query.data['locations'])
        }
    }, [query.data])

    return {
        data: locationsState[0],
        setLocations,
        loading: query.loading
    };
};

export default useLocations;