import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation CreateUser($createUserInput: CreateUserInput!) {
    createUser(createUserInput: $createUserInput) {
      id
      name
      activeUntil
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: String!
    $updateUserInput: UpdateUserInput!
  ) {
    updateUser(id: $id, updateUserInput: $updateUserInput) {
      id
      name
      activeUntil
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: String!) {
    removeUser(id: $id) {
      id
    }
  }
`;

export const TOGGLE_USER_ARCHIVED_STATE = gql`
  mutation ToggleArchived($id: String!){
      toggleUserArchivedState(id: $id){
        id,
        name,
        archivedAt
      }
}
`;

export const DUPLICATE_USER = gql`
  mutation DuplicateUser($id: String!, $name: String!){
      duplicateUser(id: $id, name: $name) {
        id
      }
}
`;
