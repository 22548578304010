import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { timeout, usePrevious } from "../common/utils";
import {Action} from "history";
import {ActionButtonProps} from "../common/components/ActionButton";

export const TopbarActionsContext = createContext<any | null>(null);

const TopbarActionsContextProvider: React.FC = ({ children }) => {
  const [actions, setActions] = useState<ReactNode | null>([]);
  const location = useLocation();

  useEffect(() => {
    setActions(null);
  }, [location.pathname]);

  const setTopbarActionsAsync = useCallback(async (newActions) => { 
    await timeout(100)
    return setActions(newActions)
  }, [])

  return (
    <TopbarActionsContext.Provider value={[actions, setTopbarActionsAsync]}>
      {children}
    </TopbarActionsContext.Provider>
  );
};

export const useTopbarActions = () =>
  useContext(TopbarActionsContext) as [
        ReactNode | null,
    Dispatch<SetStateAction<ReactNode | null>>
  ];

export const useTopbarActionsSetter = () => {
    return useTopbarActions()[1]
}

export default TopbarActionsContextProvider;
