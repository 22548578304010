import React, { useState } from "react";
import { EntityDataManager } from ".";
import { useCurrentProjectQuery } from "../../hooks";
import { EntityManagerProvider } from "../context/EntityManagerContext";
import {EntityString} from "../types";
import useLocations from "../hooks/useLocations";
import useEntityParameterKeyMapQuery from "../../parameters/hooks/useEntityParameterKeyMapQuery";

interface Props {
  entity: EntityString;
}

const EntityManagerView: React.FC<Props> = ({ entity }) => {

  const {data: currentProject, refetch: refetchProject} = useCurrentProjectQuery();

  const entityParameterKeyMap = useEntityParameterKeyMapQuery();

  const locations = useLocations();

  const [selectedTab, setSelectedTab] = useState(0);

  const focusedParameterState = useState<string[] | null>(null)

  return (
    <EntityManagerProvider refetchProject = {refetchProject} project={currentProject} entity={entity} focusedParameterState={focusedParameterState} selectedTabState={[selectedTab, setSelectedTab]} locations={locations} entityParameterKeyMapping={entityParameterKeyMap}>
      <EntityDataManager />
    </EntityManagerProvider>
  );
};

export default EntityManagerView;
