import {
  Entity,
  EntityString,
  LocationEditDialogType,
  SetLocationEditDialogType,
  SetPropertyEditDialogType,
} from "../types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { useForm } from "react-hook-form";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {
  Autocomplete,
  AutocompleteChangeReason,
  Box,
  Chip,
  TextField,
} from "@mui/material";
import { usePropertyOptionsQuery } from "../hooks";
import { useUpdateEntityMutation } from "../hooks/useUpdateEntityMutation";
import { OrderType } from "../types/orderType";
import { TruckType } from "../types/truckType";
import { Project } from "../../types";
import { useEffect, useMemo } from "react";
import EntityInputField from "./EntityInputField";
import { generateInputString } from "../../../utilities/helpers";
import {BootstrapDialogTitle} from "../../../common/components/BootstrapDialogTitle";

interface Props {
  dialog: LocationEditDialogType;
  setDialog: SetLocationEditDialogType;
  project: Project;
  entityString: EntityString;
}

const LocationEditDialog: React.FC<Props> = ({
  dialog,
  setDialog,
  project,
  entityString,
}) => {
  const [updateEntity] = useUpdateEntityMutation(entityString);

  const { handleSubmit, register, reset } = useForm();

  useEffect(() => {
    if (dialog.open) {
      reset();
    }
  }, [dialog.open, reset]);

  const formFields = useMemo(() => {
    const latitudeVal = dialog.entityObject
      ? (dialog.entityObject as any)[dialog.field as string].latitude
      : null;
    const longitudeVal = dialog.entityObject
      ? (dialog.entityObject as any)[dialog.field as string].longitude
      : null;

    return (
      <Box>
        <TextField
          fullWidth
          margin="dense"
          label="Latitude"
          name={"latitude"}
          inputProps={{
            ...register("latitude"),
          }}
          defaultValue={latitudeVal}
        ></TextField>

        <TextField
          fullWidth
          margin="dense"
          label="Longitude"
          name={"longitude"}
          inputProps={{
            ...register("longitude"),
          }}
          defaultValue={longitudeVal}
        ></TextField>
      </Box>
    );
  }, [dialog.entityObject, dialog.field, register]);

  const onSubmit = async (data: any) => {
    try {
      const inputName = generateInputString(entityString, "update");

      const response = await updateEntity({
        variables: {
          id: (dialog.entityObject as Entity).id,
          [inputName]: {
            [dialog.field as string]: data,
          },
        },
      });
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  const handleClose = () => {
    setDialog({
      entityObject: null,
      field: null,
      open: false,
    });
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={dialog.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <BootstrapDialogTitle id="form-dialog-title" onClose={handleClose}>Edit location</BootstrapDialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>{formFields}</DialogContent>

          <DialogActions>
            <Button type="submit" color="primary">
              Update
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default LocationEditDialog;
