import { GridRowParams } from "@mui/x-data-grid-pro";
import { findKey } from "lodash";
import { useCallback } from "react";
import {ErrorMap} from "../types";
import clsx from "clsx";

export function useRowClassCallback(
    errors: ErrorMap,
    warnings: ErrorMap
) {
    return useCallback(
        (row: GridRowParams) => {
            const hasErr = errors && errors[row.id] && errors[row.id].length > 0;
            const hasWarn = warnings && warnings[row.id] && warnings[row.id].length > 0;
            const rowClass = hasErr ? 'row-danger' : (hasWarn ? 'row-warning' : '')


            return clsx(`no-hover-effect ${rowClass}`)

        },

        [errors, warnings]
    );
}