import {generateInputString} from "../../../utilities/helpers";

export interface LabelObject {
    fieldLabel: string;
    valueKey: string;
    updateInputKey: string;
}

export type PropifiedEntityType = 'orders' | 'trucks'

export const propertyLabelMap: Record<PropifiedEntityType, LabelObject> = {
    orders: {
        fieldLabel: 'Requirements',
        valueKey: 'requirements',
        updateInputKey: generateInputString('orders', "update")
    },
    trucks:{
        fieldLabel: 'Properties',
        valueKey: 'properties',
        updateInputKey: generateInputString('trucks', "update")
    }
}