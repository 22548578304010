import { Theme, useTheme } from "@mui/material";
import { ValidationError } from "../types";
import { makeStyles } from "@mui/styles";

type StyleProps = {
  theme: Theme;
  errors: Record<string, ValidationError[]>;
  warnings: Record<string, ValidationError[]>;
  headerColor: string;
};

const useStyles = makeStyles({
  root: {
    "& .compatibility-bounds-0": {
      // TODO: Make abstraction
      backgroundColor: "#263238",
      color: "white",
      "&:hover": {
        backgroundColor: "#455A64",
      },
      "& .MuiChip-outlined": {
        color: "white",
        borderColor: "white",
      },
    },
    "& .compatibility-bounds-0-10": {
      backgroundColor: "#455A64",
      color: "white",
      "&:hover": {
        backgroundColor: "#546E7A",
      },

      "& .MuiChip-outlined": {
        color: "white",
        borderColor: "white",
      },
    },
    "& .compatibility-bounds-10-20": {
      backgroundColor: "#546E7A",
      color: "white",
      "&:hover": {
        backgroundColor: "#78909C",
      },
      "& .MuiChip-outlined": {
        color: "white",
        borderColor: "white",
      },
    },
    "& .compatibility-bounds-20-30": {
      backgroundColor: "#78909C",
      color: "white",
      "&:hover": {
        backgroundColor: "#B0BEC5",
      },
      "& .MuiChip-outlined": {
        color: "white",
        borderColor: "white",
      },
    },
    "& .compatibility-bounds-30-40": {
      backgroundColor: "#B0BEC5",
      "&:hover": {
        backgroundColor: "#CFD8DC",
      },
      "& .MuiChip-outlined": {
        color: "rgb(51, 51, 51)",
        borderColor: "rgb(102, 102, 102)",
      },
    },
    "& .compatibility-bounds-40-50": {
      backgroundColor: "#CFD8DC",
      "&:hover": {
        backgroundColor: "#ECEFF1",
      },
      "& .MuiChip-outlined": {
        color: "rgb(51, 51, 51)",
        borderColor: "rgb(102, 102, 102)",
      },
    },
    "& .compatibility-bounds-50-100": {
      backgroundColor: "#ECEFF1",
      "&:hover": {
        backgroundColor: "#FFFFFF",
      },
    },

    "& .compatibility-has-warning > div": {
      "&:first-child": {
        backgroundColor: "rgba(255,255,104, 0.6)",
        color: "black",
      },
    },

    "& .row-danger": {
      backgroundColor: "rgba(236,95,95,0.6)",
      color: "rgb(78, 56, 56)",
      "&:hover": {
        backgroundColor: "rgba(236,95,95,0.6)",
      },

      "& .MuiDataGrid-cell--editing": {
        backgroundColor: "white",

        "& .MuiInputBase-input": {
          color: "black",
          padding: '0 10px',
        },

        "& .MuiInputBase-input::-webkit-calendar-picker-indicator": {
          textAlign: "left",
          marginRight: "60px",
        },
      },

      "& .MuiChip-outlined": {
        color: "rgb(78, 56, 56)",
        borderColor: "rgb(102, 75, 75)",
      },
    },
    "& .row-warning": {
      backgroundColor: "rgba(255,255,104,0.6)",
      "&:hover": {
        backgroundColor: "rgba(255,255,104,0.6)",
      },
    },

    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: (props: StyleProps) => props.headerColor,
      color: "white",
      "& .Mui-checked": {
        color: "white !important",
      },
      "& .MuiDataGrid-columnHeaderCheckbox": {
        backgroundColor: (props: StyleProps) =>
          props.theme.palette.primary.main,
      },
      "& .truckGeneral": {
        backgroundColor: "#2196f3",
      },
      "& .truckLastTask": {
        backgroundColor: "#1e87db",
      },
      "& .truckDrivingWorking": {
        backgroundColor: "#1a78c2",
      },
      "& .truckRest": {
        backgroundColor: "#1769aa",
      },
      "& .orderGeneral": {
        backgroundColor: "#2196f3",
      },
      "& .orderLocations": {
        backgroundColor: "#1e87db",
      },
      "& .orderLoadUnload": {
        backgroundColor: "#1a78c2",
      },
      "& .orderDelays": {
        backgroundColor: "#1769aa",
      },
      "& .orderDriving": {
        backgroundColor: "#145a92",
      },
      "& .issuesTable": {
        backgroundColor: "rgba(236,95,95,0.9)",
      },
    },
    "& input[type='text'].MuiInputBase-input": {
      paddingLeft: "9px",
      paddingBottom: "1px",
      // fontSize: "0.8em",
      // lineHeight: 1.35,
    },
    "& input[type='datetime-local'].MuiInputBase-input": {
      paddingLeft: "9px",
      fontSize: "0.9em",
      lineHeight: 1.35,
    },

    "& .MuiDataGrid-cell:focus-within": {
      outline: (props: StyleProps) =>
        `solid ${props.theme.palette.primary.main} 2px`,
    },
    "& .cell.warning": {
      backgroundColor: "rgba(255,255,104,0.9)",
      color: "black",
    },
    "& .cell.danger": {
      backgroundColor: "rgba(236,95,95,0.5)",
      color: "white",
    },
    "& .cell.warning span, & .cell.danger span": {
      width: "100%",
      fontSize: "1.1em",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  tooltip: {
    fontSize: "1.3em",

    "& .parameter-label": {
      color: "#fdff8a",
    },

    "& .parameter-value": {
      color: "#c7daff",
    },

    "& .field-label": {
      color: "#98f5b2",
    },

    "& .field-value": {
      color: "#c7daff",
    },
  },
  errorMessage: {
    "&": {
      lineHeight: "normal",
      whiteSpace: "normal",
      // fontWeight: 'bolder'
    },
    "& .parameter-label": {
      color: "#64653a",
      fontWeight: "bolder",
      fontStyle: "italic",
    },

    "& .parameter-value": {
      color: "#444436",
      fontWeight: "bolder",
    },

    "& .field-label": {
      color: "#007922",
      fontWeight: "bolder",
      fontStyle: "italic",
    },

    "& .field-value": {
      color: "#145427",
      fontWeight: "bolder",
    },
  },
});

export const useEntityDataTableStyles = (
  errors: Record<string, ValidationError[]>,
  warnings: Record<string, ValidationError[]>,
  headerColor: string
) => {
  const theme = useTheme();

  const classes = useStyles({
    theme,
    errors,
    warnings,
    headerColor,
  });

  return classes;
};
