import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { SEND_PASSWORD_REMINDER } from "../api/mutations";
import { BootstrapDialogTitle } from "../../common/components/BootstrapDialogTitle";

interface Props {
  dialog: boolean;
  setDialog: Dispatch<SetStateAction<boolean>>;
}

const ProjectFormDialog: React.FC<Props> = ({ dialog, setDialog }) => {
  const { register, handleSubmit } = useForm();

  const handleClose = () => {
    setDialog(false);
  };

  const [sendPasswordReminder] = useMutation(SEND_PASSWORD_REMINDER, {
    errorPolicy: "all",
  });
  const onSubmit = (data: { email: string }): void => {
    sendPasswordReminder({
      variables: {
        email: data.email,
      },
    })
      .then((res) => {
        if (!res.errors) {
          alert(
            `Password was sent to ${data.email}, check your email for further information`
          );
        } else {
          alert(res.errors[0].message);
        }
      })
      .finally(() => {
        handleClose();
      });
  };

  return (
    <div>
      <Dialog
        open={dialog}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <BootstrapDialogTitle id="form-dialog-title" onClose={handleClose}>
          Send password reminder
        </BootstrapDialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <TextField
              inputProps={{ ...register("email") }}
              autoFocus
              margin="dense"
              id="email"
              label="Email Address"
              autoComplete="email"
              fullWidth
            />
          </DialogContent>

          <DialogActions>
            <Button type="submit" color="primary">
              Send reminder
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default ProjectFormDialog;
