import { InputBase } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";
import React, { useCallback } from "react";
import { Clear as ClearIcon } from "@mui/icons-material";

export default function DateTimeEditCell(
  props: GridRenderCellParams & { clearable: boolean }
) {
  const { id, value, api, field, clearable } = props;
  let dateValue;

  if (value === null) {
    dateValue = DateTime.now()
      .setZone("gmt")
      .startOf("minute")
      .toISO({ includeOffset: false });
  } else {
    dateValue = (value as DateTime)
      .setZone("gmt")
      .toISO({ includeOffset: false });
  }

  const handleChange = useCallback(
    (event) => {
      const newValue = DateTime.fromISO(event.target.value, {
        zone: "gmt",
      }).setZone("gmt");

      api.setEditCellValue({ id, field, value: newValue }, event);
    },
    [api, field, id]
  );

  const handleClear = useCallback(
    async (event) => {
      console.log("Clearing");
      api.setEditCellValue({ id, field, value: null }, event);
      await api.commitCellChange({
        field,
        id,
      });
      api.setCellMode(id, field, "view");
    },
    [api, field, id]
  );

  return (
    <>
      <InputBase
        margin={"none"}
        autoFocus
        className="MuiDataGrid-editCellInputBase"
        fullWidth
        type="datetime-local"
        value={dateValue}
        onChange={handleChange}
      />
      {clearable && (
        <ClearIcon
          style={{ color: "black", width: "20px", height: "20px" }}
          onClick={handleClear}
        />
      )}
    </>
  );
}
