import { gql } from "@apollo/client";

export const CHECK_COMPATIBILITIES = gql`
  query ($projectId: String!) {
    fetchCompatibilityData(projectId: $projectId)
  }
`;

export const FETCH_COMPATIBLE_TRUCKS = gql`
  query ($projectId: String!, $orderId: Int!) {
    fetchCompatibleTrucks(projectId: $projectId, orderId: $orderId) {
      truckId
      carId
      truckProps
      amId
    }
  }
`;
