import {IssueTraversalCoordinates, NumberedValidationError, ValidationError} from '../types';
import {  Box, Button } from '@mui/material';
import {makeStyles} from "@mui/styles";

interface TraverseIssuesButtonsProps {
  errors: ValidationError[];
  currentErrorId: number | null;
  setTraversalCoordinates: React.Dispatch<React.SetStateAction<IssueTraversalCoordinates|undefined>>;
}

const useStyles = makeStyles({
  navigationButton: {
    height: 30
  },
});

const TraverseIssuesButtons: React.FC<TraverseIssuesButtonsProps> = ({
  errors,
  currentErrorId,
  setTraversalCoordinates
}) => {

  const classes = useStyles();

  const numberedIssues: NumberedValidationError[] = errors.flat().map((error, index) => {
    let { id,...errorData} = error
    return {
      num: index+1,
      entityId: id,
      ...errorData
    }
  })

  const findCurrentError = () => {
    const currentError = numberedIssues.find((error) => error.entityId === currentErrorId)
    if(!currentError){
      setTraversalCoordinates({row: numberedIssues[0].entityId, column: numberedIssues[0].field})
    }
    return currentError;
  }

  const prevErrorHandler = () => {
    const currentError = findCurrentError()

    if(currentError){
      const previousError = numberedIssues.find((error,index) =>  {
        return numberedIssues[index+1]?.entityId === currentErrorId && numberedIssues[index+1].entityId !== numberedIssues[0].entityId
      })
      if(previousError)
      {
        setTraversalCoordinates({row: previousError.entityId, column: previousError.field})
      }
      else{
        setTraversalCoordinates({row: numberedIssues[numberedIssues.length - 1].entityId, column: numberedIssues[numberedIssues.length - 1].field})
      }

    }
  }

  const nextErrorHandler = () => {
    const currentError = findCurrentError()

    if(currentError){
      const nextError = numberedIssues.find((error) => error.entityId > currentError.entityId)
      if(nextError) {
        console.log(nextError)
        setTraversalCoordinates({row: nextError.entityId, column: nextError.field})
      }
      else{
        setTraversalCoordinates({row: numberedIssues[0].entityId, column: numberedIssues[0].field})
      }
    }
  }

  const clearSelectedErrorHandler = () => {
    setTraversalCoordinates(undefined)
  }

  return (
    <>
    <Box mr={1}>
    <Button
        className={classes.navigationButton}
      variant="contained"
      color="primary"
      onClick={() => {
        prevErrorHandler();
      }}>
      Previous
    </Button>
    </Box>
  <Box mr={1}>
    <Button
        className={classes.navigationButton}
      variant="contained"
      color="primary"
      onClick={() => {
        nextErrorHandler();
      }}>
      Next
    </Button>
  </Box>
      <Box>
        <Button
            className={classes.navigationButton}
            variant="contained"
            color="primary"
            onClick={() => {
              clearSelectedErrorHandler();
            }}>
          Clear
        </Button>
      </Box>
    </>
  )
};

export default TraverseIssuesButtons;