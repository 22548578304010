import { EntityString } from "../projects/inputData/types";

export const capitalizeFirstLetter = (string: string): string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};


export const generateInputString = (entity: EntityString, action: string) => {
    const singularEntityString = entity.slice(0, -1)

    return `${action}${capitalizeFirstLetter(singularEntityString)}Input`
}