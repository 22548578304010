import { gql } from '@apollo/client';

export const UPDATE_TRUCK = gql`
  mutation($id: Int!, $updateTruckInput: UpdateTruckInput!) {
    updateTruck(id: $id, updateTruckInput: $updateTruckInput) {
      id
      carId
      trailerId
      twoDrivers
      driver1Id
      driver2Id
      amTripEnd
      dailyDrivingTime
      nonstopDrivingTime
      extendedDrivingCounter
      weeklyDrivingTime
      fortnightDrivingTime
      lastDailyRestEnd
      lastWeeklyRestEnd
      lastWeeklyRestDuration
      firstReducedWeeklyRest
      firstReducedWeeklyRestCompensation
      secondReducedWeeklyRest
      secondReducedWeeklyRestCompensation
      lastTaskLocation {
        id
        latitude
        longitude
        country
        city
        zipCode
      }
      normalWeekRestRequired
      dailyWorkingTime
      availableStartPlace
      availableEndPlace
      availableStartTime
      properties
      issues
    }
  }
`;


export const CREATE_TRUCK = gql`
  mutation($createTruckInput: CreateTruckInput!) {
    createTruck(createTruckInput: $createTruckInput) {
      id
      carId
      trailerId
      twoDrivers
      driver1Id
      driver2Id
      amTripEnd
      dailyDrivingTime
      nonstopDrivingTime
      extendedDrivingCounter
      weeklyDrivingTime
      fortnightDrivingTime
      lastDailyRestEnd
      lastWeeklyRestEnd
      lastWeeklyRestDuration
      firstReducedWeeklyRest
      firstReducedWeeklyRestCompensation
      secondReducedWeeklyRest
      secondReducedWeeklyRestCompensation
      lastTaskPointId
      lastTaskLocation {
        id
        latitude
        longitude
        country
        city
        zipCode
      }
      lastTaskFinishingTime
      normalWeekRestRequired
      drivingHoursAtFirstWeek
      drivingHoursAtPreviousWeek
      dailyWorkingTime
      availableStartPlace
      availableEndPlace
      availableStartTime
      availableEndTime
      latestTimeStartWeekRest
      latestTimeFinishWeekRest
      properties
      issues
    }
  }
`;

export const DELETE_TRUCKS = gql`
    mutation($projectId: String!, $ids: [Int!]!){
        removeTrucks(projectId: $projectId, ids: $ids){
        ids
        count
        }
    }`;

export const TRUCK_FRAGMENT = gql`
   fragment NewTruck on Truck {
      id
      carId
      trailerId
      twoDrivers
      driver1Id
      driver2Id
      amTripEnd
      dailyDrivingTime
      nonstopDrivingTime
      extendedDrivingCounter
      weeklyDrivingTime
      fortnightDrivingTime
      lastDailyRestEnd
      lastWeeklyRestEnd
      lastWeeklyRestDuration
      firstReducedWeeklyRest
      firstReducedWeeklyRestCompensation
      secondReducedWeeklyRest
      secondReducedWeeklyRestCompensation
      lastTaskLocation {
        id
        latitude
        longitude
        country
        city
        zipCode
      }
      normalWeekRestRequired
      dailyWorkingTime
      availableStartPlace
      availableEndPlace
      availableStartTime
      properties
      }`;


