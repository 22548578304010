import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { GET_PROJECT_BATCHES } from "../api/queries";
import { BatchType } from "../types";

export default function useProjectBatchesQuery(
  projectId?: string,
  forCompare = false,
  forParams = false
) {
  const query = useQuery<{ fetchBatches: BatchType[] }>(GET_PROJECT_BATCHES, {
    variables: { projectId, forCompare },
    skip: !projectId,
  });

  const data = useMemo(() => {
    let data: BatchType[] = Object.assign(
      [],
      query.data && query.data.fetchBatches ? query.data.fetchBatches : []
    );

    if (forParams && data.length !== 0) {
      data = [
        {
          id: "-",
          name: "Latest",
          date: 0,
          isProcessing: false,
          isPlanning: false,
          isSuccess: true
        },
        ...data,
      ];
    }

    return data
  }, [forParams, query.data]);

  return { ...query, data };
}
