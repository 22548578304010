import { DateTime } from "luxon";
import { FieldMetadata } from "../../types";

export const dateToSeconds = (date: string | Date | DateTime) => {
  let dateObj: DateTime | null = null;

  if (DateTime.isDateTime(date)) {
    dateObj = date;
  } else if (date !== null && typeof date === "object") {
    dateObj = DateTime.fromJSDate(date as Date);
  } else if (typeof date === "string") {
    dateObj = DateTime.fromISO(date as string);
  }

  if (!dateObj) return null;

  return dateObj.toSeconds();
};

export const transformValue = (fieldMetadata: FieldMetadata, value: any) => {
  switch (fieldMetadata.type) {
    case "dateTime":
      return dateToSeconds(value);
    case "string":
      return String(value);
    case "boolean": {
      return Boolean(value);
    }
    default:
      return Number(value);
  }
};
