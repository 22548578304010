import { QueryResult, useQuery } from "@apollo/client"
import { useParametersMetadataQuery } from "."
import { EntityString } from "../../inputData/types"
import { Project } from "../../types"
import { GET_PROJECT_PARAMETERS } from "../api/queries"
import { keyBy, mapValues } from 'lodash'
import { useMemo } from "react"
import { ParameterMetadata } from "../types"

type QueryData = QueryResult<any, {
    fetchParamsInput: {
        projectId: string | null;
        paramKeys: string[] | undefined;
    };
}>

export default function useProjectParametersQuery(project: Project | null, parametersMeta?: ParameterMetadata[], batchId?: string, previousBatchId?: string): QueryData {
    let queryBatchId = null

    if(batchId && batchId !== '-'){
        queryBatchId = batchId
    }

    const paramKeys = parametersMeta?.map(parameter => parameter.key)

    const { data, ...rest } = useQuery(GET_PROJECT_PARAMETERS, {
        variables: {
            fetchParamsInput: {
                projectId: project ? project.id : null,
                paramKeys,
                batchId: queryBatchId
            }
        },
        skip: project === null,
        fetchPolicy: "no-cache"
    })
    
    const keyedParameters = useMemo(() => {
        if (!data) 
            return null

        return mapValues(keyBy(data.getSelectedParams, 'key'), (item) => item.value) 
    }, [data]) 

    return { data: keyedParameters, ...rest} 
}