import { GridRowParams } from "@mui/x-data-grid-pro";
import { findKey } from "lodash";
import { useCallback } from "react";
import { ErrorMap } from "../types";

export function useOrderRowClassCallback(
  errors: ErrorMap,
  warnings: ErrorMap,
  highlightCompatibilities: boolean,
  compatibilitiesData: any
) {
  const callback = useCallback(
    (row: GridRowParams) => {
      const boundClasses = {
        "0": [0, 1],
        "0-10": [1, 10],
        "10-20": [10, 20],
        "20-30": [20, 30],
        "30-40": [30, 40],
        "40-50": [40, 50],
        "50-100": [50, 100],
      };

      if (!highlightCompatibilities || !compatibilitiesData) {
        return "";
      }

      const compatibilityData = compatibilitiesData[row.id];

    
      if (!compatibilityData) {
        return `no-hover-effect row-danger`;
      } 

      

      const bounds = findKey(boundClasses, (value) => {
        const [left, right] = value;

        return (
          compatibilityData.percentage >= left &&
          compatibilityData.percentage < right
        );
      });

      let className = `no-hover-effect compatibility-bounds-${bounds}`

      if (warnings[row.id]) {
        className += ' compatibility-has-warning'
      }

      return className;
    },

    [compatibilitiesData, highlightCompatibilities, warnings]
  );

  return callback;
}
