import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Dispatch, SetStateAction, useEffect, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useMutation} from '@apollo/client';
import {Project} from '../types';
import {keyBy, mapValues} from 'lodash'
import {useParametersMetadataQuery, useProjectParametersQuery} from '../parameters/hooks';
import {UPDATE_PARAMETERS} from '../parameters/api/mutations';
import {ParameterInputField} from '../parameters/components';
import {DateTime} from 'luxon';
import {ParameterError} from "../inputData/types";
import useParameterErrorsStyle from "../inputData/hooks/useParameterErrorsStyle";
import { replaceParamErrors } from '../inputData/utilities/errorsTransformer';
import {BootstrapDialogTitle} from "../../common/components/BootstrapDialogTitle";

interface Props {
    dialog: {
        open: boolean;
        edited: Project | null;
    };
    setDialog: Dispatch<
        SetStateAction<{
            open: boolean;
            edited: Project | null;
        }>
    >;
}

const ProjectParametersDialog: React.FC<Props> = ({ dialog, setDialog }) => {
    const { register, handleSubmit, setValue, control, reset } = useForm();

    const parametersMetadata = useParametersMetadataQuery('projects')

    const [updateParams] = useMutation(UPDATE_PARAMETERS)

    const { data, refetch } = useProjectParametersQuery(dialog.edited, parametersMetadata)

    const [errors, setErrors] = useState<ParameterError[]>([])

    const classes = useParameterErrorsStyle()


    const groupedErrors = useMemo(() => {
        return mapValues(keyBy(errors, 'key'), (item: any) => item.message) 
    }, [errors])


    const projectParameters = data

    useEffect(() => {
        const refetchParams = async () => {
            await refetch()
        }

        reset()
        if (dialog.open) {
            refetchParams()
        }
      
    }, [dialog.edited, dialog.open, refetch, reset])

    const formFields = useMemo(() => {
        if (!parametersMetadata || !projectParameters) {
            return null
        }

        return parametersMetadata.map((parameter) => {
            let value = projectParameters[parameter.key]

            return (
                <ParameterInputField
                    key={parameter.key}
                    parameterMetadata={parameter}
                    control={control}
                    register={register}
                    error={groupedErrors[parameter.key]}
                    value={value}></ParameterInputField>

            )
        })
    }, [parametersMetadata, projectParameters, control, register, groupedErrors])

    const onSubmit = async (data: any) => {
        
        const paramValues = Object.entries(data).map(([key, value]) => {

            const paramValue = DateTime.isDateTime(value) ? value.toSeconds() : value
              
            return {
                key,
                value: paramValue
            }
        }) 

        const response = await updateParams({
            variables: {
                updateParamsInput: {
                    projectId: dialog.edited?.id,
                    paramValues
                }
            }
        })


        const validationErrors: ParameterError[] = response.data.updateProjectParams
        const replacedErrors: ParameterError[] = replaceParamErrors(validationErrors);

        if (validationErrors.length === 0) {
            alert('Successfuly updated project parameters')
            await refetch()
            handleClose()
        }

        setErrors(replacedErrors)
    };

    const handleClose = () => {
        setDialog({
            edited: null,
            open: false
        })
    }

    return (
        <div>
            <Dialog
                open={dialog.open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <BootstrapDialogTitle id="form-dialog-title" onClose={handleClose}>
                    {dialog.edited ? 'Edit project' : 'New project'}
                </BootstrapDialogTitle>
                <form onSubmit={handleSubmit(onSubmit)} className={classes.errors}>
                    <DialogContent>
                        <DialogContentText>
                            Here you can set basic parameters of this project.
                        </DialogContentText>

                        {formFields}

                    </DialogContent>

                    <DialogActions>
                        <Button type="submit" color="primary">
                            Update
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};

export default ProjectParametersDialog;
