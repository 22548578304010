import {
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  Box,
  Grid,
  Autocomplete,
  Chip,
} from "@mui/material";

import { Control, Controller, UseFormRegister } from "react-hook-form";
import { FieldMetadata } from "../../types";
import { DateTime } from "luxon";
import { DateTimePicker } from '@mui/lab';
import {
  EntityFormContextProps,
  useEntityFormContext,
} from "../context/EntityFormContext";
import { getFieldLabel } from "../../../common/utils";

type Value = string | number | boolean | string[];

interface Props {
  value: Value;
  register: any;
  field: string;
  fieldMetadata: FieldMetadata;
  control: Control<Record<string, any>>;
}

const getComponent = (
  field: string,
  value: Value,
  fieldMetadata: FieldMetadata,
  register: UseFormRegister<Record<string, any>>,
  control: Control<Record<string, any>>,
  entityFormContext: EntityFormContextProps,
): JSX.Element => {
  switch (fieldMetadata.type) {
    case "money":
    case "duration":
    case "number":
    case "percentage":
      return (
        <TextField
          fullWidth
          type="number"
          margin="dense"
          label={getFieldLabel(fieldMetadata)}
          id={field}
          name={field}
          defaultValue={value}
          inputProps={{
            step: fieldMetadata.type === "percentage" ? "0.01" : "1",
            ...register(field, {
              valueAsNumber: true,
            }),
          }}
        ></TextField>
      );
    case "boolean":
      return (
        <FormControl>
          <FormControlLabel
            control={
              <Controller
                defaultValue={value}
                control={control}
                name={field as any}
                render={({ field }) => (
                  <Checkbox
                    onBlur={field.onBlur}
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                    inputRef={field.ref}
                  />
                )}
              />
            }
            label={getFieldLabel(fieldMetadata)}
          />
        </FormControl>
      );
    case "dateTime":
      const defaultValue = value ? DateTime.fromSeconds(value as number) : null;
      return (
        <Controller
          defaultValue={defaultValue}
          control={control}
          name={field as any}
          render={({ field }) => (
            <DateTimePicker
              label={getFieldLabel(fieldMetadata)}
              renderInput={(props) => (
                <TextField margin="dense" fullWidth {...props} />
              )}
              onChange={(newValue) => {
                field.onChange(newValue);
              }}
              value={field.value}
            />
          )}
        />
      );
    case "location":
      return (
        <Box>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                margin="dense"
                label={`${getFieldLabel(fieldMetadata)} (Lat.)`}
                name={field}
                inputProps={{
                  ...register(`${field}[latitude]`, { valueAsNumber: true }),
                }}
              ></TextField>
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                margin="dense"
                label={`${getFieldLabel(fieldMetadata)} (Lon.)`}
                name={field}
                inputProps={{
                  ...register(`${field}[longitude]`, { valueAsNumber: true }),
                }}
              ></TextField>
            </Grid>
          </Grid>
        </Box>
      );
    case "props": {
      return (
        <Box>
          <Controller
            defaultValue={[]}
            control={control}
            name={field as any}
            render={({ field }) => (
              <Autocomplete
                multiple
                id="tags-filled"
                options={entityFormContext.propsData}
                freeSolo
                renderTags={(value: string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField {...params} variant="filled" label={getFieldLabel(fieldMetadata)} />
                )}
                onChange={(e, val) => {field.onChange(val)}}
                value={field.value}
                defaultValue={[]}
              />
            )}
          />
        </Box>
      );
    }
    default:
      return (
        <TextField
          fullWidth
          margin="dense"
          label={getFieldLabel(fieldMetadata)}
          id={field}
          name={field}
          defaultValue={value}
          inputProps={{ ...register(field) }}
        ></TextField>
      );
  }
};

const EntityInputField = ({
  value,
  register,
  field,
  fieldMetadata,
  control,
}: Props): JSX.Element => {
  const entityFormContext = useEntityFormContext();

  const inputComponent = getComponent(
    field,
    value,
    fieldMetadata,
    register,
    control,
    entityFormContext,
  );

  return inputComponent;
};

export default EntityInputField;
