import { QueryResult, useQuery } from '@apollo/client';
import { queryAllByAltText } from '@testing-library/dom';
import { FieldMetadata, Project } from '../../types';
import { dataQueriesMap, metadataQueryMap } from '../api/queries';
import { Entity, EntityString, ValidationError } from '../types';


const useMetadataQuery = (
  entity: EntityString,
): QueryResult => {
  const query = useQuery(metadataQueryMap[entity]);

  const dataKey = entity + 'Metadata';

  let data: Record<string, FieldMetadata> = query.data ? query.data[dataKey] : {};

  return {
    ...query,
    data: data
  };
};

export default useMetadataQuery;
