import { useQuery } from "@apollo/client"
import { EntityString } from "../../inputData/types"
import { GET_PARAMETERS_METADATA } from "../api/queries"
import { ParameterMetadata } from "../types"

export default function useParametersMetadataQuery(entity?: EntityString | 'projects' | 'planning'): ParameterMetadata[] {
    const { data } = useQuery(GET_PARAMETERS_METADATA, {
        variables: {
            entity
        }
    }) 

    return data ? data.getParamsMeta : []
}