import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import { Copyright } from '../../common/components';
import {LOGIN, RESET_PASSWORD} from '../api/mutations';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useAuth } from '../hooks';
import { useNavigate } from 'react-router-dom';
import {LoginResponse, ResetPasswordInput} from '../types';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Theme } from '@mui/material';


const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

interface ResetPasswordFormProps {
    resetToken: string;
}

const PasswordResetForm: React.FC<ResetPasswordFormProps> = ({resetToken}) => {
    const [resetPassword] = useMutation(RESET_PASSWORD);
    const [login] = useMutation(LOGIN);
    const { register, handleSubmit } = useForm();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const { fetchUser, user, loading } = useAuth();

    useEffect(() => {
        if (!loading && user) {
            navigate('/projects')
        }
    }, [user, loading, navigate])

    const classes = useStyles();

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    const handleMouseDown = (event: any) => {
        event.preventDefault();
    };

    const onSubmit = async ({ email, password, confirmPassword }: ResetPasswordInput): Promise<void> => {
        try {
            await resetPassword({
                variables: {
                    token: resetToken,
                    passwordResetInput: {
                        email,
                        password,
                        confirmPassword
                    }
                }
            })
            try{
            const {data} = await login({
                variables: {
                    loginInput: {
                        email,
                        password,
                        rememberMe: false
                    }
                }
            })
                const {token} = data.login as LoginResponse;

                localStorage.setItem('token', token);
                await fetchUser();}
            catch (e){
                alert('Could not login with provided credentials.')
                console.log(e);
            }
        }
        catch (e){
           
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Reset password
                </Typography>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={classes.form}
                    noValidate
                >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        autoComplete="email"
                        inputProps={{...register('email')}}
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={toggleShowPassword}
                                        onMouseDown={handleMouseDown}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        inputProps={{...register('password')}}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Confirm password"
                        type={showConfirmPassword ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={toggleShowConfirmPassword}
                                        onMouseDown={handleMouseDown}
                                    >
                                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        inputProps={{...register('confirmPassword')}}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Reset password
                    </Button>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
};

export default PasswordResetForm;
