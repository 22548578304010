import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import { Copyright } from "../../common/components";
import { LOGIN } from "../api/mutations";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useAuth } from "../hooks";
import { useNavigate } from "react-router-dom";
import { LoginInput, LoginResponse } from "../types";
import { ForgotPasswordDialog } from "./index";
import Visibility from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

const useStyles = makeStyles((theme: any) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LoginForm = (): React.ReactElement => {
  const [login] = useMutation(LOGIN);
  const [forgotPasswordDialog, setForgotPasswordDialog] = useState(false);
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { fetchUser, user, loading } = useAuth();

  useEffect(() => {
    if (!loading && user) {
      navigate(user.isSuperAdmin ? "/users" : "/projects");
    }
  }, [user, loading, navigate]);

  const classes = useStyles();

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDown = (event: any) => {
    event.preventDefault();
  };

  const onSubmit = async ({ email, password }: LoginInput): Promise<void> => {
    try {
      const { data } = await login({
        variables: {
          loginInput: {
            email,
            password,
            rememberMe,
          },
        },
      });

      const { token } = data.login as LoginResponse;

      localStorage.setItem("token", token);
      await fetchUser();
    } catch (e) {
      alert("Could not login with provided credentials.");
      console.log(e);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <ForgotPasswordDialog
        dialog={forgotPasswordDialog}
        setDialog={setForgotPasswordDialog}
      />

      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={classes.form}
          noValidate
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            autoComplete="email"
            inputProps={{ ...register("email") }}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Password"
            type={showPassword ? "text" : "password"}
            inputProps={{ ...register("password") }}
            autoComplete="current-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={toggleShowPassword}
                    onMouseDown={handleMouseDown}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="rememberMe"
                color="primary"
                checked={rememberMe}
                inputProps={{ ...register("rememberMe") }}
                onClick={() => {
                  setRememberMe(!rememberMe);
                }}
              />
            }
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                href="#"
                variant="body2"
                onClick={() => setForgotPasswordDialog(true)}
              >
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default LoginForm;
