import {
  GridCellParams,
  GridColDef,
  renderEditInputCell,
} from "@mui/x-data-grid-pro";

import { useMemo } from "react";

import clsx from "clsx";

import { formatCell } from "../../inputData/utilities/cellFormatter";
import { FieldMetadata, SizeDef } from "../../types";
import { FieldMetadataMap, OutputRecordType } from "../types";

const baseColumnProperties: Partial<GridColDef> = {
  align: "left",
  headerAlign: "left",
  editable: true,
  headerClassName: "header",
  resizable: true,
};

function renderCellFactory(field: FieldMetadata) {
  return (params: GridCellParams) => {
    let value = params.value;

    const isArray = field.isArray;

    let formattedValue = formatCell(field.type, value, {
      row: params.row as OutputRecordType,
      field: params.field,
      isArray
    });

    return <span>{formattedValue}</span>;
  };
}

const getColumnType = (metadata: FieldMetadata) => {
  if (metadata.type === "dateTime") {
    return "dateTime";
  } else if (metadata.type === "string") {
    return "string";
  }

  return "number";
};

export default function useColumnsData(fieldsMetadata: FieldMetadataMap) { // TODO EXTRACT ERRORS
  const data = useMemo(() => {
    const fieldCount  = Object.keys(fieldsMetadata).length
    return Object.entries(fieldsMetadata)
      .sort(([, a], [, b]) => (a as any).order - (b as any).order)
      .map(
        ([key, metadata], index): GridColDef => ({
          ...baseColumnProperties,
          field: key as string,
          width: fieldCount === index ? undefined : metadata.size,
          headerName: metadata.shortLabel as string,
          headerClassName: metadata.vehicleRelated ? 'vehicle--header' : 'base--header',
          type: getColumnType(metadata),
          description: metadata.description,
          hide: (key as string) === "id",
          editable: false,
          renderCell: renderCellFactory(metadata),
        })
      );
  }, [fieldsMetadata]);

  return data;
}
