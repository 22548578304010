import { QueryTuple, useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { CHECK_COMPATIBILITIES } from "../api/queries/compatibilities";

export const useCompatibilitiesQuery = (
  projectId: string
): QueryTuple<
  any,
  {
    projectId: string;
  }
> => {
  const [checkCompatibilites, queryData] = useLazyQuery(CHECK_COMPATIBILITIES, {
    variables: {
      projectId,
    },
    fetchPolicy: "no-cache",
  });

  const [compatsData, setCompatsData] = useState(null)

  useEffect(() => {
    const newData = queryData?.data?.fetchCompatibilityData
    if (newData){
      setCompatsData(newData);
    }
  }, [queryData.data])
  

  return [
    checkCompatibilites,
    {
      ...queryData,
      data: compatsData as any,
    },
  ];
};
