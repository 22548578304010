import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Dashboard } from "./common/components/Dashboard";
import theme from "./theme";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterLuxon from "@mui/lab/AdapterLuxon";
import SocketContextProvider from "./context/SocketContext";
import TopbarActionsContextProvider from "./context/TopbarActionsContext";
import { io } from "socket.io-client";


LicenseInfo.setLicenseKey(
  "9ca4ad0fa2a399d2b47ee2f47641d5bcT1JERVI6Mzc1MjYsRVhQSVJZPTE2NzYwMTYzMDEwMDAsS0VZVkVSU0lPTj0x"
);

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <SocketContextProvider>
            <Router>
              <TopbarActionsContextProvider>
                <Dashboard></Dashboard>
              </TopbarActionsContextProvider>
            </Router>
          </SocketContextProvider>
        </LocalizationProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}
