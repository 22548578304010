import { HeaderIconMap } from "../types";
import { ReactComponent as LoadingIcon } from "../../storage/icons/header-loading.svg";
import { ReactComponent as UnloadingIcon } from "../../storage/icons/header-unloading.svg";
import { ReactComponent as WorkingIcon } from "../../storage/icons/header-working.svg";
import { ReactComponent as DrivingIcon } from "../../storage/icons/header-driving.svg";
import { ReactComponent as DailyIcon } from "../../storage/icons/header-daily.svg";
import { ReactComponent as WeeklyIcon } from "../../storage/icons/header-weekly.svg";

const headerIconMap: HeaderIconMap = {
  loading: {
    component: (
      <LoadingIcon fill="currentColor" style={{ height: 16, width: 16 }} />
    ),
    description: "",
    shortLabel: "Loading",
  },

  working: {
    component: (
      <WorkingIcon fill="currentColor" style={{ height: 16, width: 16 }} />
    ),
    description: "",
    shortLabel: "Working",
  },


  daily: {
    component: (
      <DailyIcon fill="currentColor" style={{ height: 16, width: 16 }} />
    ),
    description: "",
    shortLabel: "Daily",
  },
  unloading: {
    component: (
      <UnloadingIcon fill="currentColor" style={{ height: 16, width: 16 }} />
    ),
    description: "",
    shortLabel: "Unloading",
  },
  driving: {
    component: (
      <DrivingIcon
        fill="currentColor"
        style={{ height: 16, width: 16, marginTop: "3px" }}
      />
    ),
    description: "",
    shortLabel: "Driving",
  },
  weekly: {
    component: (
      <WeeklyIcon fill="currentColor" style={{ height: 16, width: 16 }} />
    ),
    description: "",
    shortLabel: "Weekly",
  },
};

export default headerIconMap;
