import {
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridEditInputCell,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridRenderEditCellParams,
  renderEditInputCell,
} from "@mui/x-data-grid-pro";

import { useMemo } from "react";
import { DateTimeEditCell, ErrorTooltip } from "../components";
import {
  EntityField,
  Entity,
  ErrorMap,
  FieldMetadataMap,
  ValidationError,
  CellFormatProps,
} from "../types";
import { useEntityDataTableStyles } from "./useEntityDataTableStyles";
import clsx from "clsx";
import { formatCell } from "../utilities/cellFormatter";
import { FieldMetadata, SizeDef } from "../../types";
import { ClassNameMap } from "@mui/styles";
import headerIconMap from "../../../common/mappings/header.icons";
import { headerIconsMap } from "../../../common/mappings";
import { Box, Tooltip } from "@mui/material";
import formatHeaderDescription from "../../../common/utils/formatHeaderDescription";
import { mergeWith } from "lodash";
import { mergeCustomizer } from "../../../common/utils";
import PercentageEditCell from "../components/PercentageEditCell";

const baseColumnProperties: Partial<GridColDef> = {
  align: "left",
  headerAlign: "left",
  editable: true,
  headerClassName: "header",
  resizable: true,
};

function renderDateTimeEditCell(params: any) {
  return <DateTimeEditCell {...params} />;
}

function renderClearableDateTimeEditCell(params: any) {
  return <DateTimeEditCell {...params} clearable />;
}

function renderPercentageEditCell(params: any) {
  return <PercentageEditCell {...params} />;
}

function renderEditCellFactory(metadata: FieldMetadata) {
  if (metadata.type === "dateTime") {
    return metadata.zeroFormatting
      ? renderClearableDateTimeEditCell
      : renderDateTimeEditCell;
  } else if (metadata.type === "percentage") {
    return renderPercentageEditCell;
  }
  return renderEditInputCell;
}

function renderCellFactory(
  field: EntityField,
  errors: ErrorMap,
  classes: ClassNameMap,
  props: CellFormatProps
) {
  return (params: GridRenderCellParams) => {
    let value = field.valueGetter ? field.valueGetter(params) : params.value;

    let formattedValue = formatCell(field.type, value, {
      ...props,
      row: params.row as Entity,
      field: params.field,
    });

    if (!errors || !errors[params.row.id]) {
      return (
        <div
          onClick={() =>
            field.onCellClick &&
            ((!props.project?.isPlanned && !props.project?.isPlanning) ||
              props.field === "compatibleTrucks")
              ? field.onCellClick(params)
              : undefined
          }
        >
          {formattedValue}
        </div>
      );
    }

    const rowErrors = errors[params.row.id];

    const error = rowErrors.find((row) => params.field === row.field);

    return error ? (
      <ErrorTooltip
        value={formattedValue}
        tooltipClass={classes.tooltip}
        error={error}
        props={props}
      ></ErrorTooltip>
    ) : (
      <span style={{ width: "100%" }}>{formattedValue}</span>
    );
  };
}

function getCellClassName(
  params: GridCellParams,
  errors: ErrorMap,
  warnings: ErrorMap
) {
  const getClassObject = (): Record<string, boolean> => {
    const hasError =
      errors &&
      errors[params.row.id] &&
      errors[params.row.id].length > 0 &&
      errors[params.row.id].some((row) => params.field === row.field);
    const hasWarning =
      warnings &&
      warnings[params.row.id] &&
      warnings[params.row.id].length > 0 &&
      warnings[params.row.id].some((row) => params.field === row.field);

    return {
      danger: hasError,
      warning: !hasError && hasWarning,
    };
  };

  return clsx("cell", getClassObject());
}

const getColumnType = (metadata: FieldMetadata) => {
  if (metadata.type === "dateTime") {
    return "dateTime";
  } else if (metadata.type === "string" || metadata.type === "trailerType") {
    return "string";
  }

  return "number";
};

export const sorter = (a: any, b: any, params1: any, params2: any) => {
  if (!isNaN(a) && !isNaN(b)) {
    return Number(a) - Number(b);
  }

  return 0;
};

export default function useColumnsData<T extends Entity>( // TODO EXTRACT ERRORS
  fieldsMetadata: FieldMetadataMap<T>,
  props: CellFormatProps,
  errors: Record<string, ValidationError[]>,
  warnings: Record<string, ValidationError[]>,
  readOnly?: boolean
) {
  const headerColor = '"#145a92"';
  const classes = useEntityDataTableStyles(errors, warnings, headerColor);
  const issues = {};
  mergeWith(issues, errors, warnings, mergeCustomizer);

  return useMemo(() => {
    const fieldLength = Object.keys(fieldsMetadata).length;
    return Object.entries(fieldsMetadata)
      .sort(([, a], [, b]) => (a as any).order - (b as any).order)
      .map(
        ([key, metadata], index): GridColDef => ({
          ...baseColumnProperties,
          field: key as string,
          width: metadata.size,
          flex: fieldLength < 5 && index === fieldLength - 1 ? 1 : undefined,
          type: getColumnType(metadata),
          headerName: metadata.shortLabel,
          description: metadata.description,
          sortComparator: metadata.sortComparator || sorter,
          hide:
            (key as string) === "id" ||
            ((metadata.hide && typeof metadata.hide === "function"
              ? metadata.hide()
              : !!metadata.hide) as boolean),
          renderEditCell: renderEditCellFactory(metadata),
          headerClassName: metadata.fieldCategory,
          preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
            if (
              (metadata.type === "number" || metadata.type === "duration") &&
              (params.props.value === null ||
                params.props.value === undefined ||
                params.props.value === "" ||
                params.props.value < 0)
            ) {
              alert("Invalid numeric value was provided.");

              return { value: params.row[key], error: false };
            }

            if (
              params.props.hasOwnProperty("error") &&
              metadata.type === "string" &&
              String(params.props.value).trim() === ""
            ) {
              alert("Value cannot be empty.");
              return { value: params.row[key], error: true };
            }

            return { ...params.props, error: false };
          },
          renderHeader: (params: GridColumnHeaderParams) => {
            const icons = metadata.headerIcons
              ? metadata.headerIcons.map(
                  (icon) => headerIconsMap[icon].component
                )
              : null;
            return (
              <Tooltip
                title={formatHeaderDescription(
                  metadata.description,
                  metadata.fullLabel ?? metadata.shortLabel
                )}
              >
                <Box display="flex" alignItems="center">
                  {icons && (
                    <Box
                      display="flex"
                      alignItems="center"
                      marginRight={1}
                      paddingBottom={"4px"}
                    >
                      {icons}
                    </Box>
                  )}

                  {metadata.shortLabel}
                </Box>
              </Tooltip>
            );
          },
          renderCell: renderCellFactory(metadata, issues, classes, {
            ...props,
            field: key,
          }),
          cellClassName: (params) => getCellClassName(params, errors, warnings),
          editable:
            props.project &&
            !props.project.isPlanned &&
            !props.project.isPlanning &&
            !readOnly &&
            metadata.type !== "boolean",
        })
      );
  }, [classes, errors, fieldsMetadata, issues, props, readOnly, warnings]);
}
