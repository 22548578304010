import { Box, Chip, CircularProgress } from "@mui/material";
import { GridCellValue } from "@mui/x-data-grid";
import { DateTime, Duration } from "luxon";
import React, { ReactNode } from "react";
import { FieldType } from "../../types";
import { CellFormatProps, Entity, SetPropertyEditDialogType } from "../types";

type CellFormatterFunc = (
  value: GridCellValue,
  props?: CellFormatProps
) => ReactNode;
type CellFormattersMap = Record<FieldType, CellFormatterFunc>;

const cellFormatters: CellFormattersMap = {
  string: formatStringValue,
  number: formatNumberValue,
  boolean: formatBooleanValue,
  dateTime: formatDateTimeValue,
  duration: formatDurationValue,
  money: formatMoneyValue,
  percentage: formatPercentageValue,
  props: formatPropsValue,
  location: formatLocationValue,
  trailerType: formatStringValue,
  outputBoolean: formatBooleanValue,
};

function formatStringValue(value: GridCellValue) {
  return value;
}

function formatNumberValue(value: GridCellValue) {
  return value === null ? "-" : value;
}

function formatBooleanValue(value: GridCellValue, props?: CellFormatProps) {
  const toggleValue = () => {
    if (props && props.updateCellValue) {
      props.updateCellValue({
        field: props.field as string,
        id: props.row.id,
        value: !value,
      } as any);
    }
  };

  return (
    <Box
      style={{
        cursor: "pointer",
        width: "100%",
      }}
      onDoubleClick={toggleValue}
    >
      {value ? "Yes" : "No"}
    </Box>
  );
}

function formatDateTimeValue(value: GridCellValue, props?: CellFormatProps) {
  let dateTimeObj;

  if(value === null){
    return `-`
  }


  if (DateTime.isDateTime(value)) {
    dateTimeObj = value as DateTime;
  } else {
    dateTimeObj = DateTime.fromISO(value as any);
  }

  let dataValue: string | null;
  let timeValue: string | null;

  if (dateTimeObj) {
    dataValue = dateTimeObj.toLocaleString({
      ...DateTime.DATE_MED,
      timeZone: "gmt",
    });

    timeValue = dateTimeObj.toLocaleString({
      ...DateTime.TIME_24_SIMPLE,
      timeZone: "gmt",
    });
  } else {
    dataValue = null;
    timeValue = null;
    console.log("Value is not date time object");
  }

  return (
    <Box display="flex">
      <Box width={90}>{dataValue},</Box>
      <Box>{timeValue}</Box>
    </Box>
  );
}

function formatMoneyValue(value: GridCellValue) {
  return "€" + value;
}

function formatPercentageValue(value: GridCellValue) {
  if (value) {
    value = Math.round((value as number) * 100);
  }

  value += "%";

  return value;
}

function formatDurationValue(value: GridCellValue) {
  let formattedDurationValue;
  let durationValueInMinutes = value;
  if (value === null) {
    durationValueInMinutes = `-`;
  } else {
    formattedDurationValue = Duration.fromObject({
      minutes: durationValueInMinutes as number,
    }).toFormat("hh:mm");
  }

  return (
    <Box display="flex">
      <Box width={40}>{durationValueInMinutes}</Box>
      <Box>({formattedDurationValue})</Box>
    </Box>
  );
}

function formatLocationValue(value: any, props?: CellFormatProps) {
  if (!props?.locations) {
    return null;
  }

  const location = (props.locations as any)[
    `${value.latitude}|${value.longitude}`
  ];

  if (!location) {
    return (
      <CircularProgress style={{ margin: "auto" }} size={20} color="primary" />
    );
  }

  const openLocationEditForm = () => {
    if (props.setLocationEditDialog && !props.project?.isPlanned) {
      props?.setLocationEditDialog({
        field: props.field as string,
        open: true,
        entityObject: props.row as Entity,
      });
    }
  };

  return (
    <Box sx={{ cursor: "pointer" }} onClick={openLocationEditForm}>
      {location.country}, {location.city}, {location.zipCode}
    </Box>
  );
}

function formatPropsValue(value: GridCellValue, props?: CellFormatProps) {
  const chips = (value as string[]).map((item, index) => (
    <Chip
      key={`${props?.row?.id}-${index}`}
      label={item as any}
      style={{ marginRight: 5, marginBottom: 5 }}
      size="small"
      variant="outlined"
    />
  ));

  const handlePropsUpdate = () => {
    if (props && props.setPropertyEditDialog) {
      const state = {
        open: true,
        entityObject: props.row as Entity,
      };

      (props.setPropertyEditDialog as SetPropertyEditDialogType)(state);
    }
  };

  const displayValue = (
    <Box style={{ width: "100%", height: "100%" }} onClick={handlePropsUpdate}>
      <div style={{ overflowX: "auto", overflowY: "hidden" }}>{chips}</div>
    </Box>
  );

  return displayValue;
}

function formatArrayValue(
  value: GridCellValue,
  valueType: FieldType,
  props?: CellFormatProps
) {
  const elements = (value as Array<any>).map((item: any, i: number) => (
    <Box key={`${props?.row.id}-${i}`} style={{ lineHeight: 1.5 }}>
      {cellFormatters[valueType](item, props)}
    </Box>
  ));
  const displayValue = (
    <Box
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {elements}
    </Box>
  );

  return displayValue;
}

export function formatCell(
  type: FieldType,
  value: GridCellValue,
  props?: CellFormatProps
) {
  if (type in cellFormatters) {
    if (props?.isArray) {
      return formatArrayValue(value, type, props);
    }

    return cellFormatters[type](value, props);
  }

  return null;
}
