import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ApolloError, gql, useMutation } from "@apollo/client";
import { CREATE_USER, UPDATE_USER } from "../api/mutations";
import { User } from "../types";
import { useNavigate } from "react-router";
import { BootstrapDialogTitle } from "../../common/components/BootstrapDialogTitle";
import { useAuth } from "../../auth/hooks";
import { DateTimePicker } from "@mui/lab";

interface Props {
  dialog: {
    open: boolean;
    edited: User | null;
  };
  setDialog: Dispatch<
    SetStateAction<{
      open: boolean;
      edited: User | null;
    }>
  >;
}

type VoidHandler = () => void;
const UserFormDialog: React.FC<Props> = ({ dialog, setDialog }) => {
  const navigate = useNavigate();

  const { register, handleSubmit, setValue, control, reset } = useForm();
  const { user } = useAuth();

  useEffect(() => {
    setValue("name", dialog.edited?.name ?? "");
    setValue("email", dialog.edited?.email ?? "");
    setValue("activeUntil", dialog.edited?.activeUntil ?? null);
  }, [dialog, setValue]);

  const handleClose: VoidHandler = () => {
    console.log("closed");
    setDialog({
      open: false,
      edited: null,
    });

    reset();
  };

  const [createUser] = useMutation(CREATE_USER, {
    update(cache, { data: { createUser } }) {
      cache.modify({
        fields: {
          users(existingUsers = []) {
            const newUserRef = cache.writeFragment({
              data: createUser,
              fragment: gql`
                fragment NewUser on User {
                  id
                  name
                  activeUntil
                }
              `,
            });
            return [...existingUsers, newUserRef];
          },
        },
      });
    },
  });

  const [updateUser] = useMutation(UPDATE_USER);
  const onSubmit = async (data: User) => {
    try {
      if (dialog.edited) {
        await updateUser({
          variables: {
            id: dialog.edited.id,
            updateUserInput: {
              name: data.name,
              password: data.password,
              email: data.email,
              activeUntil: data.activeUntil,
            },
          },
        });
      } else {
        const {
          data: { createUser: createdUser },
        } = await createUser({
          variables: {
            createUserInput: {
              name: data.name,
              password: data.password,
              email: data.email,
              activeUntil: data.activeUntil,
            },
          },
        });
      }

      handleClose();
      // navigate((user as User).id)
    } catch (err) {
      if (err.graphQLErrors[0]?.extensions?.statusCode === 400) {
        // setError(err.graphQLErrors[0].message);
      }
    }
  };

  return (
    <div>
      <Dialog
        open={dialog.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <BootstrapDialogTitle id="form-dialog-title" onClose={handleClose}>
          {dialog.edited ? "Edit user" : "New user"}
        </BootstrapDialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText>
              Fill information about this user.
            </DialogContentText>
            <TextField
              inputProps={{ ...register("name") }}
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              fullWidth
            />
            <TextField
              inputProps={{ ...register("email") }}
              margin="dense"
              id="email"
              label="Email"
              fullWidth
            />
            <TextField
              inputProps={{ ...register("password") }}
              type="password"
              margin="dense"
              id="password"
              label="Password"
              fullWidth
            />
            <Controller
              // defaultValue={}
              control={control}
              name="activeUntil"
              render={({ field }) => (
                <DateTimePicker
                  label="Active until"
                  renderInput={(props) => (
                    <TextField {...props} margin="dense" fullWidth />
                  )}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                  value={field.value}
                />
              )}
            />
          </DialogContent>

          <DialogActions>
            <Button type="submit" color="primary">
              {dialog.edited ? "Update" : "Create"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default UserFormDialog;
