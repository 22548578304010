import _ from "lodash";
import { createContext, useCallback, useState } from "react";
import { io, Socket } from "socket.io-client";

interface SocketCtx {
  socket: Socket | null;
  connectSocket: (query: Record<string, unknown>) => void;
}

export const SocketContext = createContext<SocketCtx>({
  socket: null,
  connectSocket: () => {},
});

const SocketContextProvider: React.FC = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null);

  const connectSocket = useCallback(
    (query: Record<string, unknown>) => {
      const queryChanged = !_.isEqual(socket?.io.opts.query, query);
      if (!socket || queryChanged) {
        if (socket && queryChanged) {
          socket?.disconnect();
        }
        const newSocket = io(process.env.REACT_APP_WEBSOCKET as string, {
          transports: ["websocket"],
          path: "/ws",
          query,
        });
        setSocket(newSocket);
      }
    },
    [socket]
  );

  return (
    <SocketContext.Provider
      value={{
        socket,
        connectSocket,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export default SocketContextProvider;
