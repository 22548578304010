import { useContext, createContext, Dispatch, SetStateAction } from "react";
import { Project } from "../../types";
import {EntityString, Location, LocationData, LocationMap} from "../types";

export interface EntityManagerContextProps {
  project: Project;
  refetchProject: any;
  entity: EntityString;
  focusedParameterState: [
    string[] | null,
    React.Dispatch<React.SetStateAction<string[] | null>>
  ];
  selectedTabState: [number, Dispatch<SetStateAction<number>>];
  locations: LocationData;
  entityParameterKeyMapping: Record<EntityString,string[]>
}

export const EntityManagerContext = createContext<EntityManagerContextProps | null>(null);

export const useEntityManagerContext = () => {
  return useContext(EntityManagerContext) as EntityManagerContextProps
}
export const EntityManagerProvider: React.FC<EntityManagerContextProps> = ({
  children,
  ...props
}) => {
  return (
    <EntityManagerContext.Provider value={props}>
      {children}
    </EntityManagerContext.Provider>
  );
};
