import { useCallback, useEffect, useState } from "react";
import { Project } from "../../types";
import { OrderType } from "../types/orderType";
import { useCompatibilitiesQuery } from "./useCompatibilitiesQuery";

export function useEntityManagerCompatibilities(project: Project) {
  const [
    checkCompatibilities,
    { data: compatibilitiesData, loading: loadingCompatibilities },
  ] = useCompatibilitiesQuery(project.id);

  const [filteredBounds, setFilteredBounds] = useState<[number, number]>([0, 100]);

  const compatibilityBoundsFilter = useCallback(
    (item: OrderType) => {
      if (!compatibilitiesData) {
        return true
      }

      const [left, right] = filteredBounds;
      if (!compatibilitiesData.hasOwnProperty(item.id)) {

        // Show only if no filter is set.
        return left === 0 && right === 100 
      }

      if (left === 0 && right === 100){
          return true
      }
      
      return (
        left === right ? compatibilitiesData[item.id].percentage === left : compatibilitiesData[item.id].percentage > left && compatibilitiesData[item.id].percentage <= right
      );
    },
    [compatibilitiesData, filteredBounds]
  );

  const [highlightCompatibilities, setHighlightCompatibilities] =
    useState(false);

  return {
    checkCompatibilities,
    compatibilitiesData,
    loadingCompatibilities,
    highlightCompatibilities,
    setHighlightCompatibilities,
    compatibilityBoundsFilter,
    setFilteredBounds
  };
}
