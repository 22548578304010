
const headerDescriptionFormatter = (description: string, label: string): JSX.Element => {
    return <span>
        <span style={{fontWeight: 'bold', fontSize: 12}}>
            {label}.{" "}
        </span>
        <span>
            {description}
        </span>
    </span>
}

export default headerDescriptionFormatter