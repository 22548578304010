import { merge } from "lodash";
import { useMemo } from "react";
import { useMetadataQuery } from ".";
import { EntityField, EntityString } from "../types";

export default function useEntityMetadata(
  entity: EntityString,
  additionalFields: Record<string, Partial<EntityField>> | null
) {
  const { data } = useMetadataQuery(entity);

  const metadata = useMemo(() => {
    const newData = {};
    if (additionalFields) {
      merge(newData, data, additionalFields);
      return newData;
    } else {
      return data;
    }
  }, [additionalFields, data]);

  return metadata;
}
