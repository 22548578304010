import { DocumentNode, gql } from '@apollo/client';
import { EntityString } from '../../types';
import {DELETE_CLIENTS, UPDATE_CLIENT} from './clients';
import {DELETE_ORDERS, UPDATE_ORDER} from './orders';
import {DELETE_TRUCKS, UPDATE_TRUCK} from './trucks';
import { CREATE_CLIENT } from './clients';
import { CREATE_ORDER } from './orders';
import { CREATE_TRUCK } from './trucks';

type MutationMap = Record<EntityString, DocumentNode>;

export const IMPORT_FILES = gql`
  mutation(
    $projectId: String!
    $orders: Upload
    $trucks: Upload
    $clients: Upload
  ) {
    importFiles(
      projectId: $projectId
      orders: $orders
      trucks: $trucks
      clients: $clients
    ) {
      importName
      fieldTypeErrors
      fileStructureErrors
      otherStructureErrors
      validationPassed
    }
  }
`;

export const updateMutationsMap: MutationMap = {
  orders: UPDATE_ORDER,
  trucks: UPDATE_TRUCK,
  clients: UPDATE_CLIENT
};

export const createMutationsMap: MutationMap = {
  orders: CREATE_ORDER,
  trucks: CREATE_TRUCK,
  clients: CREATE_CLIENT
};

export const deleteMutationsMap: MutationMap = {
  orders: DELETE_ORDERS,
  trucks: DELETE_TRUCKS,
  clients: DELETE_CLIENTS
}
