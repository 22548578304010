import { useCallback, useState } from "react";
import { Project } from "../../types";
import {
  Entity,
  CompatibilityDetailsDialog,
  EntityFormDialogType,
  EntityString,
  ImportDialog,
  LocationEditDialogType,
} from "../types";

export default function useEntityDataManagerDialogs(entity: EntityString) {
  const [importDialog, setImportDialog] = useState<ImportDialog>({
    open: false,
    entity: entity,
  });

  const [entityDialog, setEntityDialog] = useState<EntityFormDialogType>({
    open: false,
    entityType: entity,
    entityObject: null,
  });

  const [propertyEditDialog, setPropertyEditDialog] = useState<{
    open: boolean;
    entityObject: Entity | null;
  }>({
    open: false,
    entityObject: null,
  });

  const [parametersEditDialog, setParametersEditDialog] = useState<{
    open: boolean;
    edited: Project | null;
    entity?: EntityString;
  }>({
    open: false,
    edited: null,
  });

  const [compatibilitiesDetailsDialog, setCompatibilitiesDetailsDialog] =
    useState<CompatibilityDetailsDialog>({
      open: false,
      orderData: null,
      projectId: null,
      compatibilitiesInfo: null,
    });

  const toggleImportDialog = useCallback((value: boolean) =>
    setImportDialog({ open: value, entity: entity }), [entity]
  );

  const [locationEditDialog, setLocationEditDialog] =
    useState<LocationEditDialogType>({
      open: false,
      entityObject: null,
      field: null,
    });
  return {
    importDialog,
    setImportDialog,
    entityDialog,
    setEntityDialog,
    toggleImportDialog,
    propertyEditDialog,
    setPropertyEditDialog,
    compatibilitiesDetailsDialog,
    setCompatibilitiesDetailsDialog,
    parametersEditDialog,
    setParametersEditDialog,
    locationEditDialog,
    setLocationEditDialog,
  };
}
