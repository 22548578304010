import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    errors: {
        "& .parameter-label": {
            color: '#19647E',
            fontWeight: 'bold'
        },

        "& .parameter-value": {
            color: '#44633F',
            fontWeight: 'bold'
        },
    },
})

const useParameterErrorsStyle = (
) => {
    return useStyles();
};

export default useParameterErrorsStyle;
