import { useQuery } from "@apollo/client";
import { useContext, useEffect, useMemo, useState } from "react";
import { SocketContext } from "../../context/SocketContext";
import { GET_USER } from "../api/queries";

const useAuth = () => {
  const { data, refetch, loading } = useQuery(GET_USER, { errorPolicy: "all" });


  const user = useMemo(() => {
    return data && data.getUser ? data.getUser : null;
  }, [data]);


  return {
    user,
    fetchUser: refetch,
    loading,
    isLoggedIn: localStorage.getItem("token") ? true : false,
  };
};

export default useAuth;
