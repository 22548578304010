import { gql } from '@apollo/client';


export const GET_CLIENT_FIELDS_METADATA = gql`
  query {
    clientsMetadata 
  }
`;

export const GET_CLIENTS = gql`
  query($projectId: String!) {
    clients(projectId: $projectId) {
      id
      clientId
      mustTransported
      cargoCount
    }
  }
`;

export const VALIDATE_CLIENTS = gql`
  query($projectId: String!, $refresh: Boolean!) {
    validateClients(projectId: $projectId, refresh: $refresh)
  }
`;

export const FETCH_CLIENT_OPTIONS = gql`
     query($projectId: String!) {
    fetchClientOptions(projectId: $projectId)
  }
`
