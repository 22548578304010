import {
  EntityString,
  NativeIdLabel,
  NumberedValidationError,
  ValidationError,
} from "../types";

import { Box, Theme, useTheme, TableContainer } from "@mui/material";

import { errorMappings } from "../mappings";
import {
  DataGridPro,
  GridColDef,
  GridRowParams,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import { makeStyles } from "@mui/styles";
import { useEntityDataTableStyles } from "../hooks/useEntityDataTableStyles";
import useEntityMetadata from "../hooks/useEntityMetadata";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      {/*<GridToolbarExport csvOptions={{ allColumns: true }}/>*/}
    </GridToolbarContainer>
  );
}

function getFieldLabel(meta: any, fieldName: string){
  return meta[fieldName].fullLabel ? meta[fieldName].fullLabel : meta[fieldName].shortLabel
}

interface Props {
  errors: ValidationError[];
  onErrorClick: any;
  entity: EntityString;
  isCompact?: boolean;
}

const ValidationErrorsTable: React.FC<Props> = ({
  errors,
  onErrorClick,
  entity,
  isCompact,
}) => {
  const meta = useEntityMetadata(entity, null);

  const headerColor = 'rgba(236,95,95,1)'

  const classes = useEntityDataTableStyles({}, {}, headerColor);

  let nativeIdLabel: NativeIdLabel;

  switch (entity) {
    case "orders": {
      nativeIdLabel = "Order ID";
      break;
    }
    case "trucks": {
      nativeIdLabel = "Truck ID";
      break;
    }
    case "clients": {
      nativeIdLabel = "Client ID";
      break;
    }
  }

  const columns = useMemo(() => {
    const data: GridColDef[] = [
      {
        field: "num",
        headerName: "No.",
        width: 65,
        headerClassName: "issuesTable",
        hide: false,
      },
    ];

    Object.entries(errorMappings).forEach(([key, value]) => {
      const col: GridColDef = {
        field: (key as string) === "id" ? "entityId" : (key as string),
        headerName:
          (key as string) === "nativeId" ? nativeIdLabel : (value.label),
        width: key === "formattedMessage" ? undefined : value.width,
        flex: key === "formattedMessage" ? 1 : undefined,
        headerClassName: "issuesTable",
        disableExport: ["id", "formattedMessage", "message"].includes(
          key as string
        ),
        hide:
          ["id", "unformattedMessage", "message"].includes(key as string) ||
          (isCompact &&
            !["id", "nativeId", "field", "formattedMessage"].includes(
              key as string
            )),
        renderCell: (params) => {
          if (key === "formattedMessage") {
            return <div className={classes.errorMessage}>{params.value}</div>;
          } else if (key === "field") {
            return <span>{getFieldLabel(meta,params.value)}</span>;
          }

          return <span>{params.value}</span>;
        },
      };
      data.push(col);
    });

    return data;
  }, [classes.errorMessage, isCompact, meta, nativeIdLabel]);

  //TODO: check if getRowId compatibility with row having and id is fixed and change accordingly

  const numberedErrors: NumberedValidationError[] = useMemo(() => {
    return errors.flat().map((error, index) => {
      let { id, ...errorData } = error;
      return {
        num: index + 1,
        entityId: id,
        ...errorData,
      };
    });
  }, [errors]);

  const rowClickHandler = (param: GridRowParams) => {
    onErrorClick({ row: param.row.entityId, column: param.row.field });
  };

  return (
    <>
      <Box height={750} width="100%" className={classes.root}>
        <DataGridPro
          components={{
            Toolbar: CustomToolbar,
          }}
          disableSelectionOnClick
          onRowClick={rowClickHandler}
          rows={numberedErrors}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[]}
          getRowId={(row) => row.num}
          density="compact"
          className="entity-datatable"
          disableColumnSelector={true}

        />
      </Box>
    </>
  );
};

export default ValidationErrorsTable;
