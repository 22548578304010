import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {DataTableColumnMap, Entity, EntityField, EntityFormDialogType, FieldMetadataMap} from '../types';
import { createMutationsMap } from '../api/mutations';
import {OperationVariables, } from '@apollo/client';
import { EntityInputField } from '../components';
import { mapValues } from 'lodash'
import { DateTime } from 'luxon'
import { capitalizeFirstLetter } from '../../../utilities/helpers';
import { usePropertyOptionsQuery } from '../hooks';
import { LabelObject, propertyLabelMap, PropifiedEntityType } from '../mappings';
import { EntityFormContext, EntityFormProvider } from '../context/EntityFormContext';
import { Project } from '../../types';
import {BootstrapDialogTitle} from "../../../common/components/BootstrapDialogTitle";

interface Props<T extends Entity> {
  createEntityHandler: (variables: OperationVariables) => Promise<void>
  project: Project
  dialog: EntityFormDialogType;
  setDialog: Dispatch<SetStateAction<EntityFormDialogType>>;
  fieldsMetadata: FieldMetadataMap<T>;
}
function EntityFormDialog<T extends Entity>({
  project,
  dialog,
  setDialog,
  fieldsMetadata,
  createEntityHandler
}: Props<T>): JSX.Element {


  const { data: propsData } = usePropertyOptionsQuery(project, dialog.entityType);


  const { register, handleSubmit, control, reset } = useForm();

  const handleClose: () => void = () => {
    setDialog({
      ...dialog,
      entityObject: null,
      open: false
    });

   // setTimeout(() => reset())
  };

  const setFormFieldValue = (dialog: EntityFormDialogType, key:string, fieldMeta:EntityField) => {
    if(dialog.entityObject) {
      return (dialog.entityObject as any)[key]
    }
    else if (fieldMeta.type === 'boolean'){
      return false
    }
    else {
      return ''
    }
  }

  const createParamName = `create${capitalizeFirstLetter(
    dialog.entityType.substring(0, dialog.entityType.length - 1)
  )}Input`;

  const onSubmit = async (data: any): Promise<void> => {
    try {
      const transformedData = mapValues(data, (value) => {
        if (DateTime.isDateTime(value)) {
          return value.toSeconds()
        } 

        return value
      })

      await createEntityHandler({
        [createParamName]: { projectId: project.id, ...transformedData }
      })


    } catch (e) {
      console.log(e);
    } finally {
      handleClose();
    }
  };

 


  const formFields = useMemo(() => {

    const { id, ...filteredFields } = fieldsMetadata

    return Object.entries(filteredFields as FieldMetadataMap<T>).filter(([key, field]) => !field.hide && !field.tableOnly).map(([key, field]) => (
        <EntityInputField
          key={key}
          field={key}
          fieldMetadata={field}
          register={register}
          control={control}
          value={setFormFieldValue(dialog,key,field)}
        />
      ));
    
  }, [control, dialog.entityObject, fieldsMetadata, register])

  return (
    <div>
      <Dialog
        open={dialog.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <BootstrapDialogTitle id="form-dialog-title" onClose={handleClose}>Create entry</BootstrapDialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <EntityFormProvider propsData={propsData}>
              {formFields}   
            </EntityFormProvider>
            </DialogContent>

          <DialogActions>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default EntityFormDialog;
