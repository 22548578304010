import { ValidationError } from '../types';
import { useMemo } from "react";


const useIssuesTableContent = (
    errors: ValidationError[] | null,
    warnings: ValidationError[] | null,
    includeRowsWithErrors: boolean,
    includeRowsWithWarnings: boolean
): ValidationError[] => {

    return useMemo(()=>{
        let content = new Set<ValidationError>()
        const preparedWarnings = warnings ?? []
        const preparedErrors = errors ?? []

        if(includeRowsWithWarnings){
            content = new Set([...preparedWarnings as ValidationError[]])
        }

        if(includeRowsWithErrors){
            content = new Set ([...content, ...preparedErrors as ValidationError[]])
        }

        return Array.from(content)
    },[errors, warnings, includeRowsWithErrors, includeRowsWithWarnings])
};

export default useIssuesTableContent;