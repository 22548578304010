import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { GET_OUTPUT_DATA } from "../api/queries";

export default function useOutputDataQuery(projectId: string, batchId: string) {
    const query = useQuery(GET_OUTPUT_DATA, { variables: { projectId, batchId }, skip: !batchId })

    const outputData = useMemo(() => {
       
        if (!query.data) {
            return {
                preplanned: [],
                unplanned: [],
                errored: []
            }
        }

        const baseData = query.data.outputRecords

        return {
            preplanned: baseData.filter((row: any) => row.status === 'Preplanned'),
            unplanned: baseData.filter((row: any) => row.status === 'Unplanned'),
            errored: baseData.filter((row: any) => row.status === 'Error')
        }
    }, [query.data])



    return { ...query, data: outputData}
}