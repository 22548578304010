import { DocumentNode } from '@apollo/client';
import { EntityString } from '../../types';
import {GET_CLIENTS, GET_CLIENT_FIELDS_METADATA, VALIDATE_CLIENTS, FETCH_CLIENT_OPTIONS} from './clients';
import {GET_ORDERS, GET_ORDER_FIELDS_METADATA, VALIDATE_ORDERS, FETCH_ORDER_REQUIREMENTS} from './orders';
import {GET_TRUCKS, GET_TRUCK_FIELDS_METADATA, VALIDATE_TRUCKS, FETCH_TRUCK_PROPERTIES} from './trucks';

type QueryMap = Record<EntityString, DocumentNode>;

type PartialQueryMap = Record<"trucks"|"orders", DocumentNode>


export const dataQueriesMap: QueryMap = {
  orders: GET_ORDERS,
  trucks: GET_TRUCKS,
  clients: GET_CLIENTS
};

export const validationQueryMap: QueryMap = {
  orders: VALIDATE_ORDERS,
  trucks: VALIDATE_TRUCKS,
  clients: VALIDATE_CLIENTS
};

export const metadataQueryMap: QueryMap = {
  orders: GET_ORDER_FIELDS_METADATA,
  trucks: GET_TRUCK_FIELDS_METADATA,
  clients: GET_CLIENT_FIELDS_METADATA
};

export const propertyOptionsQueryMap: QueryMap = {
  orders: FETCH_ORDER_REQUIREMENTS,
  trucks: FETCH_TRUCK_PROPERTIES,
  clients: FETCH_CLIENT_OPTIONS
}
