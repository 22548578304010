import { gql } from "@apollo/client";

export const UPDATE_PARAMETERS = gql`
    mutation($updateParamsInput: UpdateParamsInput!) {
        updateProjectParams(updateParamsInput: $updateParamsInput) {
            key
            message
            driverDependantKey
            parameterLabels
            parameterValues
        }
    }
`