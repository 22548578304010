import React, { Dispatch, SetStateAction, useEffect } from "react";
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import { Project } from "../../types";
import {useProjectBatchesQuery} from "../../outputData/hooks";
import {BatchType} from "../../outputData/types";

interface ParamsBatchSelectProps {
    project: Project;
    selectedBatchState: [
            BatchType | null,
        Dispatch<SetStateAction<BatchType | null>>
    ];
    batches: BatchType[]
}

const ParamsBatchSelect: React.FC<ParamsBatchSelectProps> = ({
                                                     project,
                                                     selectedBatchState,
                                                     batches
                                                 }) => {

    const [selectedBatch, setSelectedBatch] = selectedBatchState;

    const handleChange = (event: any) => {
            if (event.target.value !== selectedBatch?.id) {
                const batch = batches.find((item) => item.id === event.target.value);

                if (batch) {
                    setSelectedBatch(batch);
                }
            }
    };

    return batches.length !== 0 && selectedBatch ? (
        <>
            <Box display="flex" alignItems="center">
                <FormControl sx={{ minWidth: 80 }}>
                    <InputLabel sx={{ pt: "7px", fontSize: "0.8rem" }}>
                        Select version
                    </InputLabel>
                    <Select
                        value={selectedBatch.id}
                        onChange={handleChange}
                        size="medium"
                        fullWidth
                        sx={{width: 280}}
                        label="Select version"
                    >
                        {batches.map((batch: BatchType) => {
                            return <MenuItem key={`batch-${batch.id}`} value={batch.id}>{batch.name}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Box>
        </>
    ) : null
};

export default ParamsBatchSelect;
